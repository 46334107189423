import React from 'react';
// import { ASSETS_BASE_URL } from '../../config/config';

// MUI
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import GetAppIcon from '@material-ui/icons/GetApp';
import Button from '@material-ui/core/Button';

// HELPERS
import { onDownload } from '../../helpers';

import './AttachmentsModal.css';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '1302 !important',
  },
  paper: {
    maxWidth: 'max-content',
    maxHeight: '90vh',
    padding: theme.spacing(1, 5, 6),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: '5px',
    [theme.breakpoints.down(420)]: {
      maxWidth: '100vw',
      padding: theme.spacing(1, 4, 6),
    },
  },
  closeBtn: {
    left: 'calc(100% - 18px)',
    top: '3px',
    [theme.breakpoints.down(420)]: {
      left: 'calc(100% - 24px)',
      top: '0px',
    },
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      marginRight: '0.8rem',
    },
    [theme.breakpoints.down(420)]: {
      '& h2': {
        fontSize: '1.3em',
      },
    },
  },
  items: {
    height: '90%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '3rem',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    overflowY: 'auto',
    '& button': {
      maxWidth: '100%',
      marginBottom: '1rem',
      padding: '8px 24px',
      fontSize: '1rem',
      textTransform: 'uppercase',
      borderColor: 'var(--clr-g-3)',
      '&:hover': {
        backgroundColor: 'var(--clr-g-3)',
        color: 'var(--clr-g-f7)',
      },
    },
  },
}));

const AttachmentsModal = ({ open, handleClose, attachmentsArray }) => {
  const classes = useStyles();

  return (
    <Modal
      aria-labelledby='transition-modal-title'
      className={classes.modal}
      open={open}
      onClose={() => handleClose('attachments')}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <IconButton
            aria-label='close'
            disableRipple={true}
            onClick={() => handleClose('attachments')}
            className={classes.closeBtn}
          >
            <CloseIcon />
          </IconButton>
          <div className={classes.title}>
            <GetAppIcon />
            <h2 id='transition-modal-title'>Download Attachments</h2>
          </div>
          <div className={`${classes.items} btn-container`}>
            {attachmentsArray.map((item, idx) => (
             
              <Button
                key={idx}
                variant='outlined'
                onClick={() =>
                  onDownload(`${process.env.REACT_APP_ASSETS_BASE_URL}/${item.path}`, item.name)
                }
                
                >
                 {item.name}

              </Button>
                
            ))}
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

function propsAreEqual(prevProps, nextProps) {
  return prevProps.open === nextProps.open;
}

export default React.memo(AttachmentsModal, propsAreEqual);
