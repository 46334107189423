import React, { useState, useEffect, lazy, Suspense, useContext } from "react";
// import {
//   REACT_APP_GOOGLE_ANALYTICS_ID,
//   STATS_API_TOKEN,
//   STATS_API,
// } from "../config/config";
// MUI
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { isMobile } from "react-device-detect";

//axios
import axios from "axios";

import "./index.css";

// HOOKS
import useLogo from "../hooks/useLogo";
import WebcastingContext from "../context/webcasting/webcastingContext";

// LAZY COMPONENTS
const BackgroundImg = lazy(() => import("../components/UI/BackgroundImg"));
const Logo = lazy(() => import("../components/UI/Logo"));

const useStyles = makeStyles((theme) => ({
  root: {
    width: "90%",
    maxWidth: "600px",
    display: "inline-block",
  },
  text: {
    marginTop: "2rem",
  },
}));

const PendingArchive = (props) => {
  const [brandingFound, setBrandingFound] = useState(false);
  const [holdingSlide, setHoldingSlide] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState("#ffffff");
  const [logoPath, logoPadding] = useLogo(props.loading, props.event);
  const [bgOpacity, setBgOpacity] = useState(false);
  const webcastingContext = useContext(WebcastingContext);
  const {
    loading,
    event,
    authorization,
    authListResponse,
    getAuthList,
    authList,
    localData,
    getEvent,
    getAuthorization,
    readLocalData,
    checkLocalDataAndRedirect,
    branding,
    // authListCompleted,
    getUserIP,
    userIp,
    getUserIpPermission,
    userIpPermission,
    updateLoginTime,
    validateUserID,
    getGeoData,
    addDeviceName,
    userStartTimeTracking,
    sendUserStartAndEndTime,
    //noreq
    getGeoDataAndDeviceInfo,
    sendUserStartAndEndTimeNoReq,
  } = webcastingContext;

  const [noReqID, setnoReqID] = useState("");

  useEffect(() => {
    setBrandingFound(props.brandingFound);
  }, [props.brandingFound]);

  const removeLiveUser = async () => {
    Object.entries(authorization).length > 0 &&
      (await axios.get(
        `${process.env.REACT_APP_STATS_API_URL}/event/removeLiveUser/${props.event.items[0]._id}/?userID=${authorization.registrants[0]._id}&token=${process.env.REACT_APP_STATS_API_TOKEN}`
      ));
  };

  const removeLiveUserNoReg = async () => {
    let checkLocalNoReg = localStorage.getItem("noReglocalData");
    let parsedResultnoReglocalData = JSON.parse(checkLocalNoReg);
    // console.log('no reg event id', props.match.match.params.eventId)
    let result = parsedResultnoReglocalData?.user?.events.filter((eachData) => {
      return eachData.eventID === props.match.match.params.eventId;
    });
    if (result !== undefined && result.length > 0) {
      if (
        result[0].sessionUID?.length > 0 &&
        result[0].sessionUID !== undefined
      ) {
        await axios.get(
      `${process.env.REACT_APP_STATS_API_URL}/event/removeLiveUserNoReq/${props.event.items[0]._id}/?token=${process.env.REACT_APP_STATS_API_TOKEN}&userID=${result[0].sessionUID}`
    );
    
      }
    }
    // console.log("Removing NoReg", result[0].sessionUID);

  
  };

  useEffect(() => {


      console.log('PENDING ARCHIVE MOUNTED')


    let checkLocalNoReg = localStorage.getItem("noReglocalData");
    let parsedResultnoReglocalData = JSON.parse(checkLocalNoReg);
    // console.log('no reg event id', props.match.match.params.eventId)
    let result = parsedResultnoReglocalData?.user?.events.filter((eachData) => {
      return eachData.eventID === props.match.match.params.eventId;
    });

    console.log("Pending NoReg", result);
    if (result !== undefined && result.length > 0) {
      if (
        result[0].sessionUID?.length > 0 &&
        result[0].sessionUID !== undefined
      ) {
        setnoReqID(result[0].sessionUID);
      }
    }

    //Remove the user from live user (if present)

    if (props.event.items[0].registrationRequired) {
      authorization && removeLiveUser();

    } else {
      removeLiveUserNoReg();

    }


    // if (isMobile) {
    //   let removeUser = false;
    //   window.addEventListener("blur", function () {
    //     console.log("Blurr ");
    //     mobileUserAlert();
    //     removeUser = true;
    //   });
    //   document.onvisibilitychange = () => {
    //     if (
    //       document.visibilityState === "hidden" ||
    //       document.visibilityState === "prerender"
    //     ) {
    //       if (!removeUser) {
    //         mobileUserAlert();
    //       }
    //     }
    //   };
    // } else {
    //   window.addEventListener("beforeunload", alertUser);
    // }

    return () => {
      // window.removeEventListener("beforeunload", alertUser);
      // window.removeEventListener('pagehide', alertUser);
    };
  }, []);

  //For tracking when the browser is closed on phone
  // const mobileUserAlert = async () => {
  //   if (props.event.items[0].registrationRequired) {
  //     // console.log("This is event with reg");
  //     Object.entries(authorization).length > 0 &&
  //       sendUserStartAndEndTime(
  //         authorization.registrants[0]._id,
  //         userStartTimeTracking
  //       );
  //     //Remove from live users
  //     Object.entries(authorization).length > 0 &&
  //       (await axios.get(
  //         `${process.env.REACT_APP_STATS_API_URL}/event/removeLiveUser/${props.event.items[0]._id}/?userID=${authorization.registrants[0]._id}&token=${process.env.REACT_APP_STATS_API_TOKEN}`
  //       ));
  //   } else {
  //     console.log("This is event with no reg");
  //     let checkLocalNoReg = localStorage.getItem("noReglocalData");
  //     let parsedResultnoReglocalData = JSON.parse(checkLocalNoReg);
  //     // console.log('no reg event id', props.match.match.params.eventId)
  //     let result = parsedResultnoReglocalData?.user?.events.filter(
  //       (eachData) => {
  //         return eachData.eventID === props.match.match.params.eventId;
  //       }
  //     );

  //     // console.log("Removing NoReg", result[0].sessionUID);
  //     sendUserStartAndEndTimeNoReq(
  //       noReqID,
  //       userStartTimeTracking,
  //       props.event.items[0]._id
  //     );
  //     let res = await axios.get(
  //       `${process.env.REACT_APP_STATS_API_URL}/event/removeLiveUserNoReq/${props.event.items[0]._id}/?token=${process.env.REACT_APP_STATS_API_TOKEN}&userID=${result[0].sessionUID}`
  //     );
  //   }
  // };

  //For tracking when the browser is closed on PC
  // const alertUser = async (e) => {
  //   e.preventDefault();
  //   e.returnValue = "";
  //   // console.log('end time' , new Date())
  //   if (props.event.items[0].registrationRequired) {
  //     sendUserStartAndEndTime(
  //       authorization.registrants[0]._id,
  //       userStartTimeTracking
  //     );
  //     //Remove from live users
  //     let res = await axios.get(
  //       `${process.env.REACT_APP_STATS_API_URL}/event/removeLiveUser/${props.event.items[0]._id}/?userID=${authorization.registrants[0]._id}&token=${process.env.REACT_APP_STATS_API_TOKEN}`
  //     );
  //   } else {
  //     sendUserStartAndEndTimeNoReq(
  //       noReqID,
  //       userStartTimeTracking,
  //       props.event.items[0]._id
  //     );
  //     let res = await axios.get(
  //       `${process.env.REACT_APP_STATS_API_URL}/event/removeLiveUserNoReq/${props.event.items[0]._id}/?userID=${noReqID}&token=${process.env.REACT_APP_STATS_API_TOKEN}`
  //     );
  //   }
  // };

  useEffect(() => {
    if (props.event.items[0].holdingSlide !== null)
      setHoldingSlide(props.event.items[0].holdingSlide.path);

    if (props.event.items[0].webcast.background.page !== null)
      setBackgroundImage(props.event.items[0].webcast.background.page.path);

    if (props.event.items[0].webcast.colour.background !== "")
      setBackgroundColor(props.event.items[0].webcast.colour.background);

    if (props.event.items[0].webcast.holdingCardTransparency) {
      setBgOpacity(props.event.items[0].webcast.holdingCardTransparency);
    }
  }, [props.event.items]);

  const classes = useStyles();

  return (
    <>
      <Suspense fallback={<></>}>
        {holdingSlide !== null ? (
          <BackgroundImg path={holdingSlide} img={true} colour={null} />
        ) : backgroundImage !== null ? (
          <BackgroundImg path={backgroundImage} img={true} colour={null} />
        ) : (
          <BackgroundImg path={null} img={false} colour={backgroundColor} />
        )}
        <CssBaseline />
        <Container
          maxWidth="xl"
          className="mainContainer"
          align="center"
          justify="center"
        >
          <Grid
            container
            id="secondRow"
            alignContent="center"
            justify="center"
            spacing={0}
            direction="column"
            alignItems="center"
            style={{ minHeight: "100vh" }}
          >
            <Card
              className={`${classes.root} ${
                bgOpacity ? "bgLogoTransparency" : ""
              } `}
            >
              <CardActionArea>
                {logoPath && (
                  <Logo
                    src={logoPath}
                    title={props.event.items[0].title}
                    logoPadding={logoPadding}
                    backgroundColor="#fff"
                  />
                )}
                <CardContent justify="center" style={{ textAlign: "center" }}>
                  <Typography gutterBottom variant="h6" component="h6" className="title" style={{fontSize:'20px'}}>
                    {props.event.items[0].assetType === "video"
                      ? "Thank you for watching"
                      : "Thank you for listening"}
                  </Typography>
                  {brandingFound ? (
                    <h1 className="title branding-title">
                      {props.event.items[0].title}
                    </h1>
                  ) : (
                    <Typography gutterBottom variant="h5" component="h5">
                      <p>{props.event.items[0].title}</p>
                    </Typography>
                  )}
                  <Typography
                    className={'description'}
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    The live broadcast has now finished. <br />
                    Please come back later for the archive recording (if made
                    available)
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Container>
      </Suspense>
    </>
  );
};

export default React.memo(PendingArchive);
