import { useState, useEffect, useRef } from 'react';

export default function useDynamicColor(event) {
  const mounted = useRef(false);

  const [getCorrectColor, setGetCorrectColor] = useState('#ffffff');

  const hexToRgb = (hex) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  };

  const setContrast = (rgb) =>
    (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000 > 125
      ? '#333333'
      : '#ffffff';

  // getCorrectColor = setContrast(hexToRgb());
  useEffect(() => {
    mounted.current = true;

    if (!event.webcast) return;
    if (event.webcast.colour) {
      if (event.webcast.colour.border[0] !== '#') {
        setGetCorrectColor(
          setContrast(hexToRgb(`#${event.webcast.colour.border}`))
        );
      } else {
        setGetCorrectColor(setContrast(hexToRgb(event.webcast.colour.border)));
      }
    }

    return function cleanup() {
      mounted.current = false;
    };
  }, [event]);

  return getCorrectColor;
}
