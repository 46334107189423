import React, { useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

// import { ASSETS_BASE_URL } from '../../config/config';

// IMG
import logoPlaceholder from '../../static/logo-placeholder100.png';

import './HeaderBar.css';

// NOTES
// The CSS Class "title" is a brandign stuff and it comes from the HTML HEAD

const HeaderBar = ({
  logoPath,
  logoPadding,
  eventHeaderTitle,
  headerBackground,
  brandingFound,
  isTransparent,
  titleStyles
}) => {
  if (!headerBackground) {
    headerBackground = '#ffffff';
  }
  if (headerBackground[0] !== '#') {
    headerBackground = '#' + headerBackground;
  }


  useEffect(()=>{
    console.log('--- title style', titleStyles)
  },[])

  const hexToRgb = (hex) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  };

  const setContrast = (rgb) =>
    (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000 > 125
      ? '#333333'
      : 'white';

  const getCorrectColor = setContrast(hexToRgb(headerBackground));

  return (
    <div>
      <AppBar
        position='static'
        color='transparent'
        elevation={0}
        style={{ background:isTransparent ? 'transparent' :  headerBackground }}
      >
        <Toolbar style={{ paddingRight: '1.75rem', paddingLeft: '0px' }}>
          <Grid
            container
            direction='row'
            justify='space-between'
            alignItems='center'
            spacing={0}
          >
            <Grid
              item
              className='logo-container'
              style={{ padding: logoPadding }}
              xs={12}
              sm={5}
              md={5}
              lg={5}
              xl={5}
              ml={1}
            >
              {logoPath === null ? (
                <img src={logoPlaceholder} alt='logo' className='logo' />
              ) : (
                <img
                  src={`${process.env.REACT_APP_ASSETS_BASE_URL}/${logoPath}`}
                  alt='logo'
                  className='logo'
                />
              )}
            </Grid>
            <Grid item xs={12} sm={7} md={7} lg={7} xl={7} mr={2}>
              <h2
                className={
                  brandingFound ? 'title header-title-align' : 'header-title title' 
                }
                // style={{ color: !brandingFound && getCorrectColor }}
                  // style={{
                  //   fontFamily:titleStyles?.fontFamily,
                  //   color:titleStyles?.fontColor,
                  //   fontSize:titleStyles?.fontSizeBase,
                  //   fontWeight:titleStyles?.fontWeight
                  // }}
                
              >
                {eventHeaderTitle} 
              </h2>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
};

const areEqual = (prevProps, nextProps) => {
  return prevProps.logoPath === nextProps.logoPath;
};

export default React.memo(HeaderBar, areEqual);
