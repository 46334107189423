import React from "react";
import { Route, Switch } from "react-router-dom";

import ZoomLayout from "../layout/zoom";
import NotFound from "../components/UI/NotFound";
import CheckYourEmail from "../components/UI/CheckYourEmail";

const ZoomRouter = ({ match }) => {
  return (
    <Switch>
      <Route
        exact
        path={`${match.path}/:eventId`}
        render={(match) => <ZoomLayout {...match} match={match} />}
      />
      <Route
        exact
        path={`${match.path}/:eventId/:confirmationId`}
        render={(match) => <ZoomLayout {...match} match={match} />}
      />
      <Route component={NotFound} />
    </Switch>
  );
};

export default ZoomRouter;
