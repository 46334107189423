import React, { createContext, useEffect, useRef, useState } from "react";
import submitLogs from "../../config/AWSGlobalLog";
import { useTheme } from "@material-ui/core";


const SocketContainerWithReg = createContext(null);

export { SocketContainerWithReg };

export default ({ children, socket_wc,onDemand, authorization,noRegID, name, email, userID, eventID }) => {



    const [socketioMethods, setSocketioMethods] = useState({});

    useEffect(() => {

        console.log('Whats in socket',socket_wc)
        if (socket_wc) {

            socket_wc.on('connect', () => {
                submitLogs(
                    JSON.stringify({
                        status: 'User connected with socket.io',
                        message: `Socket.io Connected, for user ${ authorization ? JSON.stringify(authorization) : 'User data not found'}`
            
                    }) )
            });



            socket_wc.on('connect_error', (error) => {
                submitLogs(
                    JSON.stringify({
                        status: 'ERROR - connect_error',
                        message: 'SOCKET.IO connect_error',
                        error: error
                    }) )

                console.error('Socket.io connection error:', error);
            });

            socket_wc.on("disconnect", (reason) => {
                submitLogs(
                    JSON.stringify({
                        status: 'ERROR - disconnect',
                        message: 'SOCKET.IO disconnect',
                        error: reason
                    }) )
                console.log('Socket.io connection error Reason ---->>>>>>>:', reason);

            });

        }
        return () => {
            // socket.emit('leaveRoom', eventId, userId);
            try {
                // socket.disconnect();
              socket_wc.emit('leaveEvent',authorization.registrants[0].eventid, authorization.registrants[0].findId);  

                console.log("Disconneting from socket.io");

            } catch (error) {

                submitLogs(
                    JSON.stringify({
                        status: 'ERROR - Tab closed etc',
                        message: 'SOCKET.IO disconnecting',
                        error: error
                    }) )
                console.error('Error disconnecting socket:', error);
            }
        }

    }, []);


    useEffect(() => {
        // if (socket_wc.connected) {

                // if(Object.entries(authorization).length >0) {

                console.log('socket_wc', socket_wc)

                    if (socket_wc) {

                        try {
                            let userData = {
                                name:authorization.userData.name,
                                email:authorization.userData.email,
                                userID:authorization.registrants[0].findId,
                                eventID:authorization.registrants[0].eventid,
                                onDemand:onDemand
                            }
    
                            socket_wc.on('reconnect', (attemptNumber) => {
                                console.log(`Reconnected after ${attemptNumber} attempts`);
    
                                submitLogs(
                                    JSON.stringify({
                                        status: 'RECONNECTING',
                                        message: `Attempt number ${attemptNumber}, for user ${ userData ? JSON.stringify(userData) : 'User data not found'}`
                            
                                    }) )
                                
                                //Add below line, if socket.recovered did not worked (on socket.io server)
                                // socket_wc.emit('join', userData);
                            });
    
                            console.log('Connected', userData)
                            socket_wc.emit('join',userData);
        
                        } catch (error) {
                            submitLogs(
                                JSON.stringify({
                                    status: 'ERROR',
                                    message: 'SOCKET.IO Joining | Reconnecting',
                                    error: error.message
                                }) )
                            console.log('Socket.io connection error:', error.message); 
                        }

                }

                // }


        // }

        return () => {
            // socket_wc.off('message')
            // socket.disconnect()

        }
    }, [socket_wc,authorization])




    return (
        <SocketContainerWithReg.Provider value={socketioMethods}>
            {children}
        </SocketContainerWithReg.Provider>
    );
};
