import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PreviewLayout from '../layout/preview';
import NotFound from '../components/UI/NotFound';

const PreviewRouter = ({ match }) => {
  return (
    <Switch>
      <Route
        exact
        path={`${match.path}/:eventId`}
        render={(match) => <PreviewLayout {...match} match={match} />}
      />
      <Route
        exact
        path={`${match.path}/:eventId?template=clean&controlbar=no&reg=off`}
        render={(match) => <PreviewLayout {...match} match={match} />}
      />
      <Route component={NotFound} />
    </Switch>
  );
};

export default PreviewRouter;
