import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { makeStyles } from '@material-ui/core/styles';
// import { ASSETS_BASE_URL } from '../../config/config';

import './PresenterAvatarAudio.css';

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: '6rem',
  },
}));

const PresenterAvatarAudio = ({
  active,
  assetType,
  checkIfChaptersInLayout,
}) => {
  // console.log(checkIfChaptersInLayout);
  const classes = useStyles();

  let presenterImage = '';
  let presenterName = '';

  if (active) {
    if (active.images[0]) {
      if (active.images[0].upload) {
        if (active.images[0].upload.path) {
          presenterImage = active.images[0].upload.path;
        }
      }
    }
    if (active.name) {
      presenterName = active.name;
    }
  }

  return (
    <div className='presenterImgContainer'>
      {!presenterImage ? (
        <Avatar
          className={`presenterImage ${classes.root}`}
          alt={presenterName}
          src={`${process.env.REACT_APP_ASSETS_BASE_URL}/${presenterImage}`}
          variant='square'
        />
      ) : (
        <div
          className={
            !checkIfChaptersInLayout ? 'presenterImg' : 'imgWithChapters'
          }
        >
          <LazyLoadImage
            src={`${process.env.REACT_APP_ASSETS_BASE_URL}/${presenterImage}`}
            alt={presenterName}
            effect='blur'
            height={'100%'}
            delayTime={600}
          />
        </div>
      )}
    </div>
  );
};

export default React.memo(PresenterAvatarAudio);
