import React, { Suspense, lazy, useState, useRef } from 'react';

// MUI
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

// COMPONENTS
import Spinner from '../UI/Spinner';

// LAZY COMPONENTS
const LazyJotform = lazy(() => import('./LazyJotform'));

const useStyles = makeStyles((theme) => ({
  formContainer: {
    padding: '2rem 1.5rem',
    [theme.breakpoints.down('xs')]: {
      padding: '2rem 0px',
    },
  },
  closeBtn: {
    left: 'calc(100% - 64px)',
    paddingTop: '32px',
  },
}));

const PollDrawer = ({ drawerPollState, formUrl, onClose }) => {
  const node = React.useRef();

  const [infoState, setInfoState] = useState(drawerPollState);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setInfoState({ ...infoState, [anchor]: open });
  };

  const classes = useStyles();

  return (
    <div
      role='presentation'
      onClick={toggleDrawer(`left`, false)}
      onKeyDown={toggleDrawer(`left`, false)}
      
    >
      <IconButton
        aria-label='close'
        disableRipple={true}
        onClick={onClose}
        className={classes.closeBtn}
      >
        <CloseIcon />
      </IconButton>
      <Grid container className={classes.formContainer}>
        {formUrl !== '' ? (
          <Suspense fallback={<Spinner />}>
            <LazyJotform formUrl={formUrl} />
            
          </Suspense>
        ) : (
          <Typography variant='h4'>Link not found</Typography>
        )}
      </Grid>
    </div>
  );
};

export default PollDrawer;
