import  React,{ useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

// import { REACT_APP_GOOGLE_ANALYTICS_ID } from '../config/config';
import WebcastingContext from '../context/webcasting/webcastingContext';

const GA = () => {
    const webcastingContext = useContext(WebcastingContext);
    const {ga_enabled} = webcastingContext
    const location = useLocation();
    const [gaInitialized, setGaInitialized] = useState(false);
  
    useEffect(() => {
      if (
        // !window.location.href.includes('localhost') ||
        !window.location.href.includes('preview')
      ) {
        if(ga_enabled) {
                    console.log('ga_enabled',ga_enabled)
          ReactGA.initialize(`${process.env.REACT_APP_GOOGLE_ANALYTICS_ID}`, {
            debug: false,
            titleCase: false,
            redactEmail: false,
            gaOptions: { siteSpeedSampleRate: 100 },
          });
          setGaInitialized(true);
        }
        }
    }, [ga_enabled]);
  
    useEffect(() => {
      // console.log('GA Event')
      if (gaInitialized) {
        // console.log('initialized')
        ReactGA.pageview(location.pathname + location.search);
      }
    }, [gaInitialized, location]);

    return(
        <></>
      )
  };

 

export default GA;