import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Chip, Grid, List, ListItem, ListItemText, Modal, TextField, Typography, makeStyles, styled, } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import WebcastingContext from "../../context/webcasting/webcastingContext";
import '../questions/index.css'
import { ExpandMore } from "@material-ui/icons";
import { set } from "react-ga";

function rand() {
    return Math.round(Math.random() * 20) - 10;
}


const CustomHeading = styled(Typography)(({ theme }) => ({
    fontSize: "2rem",
    textAlign: 'start',
    padding: '1rem',
    [theme.breakpoints.down("sm")]: {
        fontSize: '2rem'
    }
}));

function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();

    return {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        height: "auto",
        width: "60%",
        bgcolor: "background.paper",
        // border: "2px solid #000",
        boxShadow: 24,
        p: 4,
        zIndex: 9000,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        // border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        height: "auto",
        width: "50%",
        bgcolor: "background.paper",
        // border: "2px solid #000",
        boxShadow: 24,
        zIndex: 9000,
        [theme.breakpoints.down("sm")]: {
            width: '70%',
            fontSize: '1rem'
        },
        [theme.breakpoints.up("md")]: {
            width: '70%',
        }, [theme.breakpoints.up("lg")]: {
            width: '50%',
        }

    },


}));


const HelpModal = ({ openHelpModal, handleCloseHelpModal }) => {

    const webcastingContext = useContext(WebcastingContext);


    const { sendQueryEmail, querySent, authorization, event, resetSendQuery } = webcastingContext


    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);

    const [showHelpForm, setShowHelpForm] = useState(false)

    const [name, setName] = useState('')
    const [nameError, setNameError] = useState(false)


    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState(false)


    const [query, setQuery] = useState('')
    const [queryError, setQueryError] = useState(false)


    useEffect(() => {
        if (querySent === 'sent') {
            // setHelp('ok')
            setName('')
            setEmail('')
            setQuery('')
        }
    }, [querySent])


    useEffect(() => {

        if (Object.entries(authorization).length > 0) {

            console.log('Is there name field help', Object.entries(authorization.userData))

            if(Object.entries(authorization.userData).length>0) {
                    if(authorization.userData.hasOwnProperty("name")){
                        setName(authorization.userData.name)
                    }
            }

         

            if (event.items[0].registrationRequired) {
                setEmail(authorization.userData.email)
            }
        }

    }, [authorization, querySent])


    let handleQuerySubmit = () => {

        if (!name) {
            setNameError(true)
        }

        if (!email) {
            setEmailError(true)
        }

        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            setEmailError(true)
        }

        if (!query) {
            setQueryError(true)
        }


        if (!nameError && !emailError && !queryError) {
            //Send email

            sendQueryEmail({
                fromEmail: email,
                name: name,
                text: query,
                allFields: authorization.userData,
                eventTitle: event.items[0].title
            })
        }

        // setShowHelpForm(false)

    }
    let handleChangeName = (e) => {
        setNameError(false)
        setName(e.target.value)
    }


    let handleChangeEmail = (e) => {
        setEmailError(false)
        setEmail(e.target.value)

    }


    let handleChangeQuery = (e) => {
        setQueryError(false)
        setQuery(e.target.value)

    }

    let handleShowHelpForm = ()=>{
        setShowHelpForm(true)
        setQuery('')
        resetSendQuery('')
    }
    let handleShowHelpFormClose = ()=>{
        setShowHelpForm(false)
    }


    let handleSubmitAgain =()=>{
        setQuery('')
        resetSendQuery('')

    }

    return (
        <>

            <Modal
                open={openHelpModal}
                onClose={handleCloseHelpModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div className={classes.paper}>

                    <Typography variant='h4' className='title'>
                        Help & Support
                    </Typography>


                    <Grid container justifyContent={'center'} spacing={2} style={{ marginTop: '1rem' }}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Typography className="description">
                                Our webcasts are compatible with a wide range of devices and operating systems without the
                                need for any special software. If you are having any issues hearing/viewing the webcast,
                                please try the following:
                            </Typography>
                            <List>
                                <ListItemText
                                    classes={{ primary: 'description' }}
                                    primary="- Unmute the audio/video player or, if that doesn’t work" />
                                <ListItemText
                                    classes={{ primary: 'description' }}
                                    primary="- Hard refresh your browser (to clear the cache) and unmute your audio/video player" />
                                    
                                    <Typography className="description">
                                    - If after these actions, you are still having issues, please get
                                    in touch with the team by clicking <span>
                                    <Chip onClick={handleShowHelpForm} label="Here" />
                                    </span>
                                    </Typography>
                                        {/* <ListItemText
                                    classes={{ primary: 'description' }}
                                    primary="- If after these actions, you are still having issues, please click on the Help button below to get
                                    in touch with the team." />
                                    <Chip label="Help" /> */}

                                    
                            </List>
                            <Typography className="description">
                            Thank you, the BRR Media webcast team.
                            </Typography>




                            {/* <Accordion>
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          expandIcon={<ExpandMore />}

        >
                    <Typography className="description">
                     Help
                            </Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container justifyContent={'center'} spacing={2} style={{ marginTop: '1rem' }}>
                        {
                            querySent === 'sent' ?

                                <Grid item xs={12} md={12} lg={12}>

                                    <Typography className="description" style={{ color: 'gray', fontSize: "2rem", textAlign: 'center' }}>Your query has been sent</Typography>

                                </Grid> :

                                <>
                                    <Grid item xs={12} md={12} lg={12}>

                                        <TextField
                                            InputProps={{
                                                classes: 'title'
                                            }}
                                            error={nameError} onChange={handleChangeName} value={name} helperText={nameError && "Enter you name"} label="Enter your name here" variant="outlined" style={{ width: '100%' }} />

                                    </Grid>

                                    <Grid item xs={12} md={12} lg={12}>

                                        <TextField error={emailError} onChange={handleChangeEmail} value={email} helperText={emailError && "Email not valid"} type="email" label="Enter your email here" variant="outlined" style={{ width: '100%' }} />

                                    </Grid>

                                    <Grid item xs={12} md={12} lg={12}>

                                        <TextField error={queryError} onChange={handleChangeQuery} value={query} helperText={queryError && "Enter your query"} multiline rows={4} label="Enter your query here" variant="outlined" style={{ width: '100%' }} />

                                    </Grid>

                                    <Box direction={'row'} style={{ display: 'flex', justifyContent: 'center' }} spacing={2} m={2}>
                                        <Button variant="contained" className="title" onClick={handleQuerySubmit}>Send</Button>
                                    </Box>

                                </>

                        }

                    </Grid>
        </AccordionDetails>
      </Accordion> */}

                        </Grid>
                    </Grid>



      {
        showHelpForm && <Grid container justifyContent={'center'} spacing={2} style={{ marginTop: '1rem' }}>
        {
            querySent === 'sent' ?

                <Grid item xs={12} md={12} lg={12}>
                    <Box style={{display:'flex', justifyContent:'center', gap:'1rem'}}>
                    <Typography className="description" style={{ color: 'gray', fontSize: "2rem", textAlign: 'center' }}>Your query has been sent</Typography>
                    </Box>
                    <Box style={{display:'flex', justifyContent:'center', gap:'1rem'}}>
                    {/* <Button className="description" variant="contained" onClick={handleSubmitAgain}>Submit Again </Button> */}
                    </Box>
                </Grid> :

                <>
                    <Grid item xs={12} md={12} lg={12}>
            {
     Object.entries(authorization).length > 0 &&     authorization.userData.hasOwnProperty("name") &&      <TextField
                InputProps={{
                    classes: 'title'
                }}
                disabled
                error={nameError} onChange={handleChangeName} value={name} helperText={nameError && "Enter you name"} label="Enter your name here" variant="outlined" style={{ width: '100%' }} />

            }
                   
                    </Grid>

                    <Grid item xs={12} md={12} lg={12}>

                        <TextField 
                        disabled={event.items[0].registrationRequired ? true : false}
                        error={emailError} onChange={handleChangeEmail} value={email} helperText={emailError && "Email not valid"} type="email" label="Enter your email here" variant="outlined" style={{ width: '100%' }} />

                    </Grid>

                    <Grid item xs={12} md={12} lg={12}>

                        <TextField error={queryError} onChange={handleChangeQuery} value={query} helperText={queryError && "Enter your query"} multiline rows={4} label="Enter your query here" variant="outlined" style={{ width: '100%' }} />

                    </Grid>

                    <Box direction={'row'} style={{ display: 'flex', justifyContent: 'center', gap:'1rem' }} spacing={2} m={2}>
                        <Button variant="contained" className="description" onClick={handleQuerySubmit}>Send</Button>
                        <Button variant="contained" className="description" onClick={handleShowHelpFormClose}>Cancel</Button>

                    </Box>

                </>

        }

    </Grid>
      }
                    

                </div>
            </Modal>

        </>
    )

}

export default HelpModal