import React, { useState, useEffect, useContext } from "react";
import axios from "axios";

import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CssBaseline,
  Box,
  Checkbox,
  Link,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import CancelIcon from '@material-ui/icons/Cancel';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import MuiAlert from "@material-ui/lab/Alert";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import {
  GDPR_TEXT,
  PRIVACY_POLICY_LINK_TEXT,
  TERMS_AND_CONDITIONS_LINK_TEXT,
  PRIVACY_POLICY_LINK_URL,
  TERMS_AND_CONDITIONS_LINK_URL,
} from "../config/config";
import WebcastingContext from "../context/webcasting/webcastingContext";
import RegisteredUserContext from "../context/registeredUser/registeredUserContext";

// HELPERS
import { gaEvent } from "../helpers/reactGA";
import { useLocation } from "react-router-dom";

// COMPONENTS
// import HeadStyle from "./HeadStyle";
import HeadStyleForNoReg from "./HeadStyleForNoReg";

import Spinner from "../components/UI/Spinner";

import "./index.css";
import "./RegistrationEmail.css";

import LiveLayout from './Live';
import WebcastingPlatform from './WebcastingPlatform';

const useStyles = makeStyles((theme) => ({
  buttonv1:{
    color: "#fff",
    backgroundColor: "#4caf50f2",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#4caf50",
    }
  },
  formRoot: {
    padding: "0.5rem",
  },
  contentText: {
    marginTop: "20px",
    marginBottom: "0px",
    fontSize: "0.8rem",
  },
  input: {
    "& ::before": {
      borderBottom: "2px solid #b3b3b3",
    },
    "& ::after": {
      borderBottom: "2px solid #cccccc !important",
    },
    "& :hover::before": {
      borderBottom: "2px solid #7e7e7e !important",
    },
  },
  footer: {
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: "16px 24px 12px",
    fontSize: "11px",
    "& div": {
      "& span": {
        padding: "0px",
      },
    },
    "& p": {
      margin: "0px 0.2rem",
      lineHeight: 1.3,
      fontSize: "11px",
    },
    "& a": {
      color: "#333",
      fontWeight: "700",
      lineHeight: 1.3,
      textDecoration: "underline",
    },
    "& button": {
      color: "#333",
      backgroundColor: "#fff",
      "&:hover": {
        color: "#fff",
        backgroundColor: "#333",
      },
    },
  },
  footerOnlyBtn: {
    justifyContent: "flex-end",
    padding: "6px 24px 12px",
    "& button": {
      color: "#333",
      backgroundColor: "#fff",
      "&:hover": {
        color: "#fff",
        backgroundColor: "#333",
      },
    },
  },
}));

// const Console = (props) => {
// console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>> passed data to log: ', props);
// return null;
// };

let countTimes = 1;
let fieldObj = {};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ZoomRegistration = (props) => {
  const classes = useStyles();
  const { search } = useLocation();

  const webcastingContext = useContext(WebcastingContext);
  const registeredUserContext = useContext(RegisteredUserContext);
  const {
    loading,
    registrant,
    setRegistrant,
    localData,
    saveLocalData,
    authList,
    branding,
    noRegID,
    verifyEventPassword,
    noRegPassword
  } = webcastingContext;
  const { getUserRegistation, userRegistration } = registeredUserContext;

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  //MEET MAX
  const [autoFillName, setAutoFillName] = useState("");
  const [autoFillCompany, setAutoFillCompany] = useState("");

  const [noRegPassword_, setNoRegPassword] = useState(false)

  const [submitReady, setSubmitReady] = useState(false);
  const [formData, setFormData] = useState({});
  const [registrationData, setRegistrationData] = useState(fieldObj);
  const [disabledEmail, setDisabledEmail] = useState(true);
  const [disabledData, setDisabledData] = useState(true);
  const [submittedEmail, setSubmittedEmail] = useState(false);
  const [submittedData, setSubmittedData] = useState(false);
  const [buttonPasswordText, setButtonPasswordText] = useState("Submit");
  const [buttonEmailText, setButtonEmailText] = useState("Next");
  const [buttonDataText, setButtonDataText] = useState("Register");
  const [, setReadOnly] = useState(false);
  const [, setOpenSnack] = useState(false);
  const [emailAuthorised, setEmailAuthorised] = useState(true);
  const [hasPassword, setHasPassword] = useState(false);
  const [submittedPassword, setSubmittedPassword] = useState(true);
  const [disablePasswordBtn, setDisablePasswordBtn] = useState(true);
  const [passwordFromEvent, setPasswordFromEvent] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [isRegistered, setIsRegistered] = useState(false);

  // const [branding, setBranding] = useState(false);

  const [disclaimer, setDisclainer] = useState(false);
  const [disclaimerText, setDisclaimerText] = useState("");
  const [checkedPrivacy, setCheckedPrivacy] = useState(false);
  const [checkedDisclaimer, setCheckedDisclaimer] = useState(false);
  const [registrationFields, setRegistrationFields] = useState([]);
  const [registrationFieldsFounded, setRegistrationFieldsFounded] =
    useState(false);
  const [showPsw, setShowPsw] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const [selectCountry, setSelectCountry] = useState(null);
  const [selectType, setSelectType] = useState(null);
  const [selectQ1, setSelectQ1] = useState(null);
  const [selectQ2, setSelectQ2] = useState(null);

  const [selectStatus, setSelectStatus] = useState(null);


  const [country, setCountry] = useState("");
  const [type, setType] = useState("");
  const [q1, setQ1] = useState("");
  const [q2, setQ2] = useState("");
  const [status, setStatus] = useState("");


  // Intermediaries Presentation[Event ID: 6617f9d68d18254c64eac445]
  //Checkbox
  const [intermediariesChecked, setintermediariesChecked] = useState(false)
  //NEXT Button (Disable  - True)
  const [intermediariesCheckedButton, setintermediariesCheckedButton] = useState(true)

  //if true -> NO CUSTOM TERMS AND CONDITION PAGE
  const [userReadyintermediariesCheckedButton, setuserReadysetintermediariesCheckedButton] = useState(false)

  //Disagree
  const [userDisagree, setUserDisagree] = useState(false)

  let changeIntermediariesChecked = ()=>{
    setintermediariesChecked(!intermediariesChecked)

  }

  let handleIntermedariesCheck = ()=>{
          setuserReadysetintermediariesCheckedButton(true)
  }
  
  let handleIntermedariesCheckDisagree = ()=>{
    setUserDisagree(true)
  }

  useEffect(()=>{
      if(intermediariesChecked) {
        setintermediariesCheckedButton(false) 
      } else {
        setintermediariesCheckedButton(true) 

      }
  },[intermediariesChecked])


  if (selectCountry !== null && selectType !== null && selectQ1 !== null && selectQ2 !== null && selectStatus !==null) {
    console.log(selectCountry);
    props.event.items[0].registrationFields.map(
      (field) => (fieldObj[`${field.toLowerCase()}`] = "")
    );

    Object.assign(fieldObj, {
      country: "",
      type: "",
      q1:"",
      q2:"",
      status:""
    });
  } else {
    props.event.items[0].registrationFields.map(
      (field) => (fieldObj[`${field.toLowerCase()}`] = "")
    );
  }

  delete fieldObj.Email;

  // @desc SET Google analytics
  useEffect(() => {
    let isMounted = true;
    const date = new Date();

    if (isMounted) {

        if(props.match.match.params.eventId !== '6617f9d68d18254c64eac445') {
          setuserReadysetintermediariesCheckedButton(true)
        }

      gaEvent("Event", "Show", `${props.match.match.params.eventId}`);
      gaEvent(
        "company_name",
        "view",
        `${props.event.items[0].companies[0].name}`
      );
      gaEvent("event_title", "view", `${props.event.items[0].title}`);
      gaEvent(
        "company_code",
        "view",
        `${props.event.items[0].companies[0].collections[0].collection_code}`
      );

      if (props.event.items[0].isLive) {
        // gaEvent('live', 'visit', 0)
        gaEvent("live", "active", date.toISOString());
      } else {
        // gaEvent('onDemand', 'visit', 0)
        gaEvent("onDemand", "active", date.toISOString());
      }
    }

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, []);

  // ?meetmax=true&name=testName&email=testtest@gmail.com&company=brr

  // @desc SET Registration fields when component mounts first time
  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      let meetmaxParmas = new URLSearchParams(search).get("meetmax");
      let meetmaxName = new URLSearchParams(search).get("name");
      let meetmaxEmail = new URLSearchParams(search).get("email");
      let meetmaxCompany = new URLSearchParams(search).get("company");


      if (props.event.items[0].registrationRequired) {
        setRegistrationFieldsFounded(true);

        const registrationFields =
          props.event.items[0].registrationFields.filter(
            (field) => field.toLowerCase() !== "email"
          );
        setRegistrationFields(registrationFields);
      }

      if (meetmaxParmas === "true") {
        // let autoFillEmail = props.match.match.params.email

        setEmail(meetmaxEmail);
        setAutoFillName(meetmaxName);
        setAutoFillCompany(meetmaxCompany);


        //         setRegistrationData({
        //   ...registrationData,
        //   ['name']: props.match.match.params.name,
        // });
      }

      // @desc  Here we can find some patches based on the customFields
      //        At the moment we only have the country list for Tesco
      if (props.event.items[0].customFields.length > 0) {
        const customFields = props.event.items[0].customFields;
        // Country select
        const countries = customFields.filter((e) => e.key === "country");

        // type select
        const type = customFields.filter((e) => e.key === "type");

        // Q1 select
        const q1 = customFields.filter((e) => e.key === "Will you be attending the presentation?");
        // Q2 select
        const q2 = customFields.filter((e) => e.key === "Will you be attending the tour?");

        const status = customFields.filter((e) => e.key === "status");

        console.log('custom fields', status)

        if (countries.length > 0) setSelectCountry(countries);
        if (type.length > 0) setSelectType(type);
        if (q1.length > 0) setSelectQ1(q1);
        if (q2.length > 0) setSelectQ2(q2);
        if (status.length > 0) setSelectStatus(status);

      }
    }

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (new URLSearchParams(search).get("meetmax") === "true") {

      // setRegistrationData({
      //   ...registrationData,
      //   ['email']: email,
      // });

      setRegistrationData({
        email: email,
        name: autoFillName,
        organisation: autoFillCompany,
      });
    }
  }, [email, autoFillCompany, autoFillName]);

  useEffect(() => {
    setFormData({
      eventid: props.match.match.params.eventId,
      fields: registrationData,
    });
  }, [registrationData]);

  useEffect(() => {
    if (props.event.items[0].passwordRestriction !== "") {
      setHasPassword(true);
      setSubmittedPassword(false);
      setPasswordFromEvent(props.event.items[0].passwordRestriction);
    }
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  // if (props.event.items) {
  //   if (props.event.items[0].webcast.branding.fonts.length > 0) {
  //     props.event.items[0].webcast.branding.fonts.forEach(
  //       (i) => i.fontLinkHref !== "" && setBranding(true)
  //     );
  //   }
  // }
  // eslint-disable-next-line
  // }, []);

  // >>>>>>>>>>>>>> START CHECKBOXES
  useEffect(() => {
    let isMounted = true;
    authList.success && setCheckedPrivacy(true);

    if (isMounted) {
      if (props.event.items[0]) {
        if (props.event.items[0].disclaimerRequired) {
          setDisclainer(true);
          setDisclaimerText(props.event.items[0].disclaimerBody);
        }
      }
    }

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   authList.success && setCheckedPrivacy(true);

  //   // eslint-disable-next-line
  // }, []);

  const handleCheckboxPrivacy = (event) => {
    setCheckedPrivacy(event.target.checked);
  };

  const handleCheckboxDisclaimer = (event) => {
    setCheckedDisclaimer(event.target.checked);
  };

  // >>>>>>>>>>>>>> END CHECKBOXES

  const handleChangePassword = (event) => {
    const password = event.target.value;
    setPassword(password);
    password.length < 1
      ? setDisablePasswordBtn(true)
      : setDisablePasswordBtn(false);

  };

  const handleChangeEmail = (event) => {
    const email = event.target.value;

    setEmail(email);
    const registrationDataKey = event.target.name;
    const registrationDataValue = event.target.value;
    setRegistrationData({
      ...registrationData,
      [`${registrationDataKey}`]: registrationDataValue,
    });


  };

  const handleChangePhone = (event) => {
    const phone = event.target.value;

    setPhone(phone);
    const registrationDataKey = event.target.name;
    const registrationDataValue = event.target.value;
    setRegistrationData({
      ...registrationData,
      [`${registrationDataKey}`]: registrationDataValue,
    });


  };

  const handleChangeData = (event) => {
    const registrationDataKey = event.target.name;
    const registrationDataValue = event.target.value;
    setRegistrationData({
      ...registrationData,
      [`${registrationDataKey}`]: registrationDataValue,
    });
  };

  const handleSubmitPassword = (event) => {
    setButtonPasswordText("sending...");
    setReadOnly(true);
    let checkPassword = false;

    if (password !== passwordFromEvent) {
      setErrorMsg("The given password is not allowed");
      setDisablePasswordBtn(true);
    } else {
      checkPassword = true;
    }

    if (checkPassword) {
      setButtonPasswordText("sending...");
      setReadOnly(true);
      setSubmittedPassword(true);
      // setSubmitReady(true);
      // setSubmittedData(true);
      // setIsRegistered(true)
      if (!props.event.items[0].registrationRequired) {
        console.log('no reg ID', noRegID)
        // window.location.href = `../../broadcast/${props.match.match.params.eventId}/${noRegID}`;
        // window.location.href = `../../broadcast/${props.match.match.params.eventId}?reg=off`;
        // window.location.href = `../../broadcast/${props.match.match.params.eventId}`;
        verifyEventPassword()
        setNoRegPassword(true)
      // setSubmitReady(true);
      // setSubmittedData(true);
      // setIsRegistered(true)
      }

    }
  };

  const handleCheckExistingEmail = () => {

    setButtonEmailText("sending...");

    localStorage.setItem("wpEmail", registrationData.email);
    
    getUserRegistation(
      props.match.match.params.eventId,
      registrationData.email
    );
  };

  const handleChangeCountry = (event) => {
    const registrationDataKey = event.target.name;
    const registrationDataValue = event.target.value;
    setCountry(event.target.value);
    setRegistrationData({
      ...registrationData,
      [`${registrationDataKey}`]: registrationDataValue,
    });
  };

  const handleChangeType = (event) => {
    const registrationDataKey = event.target.name;
    const registrationDataValue = event.target.value;
    setType(event.target.value);
    setRegistrationData({
      ...registrationData,
      [`${registrationDataKey}`]: registrationDataValue,
    });
  };
  const handleChangeQ1 = (event) => {
    const registrationDataKey = event.target.name;
    const registrationDataValue = event.target.value;
    setQ1(event.target.value);
    setRegistrationData({
      ...registrationData,
      [`${registrationDataKey}`]: registrationDataValue,
    });
  };
  ValidatorForm.addValidationRule('isBlackListed', (value) => {
    let valueTolowerCase = value.toLowerCase()
        
    if(props.event.items[0].emailBlacklist.includes(valueTolowerCase)) {
      return false
    }

    const domain = valueTolowerCase.split('@')[1];
    console.log('blacklisted domain', domain)
    if (props.event.items[0].emailBlacklist.includes(`all@${domain}`)) {
      return false; // Block all emails from this domain.
    }
      return true
});
  const handleChangeQ2 = (event) => {
    const registrationDataKey = event.target.name;
    const registrationDataValue = event.target.value;
    setQ2(event.target.value);
    setRegistrationData({
      ...registrationData,
      [`${registrationDataKey}`]: registrationDataValue,
    });
  };



  const handleChangeStatus = (event) => {
    const registrationDataKey = event.target.name;
    const registrationDataValue = event.target.value;
    setStatus(event.target.value);
    setRegistrationData({
      ...registrationData,
      [`${registrationDataKey}`]: registrationDataValue,
    });
  };

  // NOTES //////////////////////////////////////////
  // Create two boolean variables setted to false as initial state and update them based on the UserRegistrationEmail API response
  // At this point we can split the next useEffect in two
  // Now passing a boolean var as dependencie to the useEffect will trigger the logic inside it and only fire the right one
  useEffect(() => {
    if (userRegistration.success) {
      const eventId = userRegistration.registrants[0].eventId;
      const fieldsObj = JSON.parse(userRegistration.registrants[0].fields);

      setEmail(fieldsObj.email);
      setFormData({
        eventid: eventId,
        fields: fieldsObj,
        findId: userRegistration.registrants[0].findId,
        _id: userRegistration.registrants[0]._id,
      });

      gaEvent(
        "login",
        "submit",
        `login successfully with email: ${fieldsObj.email}`
      );

      // setSubmittedEmail(true);
      localStorage.removeItem("wpEmail");

      // next line causes warning error
      setIsRegistered(true);
    }

    if (userRegistration.success === false) {
      const emailLocalStorage = localStorage.getItem("wpEmail");
      setEmail(emailLocalStorage);
      setRegistrationData((prevObj) => ({
        ...prevObj,
        email: emailLocalStorage,
      }));

      if (registrationFields.length === 0) {

        setSubmittedData(true);
        setButtonDataText("sending...");
        setReadOnly(true);

        const eventId = props.match.match.params.eventId;
        setFormData({
          eventid: eventId,
          fields: registrationData,
        });

        setSubmitReady(true);
      }

      setSubmittedEmail(true);
    }
    // eslint-disable-next-line
  }, [userRegistration]);




  const checkIsEmailValidAndAllowed = (domains, singleEmail, email) =>{
        let emailSplit = email.split('@')
        let emailDomain = emailSplit[1]
        console.log('emailDomain', emailDomain)

        if(domains.includes(emailDomain) || singleEmail.includes(email)) {
          return true
        } else {
          return false
        }

  }
  const handleSubmitEmail = (event) => {
    setButtonEmailText("sending...");
    setReadOnly(true);
    let checkEmail = false;
    let checkEmailEmailBlackList = false;

    if (props.event.items[0].emailRestrictions.length === 0) {

      setSubmittedEmail(true);
      checkEmail = true;
    } else  {
      let withAll = props.event.items[0].emailRestrictions.filter((each)=> each.includes('all@')).map(each => each.trim()).map(each => each.slice(4))
      let withoutAll = props.event.items[0].emailRestrictions.filter((each)=> !each.includes('all@')).map(each => each.trim())

      let isEmailValidAndAllowed = checkIsEmailValidAndAllowed(withAll, withoutAll, registrationData.email.toLowerCase().trim())

      if(isEmailValidAndAllowed) {
        checkEmail = true  
      }



    }



    



    

    if (checkEmail) {
      if (registrationFields.length === 0) {

        setSubmittedData(true);
        setButtonDataText("sending...");
        setReadOnly(true);

        const eventId = props.match.match.params.eventId;
        setFormData({
          eventid: eventId,
          fields: registrationData,
        });

        setSubmitReady(true);
      } else {
        handleCheckExistingEmail();
      }



    } else {
      handleBack();
      setEmailAuthorised(false);
      setOpenAlert(true);
    }
  };

  useEffect(() => {
    if (openAlert) {
      const countdown = setTimeout(() => {
        handleCloseSnack();
        setButtonEmailText("NEXT");
      }, 5000);

      return () => clearTimeout(countdown);
    }
    // eslint-disable-next-line
  }, [openAlert]);

  const handleBack = () => {
    setSubmittedEmail(false);
    setButtonEmailText("Next");
    setReadOnly(false);
  };

  const handleSubmitData = async (event) => {
    event.preventDefault();

    setSubmittedData(true);
    setButtonDataText("sending...");
    setReadOnly(true);

    const eventId = props.match.match.params.eventId;
    setFormData({
      eventid: eventId,
      fields: registrationData,
    });

    gaEvent(
      "signup",
      "submit",
      `signup successfully with name: ${registrationData.name} and email: ${registrationData.email}`
    );

    if(typeof(registrationData.password)!== undefined  || typeof(registrationData.password)!== 'undefined') {
      if(props.event.items[0]._id === '650980df50a62b392af1791d' ) {
      if(registrationData.password === 'it123') {
        setSubmitReady(true);

      } else {
        setErrorMsg("Incorrect password");
          //  setSubmittedData(false);
          setSubmittedData(false);
          setDisabledData(true)
          setSubmitReady(false);
          setButtonDataText("LOG IN");
      }
    }

    if(props.event.items[0]._id === '64db32182b85fd91126d7185' ) {
      if(registrationData.password === 'cvs2023!') {
        setSubmitReady(true);

      } else {
        setErrorMsg("Incorrect password");
          //  setSubmittedData(false);
          setSubmittedData(false);
          setDisabledData(true)
          setSubmitReady(false);
          setButtonDataText("LOG IN");
      }
    }
    

    }
  

    if (props.event.items[0].agmCheck) {
      let investorID = registrationData.srn ? registrationData.srn : (registrationData.investorid ? registrationData.investorid : registrationData['shareholder reference number']);
      let pin = registrationData.pin;
      let companyCode = props.event.items[0].companies[0].attributes.lsecode


      let Jdata = await axios.get(`https://brrmediauk-brrmedia-assets.s3.amazonaws.com/AGM-IDS/${companyCode}.json`)
      let json = Jdata.data;
      //    let json = require('../test/testCompany.json');

      //  let success = json.find(e => e.srn === investorID)
      if (typeof(pin) === 'undefined') {
        console.log('without pin')

        let success = false

        json.map((each) => {
          if ((each.srn === Number(investorID)) || each.srn === String(investorID)) {
            success = true
          }
        })

        if (success) {
          setSubmitReady(true);


        } else {
          setErrorMsg("Incorrect details");
          //  setSubmittedData(false);
          setSubmittedData(false);
          setDisabledData(true)
          setSubmitReady(false);
          setButtonDataText("LOG IN");


        }




      } else {
        let success = false
        json.map((each) => {
          if ( (each.srn === Number(investorID) || each.srn === String(investorID)) && (each.pin === Number(pin) || each.pin === String(pin))) {
            success = true
          }
        })

        if (success) {
          setSubmitReady(true);


        } else {
          setErrorMsg("Incorrect details");
          //  setSubmittedData(false);

          setSubmittedData(false);
          setDisabledData(true)
          setSubmitReady(false);
          setButtonDataText("LOG IN");


        }
      }
    } else if(!registrationData.password){
      setSubmitReady(true);

    }


  };

  const handleSubmitDataForMeetMax = (event) => {
    event.preventDefault();

    setSubmittedData(true);
    setSubmitReady(true);

    setButtonDataText("sending...");
    setReadOnly(true);

    gaEvent(
      "signup",
      "submit",
      `signup successfully with name: ${registrationData.name} and email: ${registrationData.email}`
    );

    setSubmitReady(true);
  };

  // SENT DATA TO DB WITH setRegistrant FUNC IN CONTEXT -------------------------
  useEffect(() => {
    if (submitReady) {
      setRegistrant(props.match.match.params.eventId, formData);
    }
  }, [submitReady, formData, props.match.match.params.eventId, setRegistrant]);

  const validatorListenerEmail = (result) => {

    // console.log('checking validator', email, props.event.items[0].emailBlacklist.includes(email))
    
    if (email.length < 1 ) {
      console.log('is going to check thisss?')

      setDisabledEmail(true);
    } else {
      setDisabledEmail(!result);
    }
  };
  function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }
  const validatorListenerData = (result) => {
    const { email, phone,  ...filterdData } = registrationData;
    const fields = Object.entries(registrationData);
    console.log('validatorListenerData - fields', registrationData)
    if (selectCountry !== null || selectType !== null || selectQ1 !== null || selectQ2 !== null || selectStatus !== null ) {
      // console.log('IN VALIDATOR W/ SELECT');
      const isEmpty = fields.every((x) => x[1] !== "");
      isEmpty ? setDisabledData(false) : setDisabledData(true);
      return;
    }

    // console.log('IN VALIDATOR', fields, 'email', email);
    // console.log('each list', email)


    email === undefined ? setDisabledData(true) : setDisabledData(false)

    if (email) {
      // console.log('is it undefined?', email, validateEmail(email))
      validateEmail(email)   ? setDisabledData(false) : setDisabledData(true)

    }
    // email.length < 1 ? setDisabledData(true) :setDisabledData(false)

    fields.forEach((field) => {
      field[1].length < 1 ? setDisabledData(true) : setDisabledData(false);
    });
    
    if(props.event.items[0]._id === '64db32182b85fd91126d7185' || props.event.items[0]._id === '650980df50a62b392af1791d' ) {
    if(typeof(registrationData.password)!== undefined  || typeof(registrationData.password)!== 'undefined') {
      registrationData.password.length>0 ? setDisabledData(false) : setDisabledData(true)
    }
  }





  };

  const saveLocalAuthAndGo = () => {
    ///if event is password protected without registration

    let check = true;
    if (countTimes === 1) {
      countTimes++;
      if (localData.user.events.length > 0) {
        // console.log(localData);
        localData.user.events.forEach((event, i) => {
          if (event.eventId === props.match.match.params.eventId) {
            localData.user.events[i].auth = true;
            localData.user.events[i].zoom = event.zoom ? true : false;
            localData.user.events[i].findId = Object.keys(formData).length
              ? formData.findId
              : registrant.registrant.findId;
            localData.user.events[i].registrant = Object.keys(formData).length
              ? formData.fields
              : registrant.registrant;
            localData.user.events[i]._id = Object.keys(formData).length
              ? formData._id
              : registrant.registrant._id;
            check = false;
          }
        });
        if (check) {
          localData.user.events.push({
            eventId: props.match.match.params.eventId,
            auth: true,
            zoom: props.event.items[0].zoom ? true : false,
            findId: Object.keys(formData).length
              ? formData.findId
              : registrant.registrant.findId,
            registrant: Object.keys(formData).length
              ? formData.fields
              : registrant.registrant,
            _id: Object.keys(formData).length
              ? formData._id
              : registrant.registrant._id,
          });
        }
      } else {
        localData.user.events.push({
          eventId: props.match.match.params.eventId,
          auth: true,
          zoom: props.event.items[0].zoom ? true : false,
          findId: Object.keys(formData).length
            ? formData.findId
            : registrant.registrant.findId,
          registrant: Object.keys(formData).length
            ? formData.fields
            : registrant.registrant,
          _id: Object.keys(formData).length
            ? formData._id
            : registrant.registrant._id,
        });
      }
      //// console.log("going to save: ", localData);
      saveLocalData(localData);
      if (isRegistered) {
        window.location.href = `../../conference/${props.match.match.params.eventId}/${formData.findId}`;
      } else {
        window.location.href = `../../conference/${props.match.match.params.eventId}/${registrant.registrant.findId}`;
      }
    } else {
   console.log("debouncing");
    }
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    console.log('error', errorMsg)
    if (errorMsg !== "") {
      // setRegistrationData({});
      setErrorMsg("");
      setPassword("");
      // setEmail("")
      setOpenSnack(false);
      setDisabledData(false)
      setButtonPasswordText("Submit");
    }

    setButtonEmailText("NEXT");
    setEmailAuthorised(true);
    setOpenSnack(false);
  };

  const handleTermsUseLink = () => {
    if (!props.match.location.pathname.includes("preview")) {
      gaEvent("login", "click", "terms of use link");
    }
  };

  const handlePrivacyLink = () => {
    if (!props.match.location.pathname.includes("preview")) {
      gaEvent("login", "click", "privacy link");
    }
  };

  const handleClickShowPassword = () => {
    setShowPsw(!showPsw);
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  return (
    

    
    // noRegPassword_ ? (
    //     <>
    //            {props.event.items[0].isLive ? (
    //                   <LiveLayout
    //                     match={props.match}
    //                     loading={loading}
    //                     event={props.event}
    //                     saveAuth={false}
    //                     authorization={props.match.match.params.confirmationId}
    //                     localData={localData}
    //                     preview={false}
    //                   />
    //                 ) : (
    //                   <WebcastingPlatform
    //                     match={props.match}
    //                     loading={loading}
    //                     event={props.event}
    //                     saveAuth={true}
    //                     authorization={props.match.match.params.confirmationId}
    //                     localData={localData}
    //                     preview={false}
    //                     branding={branding}
    //                   />
    //                 )}
        
    //     </>
    //   ) : (
        <>
     
        {branding ? (
          <HeadStyleForNoReg branding={branding} />
        ) : (
          <HeadStyleForNoReg />
        )}
        <CssBaseline />

{
  props.match.match.params.eventId === '6617f9d68d18254c64eac445' && !userDisagree && !userReadyintermediariesCheckedButton &&
   
    <>
<Box m={2} p={1}>
<Typography  className="title" style={{textAlign:'center',fontWeight:'bold'}}>
Registration for the presentation to Intermediaries on Thursday 18th April at 11.30 am
                  </Typography>
                  <br/>
            <Typography  className="title" style={{textAlign:'center',fontWeight:'bold'}}>
            IMPORTANT NOTICE
                  </Typography>
                  <br/>
              <Box style={{display:'', flexDirection:''}}>

              <Typography className="description">
                <b>IMPORTANT: You must read the following disclaimer before registering your attendance for this briefing session (the “Briefing”)</b>. The following disclaimer applies to the presentation that will be delivered at the Briefing, any associated dialogue and/or any related or subsequent materials which you may receive or to which you may have access during or following the Briefing (together, the “Information”). You are advised to read this disclaimer carefully before clicking on the AGREE button below to register your attendance for the Briefing during which you will be accessing and viewing the Information. In registering your attendance for the Briefing, you agree to be bound by the following terms and conditions.
              </Typography>
              <br/>
              <Typography className="description" style={{fontWeight:'bold'}}>
                BY ELECTING TO ATTEND THE BRIEFING AND RECEIVE THE INFORMATION, YOU REPRESENT, WARRANT AND AGREE THAT YOU WILL NOT VIDEOTAPE, RECORD, COPY, DOWNLOAD OR OTHERWISE ATTEMPT TO REPRODUCE OR RE-TRANSMIT IN WHOLE OR IN PART THE INFORMATION.
              </Typography>
              <br/>

              <Typography className="description" style={{fontWeight:'bold'}}>  
                ACCESSING THIS WEBSITE, ATTENDING THIS BRIEFING AND RECEIVING THE INFORMATION IS ONLY PERMISSIBLE FOR PERSONS WHO ARE: (A) LOCATED IN THE UNITED KINGDOM, AND (B) “INVESTMENT PROFESSIONALS” AS SUCH TERM IS DEFINED IN ARTICLE 19 OF THE FINANCIAL SERVICES AND MARKETS ACT 2000 (FINANCIAL PROMOTION) ORDER 2005, AS AMENDED (THE “ORDER”).
              </Typography>
              <br/>
              <Typography className="description">
                The Briefing and the Information are not directed to, or intended for distribution to or use by, any person or entity that is located in any locality, state, country or jurisdiction other than the United Kingdom, including any in which such distribution, publication, availability or use would be contrary to law or regulation or which would require any registration or licensing within such jurisdiction, including, but not limited to, the United States, Australia, Canada, Japan or South Africa. Any failure to comply with these restrictions may constitute a violation of the securities laws of any such jurisdiction. Any person who attends the Briefing, accesses this website or into whose possession the Information comes should inform themselves about, and observe, any such restrictions.                             
                           </Typography>
                           <br/>
                           <Typography className="description">
                           Any securities referred to at the Briefing or in the Information have not been and will not be registered under the US Securities Act of 1933, as amended 
                           <b>(the “US Securities Act”)</b> (including its territories and possessions, any state of the United States and the District of Columbia), and may not be offered, sold or transferred, directly or indirectly, in or into the United States except pursuant to an exemption from, or in a transaction not subject to, the registration requirements of the Securities Act and in compliance with any applicable securities laws of any State or other jurisdiction of the United States. There will be no public offering of securities in the United States.                          
                           </Typography>

                           <br/>
                           <Typography className="description">
                           If you are not permitted to attend the Briefing or access this website or are in any doubt as to whether you are permitted to attend the Briefing or access this website, please exit this website.                    
                           </Typography>
                           <br/>
                           <Typography className="description">
                           The Information has not been independently verified and speaks as of the date hereof only and is subject to updating, revision, and amendment without notice. Neither His Majesty’s Treasury <b>(“HM Treasury”) </b>, UK Government Investments Limited <b>(“UKGI”)</b>, Barclays Bank PLC <b>(“Barclays”)</b>, Goldman Sachs International <b>(“Goldman Sachs”)</b> nor any of their respective affiliates nor any of its or their respective directors, officers, partners, employees, agents, advisers or any person acting on their behalf (collectively,<b>“Representatives” </b>) is under any obligation to update or keep current the Information, to correct any inaccuracies which may become apparent or provide the recipient with access to any additional information.
               
                           </Typography>
                           <br/>
                           <Typography className="description">
                           No liability whatsoever (whether in negligence or otherwise) arising directly or indirectly from the use of the Information or its contents is accepted, and no representation, warranty or undertaking, express or implied, is or will be made or given by or on behalf of HM Treasury, UKGI, Barclays, Goldman Sachs, any of their respective affiliates or any of its or their respective Representatives, as to, and no reliance should be placed for any purposes on, the accuracy, completeness or fairness of the information or opinions contained in the Information or for any errors, omissions or misstatements or as to the suitability of any particular investment for any particular investor or for any loss howsoever arising, directly or indirectly, from any use of such information or opinions or otherwise arising in connection therewith. To the fullest extent permissible by law, each of HM Treasury, UKGI, Barclays, Goldman Sachs, their respective affiliates and its and their respective Representatives disclaim all and any responsibility or liability, whether arising in tort, contract or otherwise, which they might otherwise have in respect of the Briefing or the Information. Recipients should not construe the Briefing or the contents of the Information as legal, tax, regulatory, financial or accounting advice and are urged to consult with their own advisers in relation to such matters.
               
                           </Typography>
                           <br/>
                           <Typography className="description">
                           Each of Barclays and Goldmans Sachs is authorised by the Prudential Regulation Authority (the <b>“PRA”</b>) and regulated in the United Kingdom by the PRA and the Financial Conduct Authority and is acting exclusively for HM Treasury and UKGI and no-one else in connection with the Briefing and will not regard any other person (whether or not an attendee at the Briefing and/or a recipient of the Information) as its client in relation to the Briefing, the Information or any other matter contemplated therein and will not be responsible to anyone other than HM Treasury and UKGI for providing the protections afforded to its clients nor for providing advice to any other person in relation to the Briefing, the Information or any other matter contemplated herein.
               
                           </Typography>
                           <br/>
                           <Typography className="description" style={{fontWeight:'bold'}}>
                           By clicking the AGREE button below and electing to attend the Briefing and receive the Information, you (a) agree to the above terms, (b) agree to keep the Information strictly confidential and (c) further represent, warrant, acknowledge and agree that: 
                           </Typography>

                           <br/>
                           <Typography className="description" style={{fontWeight:'bold'}}>
                           (1) you will not print, copy, videotape, record, hyperlink or otherwise attempt to reproduce or retransmit (in any form, including hard copy or electronic distribution format) the Information; 
                           </Typography>

                           <br/>
                           <Typography className="description" style={{fontWeight:'bold'}}>
                           (2) you are located in the United Kingdom and not in any other country; and
                           </Typography>
                           <br/>
                           <Typography className="description" style={{fontWeight:'bold'}}>
                           (3) you are an “investment professional” within the meaning of the Order.                      
                                </Typography>
                                <br/>
                                <Typography className="description" style={{fontWeight:'bold'}}>
                                If you are unable to give any of the foregoing representations or are otherwise restricted by law from accessing this website, registering your attendance at the Briefing and/or receiving the Information, please click the DISAGREE button below to be logged out.                           
                                     </Typography>

        </Box>

        <br/>
        <Box style={{display:'flex', flexDirection:'row'}}>
          
          <Button color="secondary" onClick={handleIntermedariesCheckDisagree}  style={{margin:'auto'}} variant="contained">Disagree</Button>

          <Button  onClick={handleIntermedariesCheck}  className={classes.buttonv1}  style={{margin:'auto'}} variant="contained">Agree</Button>


        </Box>



</Box>
  


    </>
  }


  {
    userDisagree && 
    <Box m={2} p={1}>
     <Box style={{display:'flex', justifyContent:'center'}}>
                  <CancelIcon style={{margin:'auto', textAlign:'center', fontSize:'100px', color:'#f50057', boxShadow:'10px red'}} />
                  </Box>
                  <br/>

                  

<Typography  className="title" style={{textAlign:'center',fontWeight:''}}>
Due to legal restrictions you are not allowed to register for the presentation
</Typography>
 </Box> 
  }

{

 userReadyintermediariesCheckedButton &&   <ValidatorForm
    onSubmit={handleSubmitEmail}
    onError={(errors) => console.log(errors)}
    className={classes.formRoot}
  >
    {branding ? (
      <>
      <h2 className={"title branding-title"}>
        {props.event.items[0].title}
      </h2>
      {
props.event.items[0]._id === '6617f9d68d18254c64eac445' &&
      <h2 className={"title branding-title"}>
      The presentation will contain information that must remain confidential
      </h2>
      }

      </>
      
    ) : (
      <>
      <DialogTitle id="form-dialog-title" className="registration-title">
        {props.event.items[0].title}
      </DialogTitle>
            {
              props.event.items[0]._id === '6617f9d68d18254c64eac445' &&
              <DialogTitle id="form-dialog-title" className="registration-title">
              The presentation will contain information that must remain confidential
              </DialogTitle>
                    }
                    </>
    )}
    <div style={{ width: "100%" }}>
      {emailAuthorised ? (
        <></>
      ) : (
        <Alert severity="error" onClose={handleCloseSnack}>
          Your email is not authorised to watch this event
        </Alert>
      )}
      {errorMsg !== "" && (
        <Alert severity="error" onClose={handleCloseSnack}>
          {errorMsg}
        </Alert>
      )}
    </div>
    {hasPassword && !submittedPassword && !submittedEmail ? (
      <>
        <DialogContent>
          <DialogContentText>
            Please enter the password to access this event
          </DialogContentText>
          <TextValidator
            className={classes.input}
            label="Password"
            onChange={handleChangePassword}
            name="password"
            type={showPsw ? "text" : "password"}
            validators={["required"]}
            // errorMessages={[
            //   errorMsg !== '' && errorMsg,
            //   'this field is required',
            // ]}
            value={password}
            disabled={errorMsg !== ""}
            autoFocus
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPsw ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
        <DialogActions className={`footer ${classes.footerOnlyBtn}`}>
          <Button
            variant="contained"
            onClick={handleSubmitPassword}
            disabled={disablePasswordBtn}
          >
            {buttonPasswordText}
          </Button>
        </DialogActions>
      </>
    ) : submittedPassword && !submittedEmail && !submittedData ? (
      <>
        <DialogContent className="email-input">
          <DialogContentText
            className={`content-text description ${classes.contentText}`}
          >
            Please enter your email address
          </DialogContentText>

          { props.event.items[0].webcast?.zoom?.enable  &&

(
  <Box style={{margin:2}}>
<DialogContentText
    className={`content-text ${classes.contentText}`}
  >
  Please note, you will join via a Zoom Webinar, where you are able to watch the session and use the raised hand function to participate in Q&A.
  <br/>
  Please add your phone number. Unrecognised numbers will be removed from the call.
  </DialogContentText>


  </Box>
)

}


 
          <TextValidator
            // className={classes.input}
            className="description"
 
            autoFocus
            margin="dense"
            id="name"
            fullWidth
            label="Email Address"
            onChange={handleChangeEmail}
            name="email"
            value={email}
            validators={["required", "isEmail", "isBlackListed"]}
            errorMessages={["this field is required", "email is not valid", "Your email is not authorised to watch this event"]}
            validatorListener={validatorListenerEmail}
            disabled={submittedEmail}
          />
        </DialogContent>
        {/* <DialogActions className={`footer ${classes.footer}`}> */}
        <DialogActions
          className={`footer ${!authList.success ? classes.footer : classes.footerOnlyBtn
            }`}
        >
          {authList.success !== true && (
            <div className="footer__content">
              <div>
                <Checkbox
                  checked={checkedPrivacy}
                  onChange={handleCheckboxPrivacy}
                  inputProps={{ "aria-label": "terms of use checkbox" }}
                />
                <Typography vairant="body2" className="description">
                  {GDPR_TEXT}
                  <Link
                    onClick={handleTermsUseLink}
                    href={TERMS_AND_CONDITIONS_LINK_URL}
                    target="_blank"
                  >
                    {TERMS_AND_CONDITIONS_LINK_TEXT}
                  </Link>
                  and{" "}
                  <Link
                    onClick={handlePrivacyLink}
                    href={PRIVACY_POLICY_LINK_URL}
                    target="_blank"
                  >
                    {PRIVACY_POLICY_LINK_TEXT}
                  </Link>
                </Typography>
              </div>
              {disclaimer && (
                <div>
                  <Checkbox
                    checked={checkedDisclaimer}
                    onChange={handleCheckboxDisclaimer}
                    inputProps={{ "aria-label": "disclaimer checkbox" }}
                  />
                  <Typography  className="description">
                    I have read and accepted the <span>disclaimer</span>{" "}
                    down below
                  </Typography>
               
                </div>
                
              )}
 
 
            </div>
          )}
          {props.event.items[0].emailRestrictions.length > 0 ? (
            <Button
              variant="contained"
              onClick={handleSubmitEmail}
              disabled={
                !disclaimer
                  ? !checkedPrivacy || disabledEmail || submittedEmail
                  : !checkedPrivacy ||
                  !checkedDisclaimer ||
                  disabledEmail ||
                  submittedEmail
              }
            >
              {buttonEmailText}
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={handleCheckExistingEmail}
              disabled={
                !disclaimer
                  ? !checkedPrivacy || disabledEmail || submittedEmail
                  : !checkedPrivacy ||
                  !checkedDisclaimer ||
                  disabledEmail ||
                  submittedEmail
              }
            >
              {buttonEmailText}
            </Button>
          )}
        </DialogActions>
 
        <DialogContent>
        <Typography  className="description">
        On registering for this event, analytical cookies will be stored on your browser. If you do not wish that to happen, then you should not proceed
 
                  </Typography>
          </DialogContent>
 
        {disclaimer && (
          <DialogContent className="disclaimer-content">
            <h3>Disclaimer</h3>
            <div dangerouslySetInnerHTML={{ __html: disclaimerText }}></div>
          </DialogContent>
        )}
        
      </>
    ) : submittedEmail && !submittedData && registrationFieldsFounded ? (
      <>
        <DialogContent>
          <DialogContentText
            className={`content-text ${classes.contentText}`}
          >
            Please fill out the details below
          </DialogContentText>
 
          {registrationFields.map((field, idx) => (
            <TextValidator
              className={classes.input}
              key={idx}
              // key={field.replace(/\W/g, '')}
              autoFocus={registrationFields[0] === field && true}
              margin="dense"
              fullWidth
              label={field}
              onChange={handleChangeData}
              name={field.toLowerCase()}
              value={registrationData[`${field.toLowerCase()}`] || ""}
              validators={["required", "minStringLength: 1"]}
              errorMessages={[`${field}`, "this field is required"]}
              validatorListener={validatorListenerData}
              disabled={submittedData}
            />
          ))}
 
          {selectType !== null && (
            <Box className="select-in-registration-container select-mt">
              <SelectValidator
                className="select-in-registration"
                name="type"
                value={type}
                onChange={handleChangeType}
                fullWidth
                validators={["required", "minStringLength: 1"]}
                errorMessages={["this field is required"]}
                validatorListener={validatorListenerData}
                SelectProps={{
                  displayEmpty: true,
                }}
              >
                <MenuItem value="">
                  <em>Select Type</em>
                </MenuItem>
                {selectType[0].value.split(",").map((el) => (
                  <MenuItem key={el.trim()} value={el.trim()}>
                    {el.trim()}
                  </MenuItem>
                ))}
              </SelectValidator>
            </Box>
          )}
 
          {selectCountry !== null && (
            <Box className="select-in-registration-container">
              <SelectValidator
                className="select-in-registration"
                name="country"
                value={country}
                onChange={handleChangeCountry}
                fullWidth
                validators={["required", "minStringLength: 1"]}
                errorMessages={["this field is required"]}
                validatorListener={validatorListenerData}
                SelectProps={{
                  displayEmpty: true,
                }}
              >
                <MenuItem value="">
                  <em>Select Country</em>
                </MenuItem>
                {selectCountry[0].value.split(",").map((el) => (
                  <MenuItem key={el.trim()} value={el.trim()}>
                    {el.trim()}
                  </MenuItem>
                ))}
              </SelectValidator>
            </Box>
          )}
 
 {selectQ1 !== null && (
            <Box className="select-in-registration-container select-mt">
              <SelectValidator
                className="select-in-registration"
                name="Attending the presentation?"
                value={q1}
                onChange={handleChangeQ1}
                fullWidth
                validators={["required", "minStringLength: 1"]}
                errorMessages={["this field is required"]}
                validatorListener={validatorListenerData}
                SelectProps={{
                  displayEmpty: true,
                }}
                label="Will you be attending the presentation?"
              >
                {selectQ1[0].value.split(",").map((el) => (
                  <MenuItem key={el.trim()} value={el.trim()}>
                    {el.trim()}
                  </MenuItem>
                ))}
              </SelectValidator>
            </Box>
          )}
          {selectQ2 !== null && (
            <Box className="select-in-registration-container select-mt">
              <SelectValidator
                className="select-in-registration"
                name="Attending the tour?"
                value={q2}
                onChange={handleChangeQ2}
                fullWidth
                validators={["required", "minStringLength: 1"]}
                errorMessages={["this field is required"]}
                validatorListener={validatorListenerData}
                SelectProps={{
                  displayEmpty: true,
                }}
                label="Will you be attending the tour?"
              >
                {selectQ2[0].value.split(",").map((el) => (
                  <MenuItem key={el.trim()} value={el.trim()}>
                    {el.trim()}
                  </MenuItem>
                ))}
              </SelectValidator>
            </Box>
          )}
 {selectStatus !== null && (
            <Box className="select-in-registration-container">
              <SelectValidator
                className="select-in-registration"
                name="status"
                value={status}
                onChange={handleChangeStatus}
                fullWidth
                validators={["required", "minStringLength: 1"]}
                errorMessages={["this field is required"]}
                validatorListener={validatorListenerData}
                SelectProps={{
                  displayEmpty: true,
                }}
              >
                <MenuItem value="">
                  <em>Select Status</em>
                </MenuItem>
                {selectStatus[0].value.split(",").map((el) => (
                  <MenuItem key={el.trim()} value={el.trim()}>
                    {el.trim()}
                  </MenuItem>
                ))}
              </SelectValidator>
            </Box>
          )}
          <TextValidator
            className={classes.input}
            margin="dense"
            fullWidth
            label="Email Address"
            onChange={handleChangeEmail}
            name="email"
            value={email}
            validators={["required", "isEmail"]}
            errorMessages={["this field is required", "email is not valid"]}
            validatorListener={validatorListenerData}
            disabled={submittedData}
          />
        </DialogContent>
        <DialogActions className={`footer ${classes.footerOnlyBtn}`}>
          {new URLSearchParams(search).get("meetmax") === "true" ? (
            <Button
              className="btn-login"
              variant="contained"
              onClick={handleSubmitDataForMeetMax}
            >
              {buttonDataText}
            </Button>
          ) : (
            <Button
              className="btn-login"
              variant="contained"
              onClick={handleSubmitData}
              disabled={disabledData || submittedData}
            >
              {buttonDataText}
            </Button>
          )}
        </DialogActions>
      </>
    ) : (
      <>
        <DialogContent>
          <Box mt={2}>
            <Spinner />
          </Box>
        </DialogContent>
        <DialogActions>&nbsp;</DialogActions>
      </>
    )}
  </ValidatorForm> 
 

    }




          

        <>
          {registrant.success || isRegistered ? (
            <>{saveLocalAuthAndGo()}</>
          ) : (
            <></>
          )}
        </>
      
        </>
      // )
      
    
   
    
  );
};

export default React.memo(ZoomRegistration);
