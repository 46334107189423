import React, { useState, useEffect } from 'react';

// Components
import HeadStyle from './HeadStyle';
import PasswordForm from '../components/forms/PasswordForm';
import PendingArchive from './PendingArchive';

const PendingArchiveContainer = ({
  event,
  match,
  loading,
  saveAuth,
  authorization,
  localData,
  branding,
}) => {
  const eventPassword = event.items[0].passwordRestriction;
  const [hasPassword] = useState(eventPassword !== '');
  const [passwordIsCorrect, setPasswordIsCorrect] = useState(false);
  const [brandingFound, setBrandingFound] = useState(false);

  useEffect(() => {
    setBrandingFound(branding);
  }, [branding]);

  return (
    <>
      {brandingFound ? <HeadStyle branding={brandingFound} /> : <HeadStyle />}
      {hasPassword ? (
        <PasswordForm
          eventPassword={eventPassword}
          setPasswordIsCorrect={setPasswordIsCorrect}
        />
      ) : (
        <PendingArchive
          match={match}
          loading={loading}
          event={event}
          saveAuth={true}
          authorization={match.match.params.confirmationId}
          localData={localData}
          brandingFound={brandingFound}
        />
      )}
      {passwordIsCorrect && (
        <PendingArchive
          match={match}
          loading={loading}
          event={event}
          saveAuth={true}
          authorization={match.match.params.confirmationId}
          localData={localData}
          brandingFound={brandingFound}
        />
      )}
    </>
  );
};

export default React.memo(PendingArchiveContainer);
