import React, { useState, useEffect, useRef } from 'react';
import {
  WOWZA_CONFIG_JSON_URL} from '../../../config/config';
import axios from 'axios';

import Spinner from '../../UI/Spinner';
import WowzaVideoJsPlayer from './WowzaVideoJsPlayer';

import './WowzaPlayer.css';
// let confCounter = 1;

const WowzaPlayerContainer = (props) => {
  const [settingsOk, setSettingsOk] = useState(false);
  const [url, setUrl] = useState('');
  const [playerId, setPlayerId] = useState('');
  let wowzaSettings = useRef(false);

  useEffect(() => {
    let componentMounted = true;
    async function fetchData() {
      // console.log('Configuring WOWZA PLAYER...', props);
      wowzaSettings.current = await axios(
        `${WOWZA_CONFIG_JSON_URL}${props.media.wowzaCloudLiveid}`
      );
      if (componentMounted) {
        console.log('WOWZA PLAYER configured PROPS', props.event.items[0]._id );
        setUrl(wowzaSettings.current.data.live_stream?.player?.hls_playback_url);
        setPlayerId(wowzaSettings.current.data.live_stream?.player?.id);
        setSettingsOk(true);
      }
    }
    fetchData();

    return () => {
      setUrl('');
      setPlayerId('');
      componentMounted = false;
    };
  }, [props]);

  const componentRef = useRef();

  // Read component height only when component mount
  useEffect(() => {
    let count = 0;
    if (count === 0) {
      const { height } = componentRef.current.getBoundingClientRect();
      if (props.getHeight !== undefined) {
        props.getHeight(height);
        count++;
      }
    }
    // eslint-disable-next-line
  }, []);

  // Read component height when win resize
  useEffect(() => {
    const onWinResize = () => {
      const { height } = componentRef.current.getBoundingClientRect();
      if (props.getHeight !== undefined) {
        props.getHeight(height);
      }
    };
    window.addEventListener('resize', onWinResize);
  }, [props]);

  return (
    <div ref={componentRef} className='wowzaPlayerContainer'>
      {settingsOk && url !== '' && playerId !== '' ? (
        <>
          <WowzaVideoJsPlayer
            title={props.title}
            sourceURL={url}
            playerId={playerId}
            isOnlyVideo={props.isOnlyVideo}
            preview={props.preview}
            liveStarted={props.event.items[0].liveStarted}
            qnaMode={props.qnaMode}
          />
        </>
      ) : (
        <div className='wowzaPlayerSpinner'>
          <Spinner />
          {/* {console.log('loading wowza player...')} */}
        </div>
      )}
    </div>
  );
};

const areEqual = (prevProps, nextProps) => {
  // console.log('are equal prev', prevProps);
  // console.log('are equal next', nextProps);
  // console.log('are equal', prevProps.title === nextProps.title);
  return prevProps.title === nextProps.title;
};

export default React.memo(WowzaPlayerContainer, areEqual);
