// const dotenv = require('dotenv').config();
module.exports = {
  GDPR_TEXT: 'I have read and accepted the ',
  TERMS_AND_CONDITIONS_TEXT:
    "By clicking 'Log in', you confirm your acceptance of our ",
  TERMS_AND_CONDITIONS_LINK_TEXT: 'terms of use ',
  PRIVACY_POLICY_LINK_TEXT: 'privacy policy',
  TERMS_AND_CONDITIONS_LINK_URL: '../../../../terms_and_conditions',
  PRIVACY_POLICY_LINK_URL: '../../../../privacy',
  WOWZA_PLAYER_JS_URL: '//player.cloud.wowza.com/hosted/9fv9fhwp/wowza.js',
  WOWZA_CONFIG_JSON_URL:
    'https://www.brrmedia.co.uk/wowzacloud/?action=info&liveid=',
  ANTMEDIA_STREAM_URL: '//streaming.lvis.io:/LiveApp/play.html?name=',
};
