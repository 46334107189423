import React, { useEffect, useState, useRef, useContext } from 'react';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import { toast, ToastContainer } from 'react-toastify';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';

import WebcastingContext from '../../context/webcasting/webcastingContext';

import 'react-toastify/dist/ReactToastify.css';
import './MessageToAll.css';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: '0 12px',
    '& h3': {
      color: '#959595',
      fontSize: '.9rem',
      fontWeight: '600',
    },
    '& p': {
      color: '#333',
    },
    '&:last-child': {
      paddingBottom: '8px',
    },
  },
  avatar: {
    position: 'absolute',
    marginLeft: '-35px',
    width: '40px',
    height: '40px',
    backgroundColor: '#f96c47',
    boxShadow: theme.shadows[3],
    [theme.breakpoints.down('481')]: {
      display: 'none',
    },
  },
}));

// Initialize the Toast here instead of returning ToastContainer
// We can pass the configuration settings for the Toast
// toast.configure();

const MessageToAll = ({ companyName }) => {
  const webcastingContext = useContext(WebcastingContext);
  const { messageToAll } = webcastingContext;

  const [message, setMessage] = useState('');
  const prevMessage = useRef();

  const classes = useStyles();

  useEffect(() => {
    if (messageToAll !== message) {
      setMessage(messageToAll.message);
    }
  }, [messageToAll, message]);

  useEffect(() => {
    prevMessage.current = message;

    prevMessage.current === '' && toast.dismiss();

    prevMessage.current &&
      toast(<Msg />, {
        position: 'top-right',
        autoClose: 60000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        toastId: `${message}`,
      });
  }, [message]);

  // Create Toast
  const Msg = () => (
    <>
      <Avatar className={classes.avatar} alt={companyName}>
        <NotificationsActiveIcon />
      </Avatar>

      <CardContent className={classes.content}>
        <Typography gutterBottom component='h3'>
          {companyName}
        </Typography>
        <Typography component='p'>{message}</Typography>
      </CardContent>
    </>
  );

  return (
    <>
      <ToastContainer progressStyle={{ background: '#c6c6c6' }} />
    </>
  );
};

export default React.memo(MessageToAll);
