import { GET_USER_REGISTRATION } from '../types.js';

export default (state, action) => {
  switch (action.type) {
    case GET_USER_REGISTRATION:
      return {
        ...state,
        userRegistration: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
