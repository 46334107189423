import React, { useState, useEffect, useContext, useRef } from 'react';
import moment from 'moment/moment';
import './index.css';
import WebcastingContext from '../context/webcasting/webcastingContext';

// Components
import HeadStyle from './HeadStyle';
import PendingArchive from './PendingArchive';
import UpcomingEvent from './UpcomingEvent';
import ZoomEvent from './ZoomEvent';
import WebcastingPlatform from './WebcastingPlatform';
import PrimusContainer from '../components/websocket/primusContainer';
import MessageToAll from '../components/UI/MessageToAll';

// const Console = (props) => {
//   // console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>> passed data to log: ', props);
//   return null;
// };

const LiveLayout = (props) => {
  const webcastingContext = useContext(WebcastingContext);
  const {
    loading,
    event,
    authorization,
    localData,
    getEvent,
    getAuthorization,
    readLocalData,
    liveLayout,
    branding
  } = webcastingContext;

  const companyName = useRef();

  // const [branding, setBranding] = useState(false);

  useEffect(() => {
    console.log('live????????????????????????///')
    getEvent(props.match.match.params.eventId);
    readLocalData(event, authorization);
    if (props.match.match.params.confirmationId) {
      getAuthorization(
        props.match.match.params.eventId,
        props.match.match.params.confirmationId
      );
    }
  });

  // useEffect(() => {
  //   if (event.items) {
  //     if (event.items[0].webcast.branding.fonts.length > 0) {
  //       event.items[0].webcast.branding.fonts.forEach(
  //         (i) => i.fontLinkHref !== '' && setBranding(true)
  //       );
  //     }
  //   }
  //   // eslint-disable-next-line
  // }, []);

  useEffect(() => {
    companyName.current = event.items[0].companies[0].name;
    // eslint-disable-next-line
  }, []);

  const ssss = (event) => {
    let start = moment(event.items[0].releaseTime, ' YYYY-MM-DDTHH:mm:ss.SSSZ');
    let now = moment();
    let diff = start.diff(now);
    // console.log('time diff', diff);
    return diff;
  };
if(event.items[0].webcast.zoom.enable==true) 
  return(
    <>
    {branding ? <HeadStyle branding={branding} /> : <HeadStyle />}
    <ZoomEvent
      match={props.match}
      loading={loading}
      event={event}
      saveAuth={true}
      authorization={props.match.match.params.confirmationId}
      localData={localData}
      timeToEvent={ssss(event)}
      brandingFound={branding}
    />
  </>
)
  return (
    <>
      <>
        {branding ? <HeadStyle branding={branding} /> : <HeadStyle />}
        {liveLayout === 'start' || props.preview === true ? (
          <>
            <WebcastingPlatform
              match={props.match}
              loading={loading}
              event={event}
              saveAuth={true}
              authorization={props.match.match.params.confirmationId}
              localData={localData}
              preview={props.preview}
            />
          </>
        ) : liveLayout === 'stop' ? (
          <>
            <PendingArchive
              match={props.match}
              loading={loading}
              event={event}
              saveAuth={true}
              authorization={props.match.match.params.confirmationId}
              localData={localData}
              brandingFound={branding}
            />
          </>
        ) : (
          <>
            {event.items[0].liveStarted === '0' ? (
              <>
                {event.items[0].liveEnded === '0' ? (
                  <>
                    <UpcomingEvent
                      match={props.match}
                      loading={loading}
                      event={event}
                      saveAuth={true}
                      authorization={props.match.match.params.confirmationId}
                      localData={localData}
                      timeToEvent={ssss(event)}
                      brandingFound={branding}
                    />
                  </>
                ) : (
                  <>
                    <PendingArchive
                      match={props.match}
                      loading={loading}
                      event={event}
                      saveAuth={true}
                      authorization={props.match.match.params.confirmationId}
                      localData={localData}
                      brandingFound={branding}
                    />
                  </>
                )}
              </>
            ) : (
              <>
                {event.items[0].liveEnded === '0' ? (
                  <>
                    <WebcastingPlatform
                      match={props.match}
                      loading={loading}
                      event={event}
                      saveAuth={true}
                      authorization={props.match.match.params.confirmationId}
                      localData={localData}
                      preview={false}
                    />
                  </>
                ) : (
                  <>
                    <PendingArchive
                      match={props.match}
                      loading={loading}
                      event={event}
                      saveAuth={true}
                      authorization={props.match.match.params.confirmationId}
                      localData={localData}
                      brandingFound={branding}
                    />
                  </>
                )}
              </>
            )}
          </>
        )}
      </>
      {props.event.items[0].webcast.stream && (
        <>
          <PrimusContainer
            event={props.event.items[0]}
            preview={props.preview}
          />
          <MessageToAll companyName={companyName.current} />
        </>
      )}
      {/* <PrimusContainer event={props.event.items[0]} preview={props.preview} /> */}
      {/* <MessageToAll companyName={companyName.current} /> */}
    </>
  );
};

export default React.memo(LiveLayout);
