import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';

// import { PRIMUS_CONNECT_URL } from '../../config/config';

let primus = null;
let channel = null;
// let slideId = ''; // Moved in channel.on 'slide' function
let presenterPrevious = '';
let oldStartEventID = '';
let oldStopEventID = '';
let oldMessage = '';
let local_ws = 'http://localhost:3008/'
const MemoizedPrimusConnect = ({
  event,
  preview,
  setLiveStatus, // in areEqual return false
  setLiveLayout, // in areEqual return false
  setCurrentPresenter, // in areEqual return false
  getMessageToAll, // in areEqual return false
  getCurrentThumb, // in areEqual return false
  getChannel, // in areEqual return false
  setQnaStatus //Qna Status
}) => {
  const getSlide = (slideId) => {
    let selected = 0;
    event.slides.forEach((slideEl, index) => {
      if (slideEl._id === slideId) {
        selected = index;
      }
    });
    // console.log('>>>>>>>>>>>>> getSlide:', slideId, selected);
    return selected;
  };

  // NOTES / CONSIDERATIONS
  // Take the connection outside of the main function
  // once the connection is established do some Primus actions/events
  // can we unsubscribe from event? That can prevent memory leaks
  // can we use .emit instead of .on?

  //////                ////////
  // Establish Primus Connection
  const primusConnect = () => {
    if (primus === null) {
      primus = new window.Primus.connect(process.env.REACT_APP_PRIMUS_CONNECT_URL, {
      // primus = new window.Primus.connect(local_ws, {
      
      strategy: false,
        ping: 10000,
        pong: 15000,
        timeout: 20000,
      });
    }

    primus.on('open', function open() {
      console.log(
        '%c WS open: WS connection has been established',
        'background: #bada55; color: #333'
      );
      setLiveStatus('open');
    });

    primus.on('connected', function connected(data) {
      console.log('ws connected', data);
      setLiveStatus('connected');
    });

    primus.on('error', function error(err) {
      console.log('ws error', err.message);
      setLiveStatus('error');
    });

    primus.on('end', function end() {
      console.log('ws end', 'The connection has ended.');
      setLiveStatus('end');
    });

    primus.on('close', function end() {
      console.log('ws close', "We've lost the connection to the ws server.");
      setLiveStatus('close');
    });

    primus.on('reconnect', function reconnect(opts) {
      // console.log(
      //   `ws reconnect attempt, ${opts.attempt} out of ${opts.retries} attempts`,
      //   opts
      // );
      setLiveStatus('reconnect');
    });

    primus.on('online', function online() {
      // console.log(
      //   'ws online',
      //   'We have regained control over our internet connection.'
      // );
      setLiveStatus('online');
    });

    primus.on('offline', function offline() {
      // console.log('ws offline', 'Internet connection is lost.');
      setLiveStatus('offline');
    });

    channel = primus.channel(event.webcast.stream.channel);
    getChannel(primus.channel(event.webcast.stream.channel));

    // console.log('Channel in MemoPrimusConnect: ', channel);

    channel.on('public', function (msg) {
      // console.log('ws public', msg);
      event.isPublic = msg.public === 'false' ? false : true;
      setLiveStatus('public');
    });
  };

  //////                ////////
  // Primus Channel Events
  const primusEvents = () => {
    if (channel !== null) {
      if (event.isLive || preview) {
        channel.on('slide', function (msg) {
          // console.log('slide received', msg);
          let slideId = '';

          if (event.slides.some((s) => s._id === msg.slideId)) {
            if (msg.slideId !== slideId) {
              slideId = msg.slideId;
              if (isMobile) {
                if (event.assetType === 'audio') {
                  getCurrentThumb(getSlide(msg.slideId));
                } else {
                  getCurrentThumb(getSlide(msg.slideId));
                }
              } else {
                getCurrentThumb(getSlide(msg.slideId));
              }
              setLiveStatus('slide');
            } else {
              // console.log('same slide');
              return false;
            }
          }
        });

        channel.on('presenter', function (msg) {
          // console.log('>>>>>>>>>>>>>>>>>>>>>>>>>> presenter msg', msg);
          let msgArr = msg.presenterId.split('=');
          let presenterId = msgArr[0];
          let eventId = msgArr[1];
          let presenterObj = {};

          if (eventId === event._id) {
            // console.log('ws presenter change');
            event.presenters.forEach((p, i) => {
              if (p._id === presenterId) {
                presenterObj = p;
              }
            });

            if (presenterObj !== presenterPrevious) {
              presenterPrevious = presenterObj;
              if (isMobile) {
                if (event.assetType === 'audio') {
                  setCurrentPresenter(presenterObj);
                } else {
                  setCurrentPresenter(presenterObj);
                }
              } else {
                setCurrentPresenter(presenterObj);
              }
              setLiveStatus('presenter');
            } else {
              // console.log('same presenter');
              return false;
            }
          }
          // console.log('>>>>>>>>>>>>>>>>>>>>>>>>>> presenterId', presenterId);
        });

        channel.on('start', function (msg) {
          if (event.preview || event.isPublic) {
            // console.log('ws start', msg);
            if (event.livechat || event.coveritlive) {
              // console.log('start live chat');
            }
          }
          if (msg !== oldStartEventID) {
            oldStartEventID = msg;

            if (msg.eventId === event._id) {
              setLiveStatus('start');
              setLiveLayout('start');
            }
          } else {
            // console.log('%c same start ', 'background: #ffd700; color: #333');
            return false;
          }
        });

        //listen to slideUpdated events (occur when presenter selected in manager)
        channel.on('stop', function (msg) {
          if (event.preview || event.isPublic) {
            // console.log('stopping the stream', msg);
          }
          if (msg !== oldStopEventID) {
            oldStopEventID = msg;

            if (msg.eventId === event._id) {
              setLiveStatus('stop');
              setLiveLayout('stop');
            }
          } else {
            // console.log('%c same stop ', 'background: #ffd700; color: #333');
            return false;
          }
        });


        //Toggle Qna Status
        channel.on('ToogleQnaStatus', function (msg) {
          if (event.preview || event.isPublic) {
            // console.log('stopping the stream', msg);
          }
          if (msg.id === event._id) {
              console.log('toggleeeeeee', msg)

              setQnaStatus(msg.status)
            }
          // else {
          //   // console.log('%c same stop ', 'background: #ffd700; color: #333');
          //   return false;
          // }
        });

        //Display Live Webcast Message sent by Manager using socket on live event
        let channel_6 = primus.channel(6);
        channel_6.on('WebcastLiveMessageSend', function (msg) {
          if (msg.message !== oldMessage) {
            oldMessage = msg.message;

            if (event._id === msg.id) {
              getMessageToAll(msg);
              setLiveStatus('messageArrived');
            }
          } else {
            if (event._id === msg.id) {
              // console.log(
              //   `%c same message: ${oldMessage}`,
              //   'background: #ff4500; color: #333'
              // );
            }
          }
        });
      } else {
        // event offline
      }
    }
  };

  useEffect(() => {
    primusConnect();
    primusEvents();
  });

  return <></>;
};

// returning FALSE causes another render
const areEqual = (prevProps, nextProps) => {
  // console.log('areEqual: ', prevProps.event === nextProps.event);
  return prevProps.event === nextProps.event;
};

export default React.memo(MemoizedPrimusConnect, areEqual);
