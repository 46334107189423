import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  lazy,
  Suspense,
  // useCallback,
} from 'react';
// import { ASSETS_BASE_URL } from '../../../config/config';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import Draggable from 'react-draggable';
import { isSafari, isMobile } from 'react-device-detect';
import { Button, Modal } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import KeyboardVoiceIcon from '@material-ui/icons/KeyboardVoice';
import MicOffIcon from '@material-ui/icons/MicOff';
import 'react-h5-audio-player/lib/styles.css';
import './index.css';

// HOOKS & HELPERS
import viewportDimension from '../../../hooks/useViewportDimension';
import { gaEvent } from '../../../helpers/reactGA';
import { GAMuteBtnAudioElement } from '../../../helpers/GAPlayerElements';

import WebcastingContext from '../../../context/webcasting/webcastingContext';

const PresenterItem = lazy(() => import('../../presenter/PresenterItem'));

const WpAudioPlayer = (props) => {
  const webcastingContext = useContext(WebcastingContext);
  const {
    event,
    seekToTime,
    playerCurrentTime,
    getProgressBarCurrentTime,
    getSeekingTime,
    getPlayerCurrentTime,
    liveStatus,
    liveLayout,
    currentPresenter,
    hasMetaData,
    audioPip,
    onDemandFullScreenModeTimings,
    setOnDemandFullScreenMode
  } = webcastingContext;

  // console.log('<<<< props >>>>', props);

  const nodeRef = useRef(null);
  const audioRef = useRef(null);

  const activePresenterFromAPI = event.items[0].webcast.live.active.presenter;
  let presenters;
  let presentersTime;
  let audioSrc = false;
  let liveCurrentPresenter = [];
  let assetType = '';
  let isLive = false;
  let bgColor = '';
  let checkIfChaptersInLayout = false;
  let presenterCardColors = {};
  const started = event.items[0].liveStarted;
  const [draggable, setDraggable] = useState('');
  const [canPlayThrough, setCanPlayThrough] = useState(false);
  const [canPlay, setCanPlay] = useState(false);
  const [sourceUrl, setSourceUrl] = useState('');
  const [onPause, setOnPause] = useState(false);
  const [onWaiting, setOnWaiting] = useState(false);
  const [openBtnUserInteraction, setOpenBtnUserInteraction] = useState(true);
  const [startIcon, setStartIcon] = useState();
  const [mediaError, setMediaError] = useState(false);
  const [weHaveSomeDataFromPlayer, setWeHaveSomeDataFromPlayer] =
    useState(false);
  const [activePresenter, setActivePresenter] = useState(0);

  //////// START PLAYER POSITION IN PIP
  const [width, height] = viewportDimension();

  const [bounds, setBounds] = useState({
    left: -width + 310,
    right: 0,
    top: -height + 95,
    bottom: 0,
  });

  // console.log('sourceUrlsourceUrl',audioRef  && audioRef.current?.audio?.current)

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      setBounds({ left: -width + 310, right: 0, top: -height + 95, bottom: 0 });
    }

    return () => {
      isMounted = false;
    };
  }, [width, height]);
  //////// END PLAYER POSITION IN PIP

  if (event.items[0]) {
    let liveStarted = event.items[0].liveStarted;

    presenters = event.items[0].presenters;
    presentersTime = event.items[0].webcast.presenterTimes;
    // slides = event.items[0].slides;
    assetType = event.items[0].assetType;
    isLive = event.items[0].isLive;
    checkIfChaptersInLayout = event.items[0].webcast.layout.some(
      (e) => e.name.toLowerCase() === 'chapters'
    );

    if (event.items[0].webcast.colour) {
      bgColor = event.items[0].webcast.colour.border;
    } else {
      bgColor = '';
    }

    if (event.items[0].webcast.branding) {
      if (event.items[0].webcast.branding.presenterCard) {
        presenterCardColors = event.items[0].webcast.branding.presenterCard;
      }
    }

    if (event.items[0].media) {
      if (event.items[0].media.upload) {
        if (event.items[0].media.upload.path) {
          audioSrc = `${process.env.REACT_APP_ASSETS_BASE_URL}/${event.items[0].media.upload.path}`;
        }
      }

      if (
        event.items[0].media.url &&
        (liveLayout !== undefined || liveStarted > 0)
      ) {
        audioSrc = event.items[0].media.url;
      }
    }
  }

  ///////////////////// Next useEffect is used to restart the player in preview mode
  ///////////////////// after live event is stopped from the Manager
  useEffect(() => {
    let liveStarted = event.items[0].liveStarted;

    if (isLive) {
      liveStatus === 'stop' && setSourceUrl('');
      if (!openBtnUserInteraction) {
        (liveStatus === 'start' || +liveStarted > 0) &&
          setSourceUrl(event.items[0].media.url);
      }
    }
    // eslint-disable-next-line
  }, [liveStatus, isLive, openBtnUserInteraction]);

  useEffect(() => {
    if (!mediaError) {
      setSourceUrl(event.items[0].media.url);
      setMediaError(false);
      // console.log('>>>>> media founded');
    } else {
      const timer = setInterval(() => {
        // console.log('>>>> on error');
        setSourceUrl(event.items[0].media.url);
        setMediaError(false);
      }, 8000);
      return () => {
        clearInterval(timer);
      };
    }
  }, [event, mediaError]);

  ///////////////////// Loader useEffect
  useEffect(() => {
    if (!openBtnUserInteraction) {
      if (canPlayThrough) setCanPlay(true);
      if (liveStatus === 'start' && canPlayThrough) setCanPlay(true);
    }
    if (liveStatus === 'stop') setCanPlay(false);
  }, [liveStatus, canPlayThrough, openBtnUserInteraction]);

  function manageFullScreen(currentTime, timeRanges) {
    for (const range of timeRanges) {
        if (currentTime >= range.fullScreenON && currentTime < range.fullScreenOFF) {
            console.log("Turning ON fullscreen mode");
            setOnDemandFullScreenMode(true)
  
            return;
        }
    }
    setOnDemandFullScreenMode(false)
  
    console.log("Turning OFF fullscreen mode");
  }

  useEffect(()=>{
    if(onDemandFullScreenModeTimings && onDemandFullScreenModeTimings.length > 0) {
      manageFullScreen(Math.round(playerCurrentTime), onDemandFullScreenModeTimings);
    }

  },[playerCurrentTime])



  ///////////////////// Add classes for PIP
  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if (isLive && audioPip) setDraggable('draggable live-player-layout');
      if (!isLive && audioPip) setDraggable('draggable');
    }

    return () => {
      isMounted = false;
    };
  }, [isLive, audioPip]);

  //@desc Set current presenter ondemand
  function getCurrentPresenter(time, array, pres) {
    let index = 0;
    array.forEach((el, idx) => {
      if (el.time <= time) {
        index = idx;
      }
    });

    return pres.filter((e) => e._id === array[index].presenterId);
  }

  useEffect(() => {
    let t = playerCurrentTime || seekToTime;

    if (!isLive) {
      if (presentersTime.length === 0) {
        setActivePresenter([presenters[0]]);
      } else {
        setActivePresenter(getCurrentPresenter(t, presentersTime, presenters));
      }
    }
    
    // eslint-disable-next-line
  }, [playerCurrentTime, seekToTime]);

  //@desc Set current presenter for live event
  if (
    Object.keys(currentPresenter).length === 0 &&
    activePresenterFromAPI === null
  ) {
    liveCurrentPresenter.push(presenters[0]);
  } else if (
    activePresenterFromAPI !== null &&
    Object.keys(currentPresenter).length === 0
  ) {
    const cP = presenters.filter((p) => p._id === activePresenterFromAPI);
    liveCurrentPresenter.push(cP[0]);
  } else {
    liveCurrentPresenter.push(currentPresenter);
  }

  // const action = (ac) => {
  //   console.log('Audio Player action: ', ac);
  // };

  const handleCloseModal = () => {
    setOpenBtnUserInteraction(false);

        try {
          audioRef.current.audio.current.play()
        } catch (error) {
          console.log('udioRef.current.audio.current.play()??', error)
        }
        // audioRef.current.play();
      
    
  
  };

  //@desc   Set modal icons
  useEffect(() => {
    if ((liveLayout === undefined && +started === 0) || liveLayout === 'stop') {
      setStartIcon(<MicOffIcon />);
    } else {
      setStartIcon(<KeyboardVoiceIcon />);
    }
  }, [started, liveLayout]);

  useEffect(() => {
    if (liveLayout === 'stop') setOpenBtnUserInteraction(true);
  }, [liveLayout]);

  // >>>>>>>>>>>>>>>>>>>>
  //          START ANALYTICS
  // >>>>>>>>>>>>>>>>>>>>
  const GALoadedEvent = (e) => {
    props.preview === false && gaEvent('audio', 'loaded', `${seekToTime}`);
  };

  const GAVolumeEvent = (e) => {
    if (e.target.volume === 0) {
      gaEvent('audio', 'volume changed', '0');
      gaEvent('volume_change', 'audio', '0');
    }
  };

  const GAPlayEvent = (e) => {
    props.preview === false &&
      gaEvent('audio', 'play', `${e.target.currentTime}`);
  };

  const GASeekEvent = (e) => {
    props.preview === false &&
      gaEvent('audio', 'seek', `${e.target.currentTime}`);
  };

  const GAOnPauseEvent = (e) => {
    props.preview === false &&
      gaEvent('audio', 'pause', `${e.target.currentTime}`);
  };

  const GAEndedEvent = (e) => {
    props.preview === false &&
      gaEvent('audio', 'ended', `${e.target.currentTime}`);
  };

  useEffect(() => {
    if (hasMetaData && props.preview === false) {
      const muteBtn = document.querySelector('.rhap_volume-container button');
      // GAMuteBtnAudioElement(muteBtn, 'audio', 'mute');
    }
  }, [hasMetaData, props.preview]);
  // <<<<<<<<<<<<<<<<<<<<
  //         END ANALYTICS
  // <<<<<<<<<<<<<<<<<<<<

  return (
    <>
      {isLive && !props.preview && (
        <Modal
          open={openBtnUserInteraction}
          className='modal-user-interaction__container'
          disableEscapeKeyDown
        >
          <Button
            onClick={handleCloseModal}
            disabled={liveLayout === undefined && +started === 0}
            color='secondary'
            variant='contained'
            startIcon={startIcon}
            className='modal-user-interaction__btn'
          >
            <div className='modal-user-interaction__text'>
              {(liveLayout === undefined && +started === 0) ||
              liveLayout === 'stop' ? (
                <Typography component='h3' variant='subtitle2'>
                  We will be going live shortly
                </Typography>
              ) : (
                <Typography component='h3' variant='subtitle2'>
                  We are Live. Click here to listen.
                </Typography>
              )}
              {isSafari && (
                <Typography
                  className='modal-user-interaction__text__safari'
                  component='h4'
                  variant='body2'
                >
                  {width <= 820
                    ? 'You are using Safari, you may need to click play to start'
                    : 'You are using Safari, please unmute the player'}
                </Typography>
              )}
            </div>
          </Button>
        </Modal>
      )}

      <Suspense fallback={<></>}>
        <div className='presenter'>
          <PresenterItem
            active={
              event.items[0].isLive
                ? liveCurrentPresenter[0]
                : activePresenter[0]
            }
            assetType={assetType}
            isLive={isLive}
            bgColor={bgColor}
            presenterCardColors={presenterCardColors}
            checkIfChaptersInLayout={checkIfChaptersInLayout}
          />

        </div>
      </Suspense>
      <div className={audioPip ? 'audio-player-container-pip' : 'base-height'}>
        <Draggable
          nodeRef={nodeRef}
          disabled={!audioPip}
          positionOffset={{ x: 'calc(100vw - 330px)', y: 'calc(100vh - 95px)' }}
          bounds={bounds}
        >
          <div
            ref={nodeRef}
            className={`${
              audioPip
                ? `${draggable} audio-player-container`
                : 'reset-position audio-player-container'
            } ${isLive ? 'live-player-layout' : 'ondemand-player-layout'} 
              ${width <= 820 && isLive ? 'is-mobile' : ''} 
              ${!canPlay && isLive && !props.preview ? 'loading' : ''}
            `}
          >
            {props.preview && !weHaveSomeDataFromPlayer && isLive && (
              <div className='waiting-overlay-in-preview'>
                <h3>Waiting...</h3>
              </div>
            )}
            {props.preview && isLive && onWaiting && (
              <div className='sync-overlay'>
                <h3>Sync...</h3>
              </div>
            )}
            {audioSrc ||
            (sourceUrl && canPlay) ||
            (sourceUrl && props.preview && !mediaError) ? (
              <>
                <AudioPlayer
                ref={audioRef}
                  width='100%'
                  muted={isLive && isSafari && !isMobile ? true : false}
                  autoPlay={isLive || (isLive && props.preview) ? true : false}
                  // autoPlay={isLive && !isSafari ? true : false}
                  volume={0.7}
                  // media fragment to the media URL to seek a specific timestamp
                  src={props.isLive ? sourceUrl : `${audioSrc}#t=${seekToTime}`}
                  autoPlayAfterSrcChange
                  showJumpControls={false}
                  customAdditionalControls={[]}
                  customVolumeControls={[]}
                  customProgressBarSection={[]}
                  progressUpdateInterval={20}
                  listenInterval={2000}
                  customControlsSection={[
                    RHAP_UI.CURRENT_TIME,
                    <div className='rhap_slash'>/</div>,
                    RHAP_UI.DURATION,
                    RHAP_UI.MAIN_CONTROLS,
                    RHAP_UI.PROGRESS_BAR,
                    RHAP_UI.VOLUME,
                  ]}
                  // onCanPlay={action('onCanPlay')}
                  // onCanPlayThrough={action('onCanPlayThrough')}
                  // onSeeked={action('onSeeked')}
                  // onPlayError={}
                  // onAbort={action('onAbort')}
                  onPlaying={(e) => {
                    setOnWaiting(false);
                  }}
                  onWaiting={(e) => {
                    setOnWaiting(true);
                  }}
                  onError={(e) => {
                    setMediaError(true);
                    setWeHaveSomeDataFromPlayer(false);
                  }}
                  onLoadedMetaData={(e) => {
                    hasMetaData();
                    GALoadedEvent(e);
                    setMediaError(false);
                    setWeHaveSomeDataFromPlayer(true);
                  }}
                  onCanPlayThrough={(e) => setCanPlayThrough(true)}
                  onEnded={(e) => {
                    GAEndedEvent(e);
                    setWeHaveSomeDataFromPlayer(false);
                    setMediaError(true);
                    setSourceUrl('');
                    setOnWaiting(false);
                  }}
                  onVolumeChange={(e) => GAVolumeEvent(e)}
                  onListen={(e) =>  getPlayerCurrentTime(e.target.currentTime)}
                  onSeeking={(e) => GASeekEvent(e)}
                  onPlay={(e) => {
                    GAPlayEvent(e);
                    e.target.onseeking = () => {
                      getProgressBarCurrentTime(e.target.currentTime);
                      getSeekingTime(e.target.currentTime);
                    };

                    if (isLive && onPause) {
                      // console.log('onPlay', e.target.networkState);
                      const nocache = Math.floor(Math.random() * 1000000 + 1);
                      setSourceUrl(
                        `${event.items[0].media.url}?nocache=${nocache}`
                      );
                      setOnPause(false);
                    }
                    // e.target.ontimeupdate = (e) => {
                    //   myTimer(e);
                    // };
                  }}
                  onPause={(e) => {
                    GAOnPauseEvent(e);
                    e.target.onseeking = () => {
                      getProgressBarCurrentTime(e.target.currentTime);
                      getSeekingTime(e.target.currentTime);
                    };

                    if (isLive && props.preview) {
                      setOnPause(true);
                    }
                  }}
                />
              </>
            ) : (
              <>
                {liveLayout === undefined && !props.preview && (
                  <div className='audio-text waiting-text'>
                    <h3>Waiting for stream to start</h3>
                  </div>
                )}
              </>
            )}
          </div>
        </Draggable>
      </div>
    </>
  );
};

export default React.memo(WpAudioPlayer);
