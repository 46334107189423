import React, { useEffect, useRef, useState, useContext } from 'react';
import videojs from 'video.js';
import '../../../static/video-js.css';

import WebcastingContext from '../../../context/webcasting/webcastingContext';

// HELPERS
import { gaEvent } from '../../../helpers/reactGA';

// eslint-disable-next-line import/prefer-default-export
// const usePlayer = ({ src }) => {
//   const options = {
//     // fill: true,
//     // fluid: true,
//     autoplay: true,
//     muted: true,
//     controls: true,
//     preload: 'auto',
//   };
//   const videoRef = useRef(null);
//   const [player, setPlayer] = useState(null);

//   useEffect(() => {
//     const vjsPlayer = videojs(videoRef.current, {
//       ...options,
//       sources: [
//         {
//           src: src,
//           type: 'application/x-mpegURL',
//         },
//       ],
//     });
//     setPlayer(vjsPlayer);

//     return () => {
//       console.log('IS PALYER NULL?', player);

//       if (player !== null) {
//         console.log('PLAYER IS UNMOUNTED');
//         player.dispose();
//       }
//     };

//     // eslint-disable-next-line
//   }, []);

//   useEffect(() => {
//     // console.log('IS PALYER NULL?', player === null);
//     if (player !== null) {
//       player.src({ src });
//     }
//     // eslint-disable-next-line
//   }, [src, player]);

//   return videoRef;
// };

const options = {
  autoplay: true,
  muted: true,
  controls: true,
  preload: 'auto',
};

const VideoJsSimpleFunc = ({ src,qnaMode }) => {
  const webcastingContext = useContext(WebcastingContext);
  const { hasMetaData, QnaStatus } = webcastingContext;
  // const playerRef = usePlayer({ src });

  const videoRef = useRef(null);
  const [player, setPlayer] = useState(null);

  useEffect(() => {
    const vjsPlayer = videojs(videoRef.current, options);
    setPlayer(vjsPlayer);
    // videoRef.current.muted = false
    console.log('Is video muted', videoRef.current.volume)
    return () => vjsPlayer.dispose();
  }, []);

  useEffect(() => {
    if (player !== null) {
      player.src({ src });
      var playButton = player.controlBar.playToggle.el();
      playButton.style.display = 'none';
      player.muted(false)
    }
    if(QnaStatus) {
      console.log('props props', QnaStatus)
      player && player.muted(false)
    }

  }, [src, player]);

  const GAVideoOnPlay = () => {
    gaEvent('video', 'play', `${videoRef.current.currentTime}`);

    player.on('fullscreenchange', () => {
      const ifFS = player.isFullscreen();
      ifFS && gaEvent('video', 'full screen', `${player.currentTime()}`);
    });
    player.on('enterpictureinpicture', function () {
      gaEvent('video', 'openpip', `${player.currentTime()}`);
    });
    player.on('leavepictureinpicture', function () {
      gaEvent('video', 'closepip', `${player.currentTime()}`);
    });
  };

  const GAVideoOnPause = () => {
    gaEvent('video', 'pause', `${videoRef.current.currentTime}`);
  };

  const GAVideoVolumeChange = () => {
    if (videoRef.current.volume === 0) {
      gaEvent('video', 'volume change', '0');
      gaEvent('volume change', 'video', '0');
    }

    videoRef.current.muted
      ? gaEvent('video', 'mute', `${videoRef.current.currentTime}`)
      : gaEvent('video', 'unmute', `${videoRef.current.currentTime}`);
  };

  return (
    <div data-vjs-player>
      <video
        ref={videoRef}
        className='video-js'
        onLoadedMetadata={hasMetaData}
        onPlay={GAVideoOnPlay}
        onPause={GAVideoOnPause}
        onVolumeChange={GAVideoVolumeChange}
        playsInline
      />
    </div>
  );
};

export default VideoJsSimpleFunc;
