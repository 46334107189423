import React, { useReducer } from 'react';
import axios from 'axios';
//import { API_BASE_URL, API_TOKEN } from '../../config/config';
import RegisteredUserContext from './registeredUserContext';
import RegisteredUserReducer from './registeredUserReducer';
import { SET_LOADING, GET_USER_REGISTRATION, EVENT_ERROR } from '../types';

axios.defaults.timeout = 5000;

let deb1 = 1;

const RegisteredUserState = (props) => {
  const initialState = {
    userRegistration: {},
  };

  const [state, dispatch] = useReducer(RegisteredUserReducer, initialState);

  // CHECK IF USER IS REGISTERED
  const getUserRegistation = async (eventId, email) => {
    if (deb1 === 1) {
      setLoading();
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/registrant/searchAllRegistrants/0/1?eventId=${eventId}&email=${email}&token=${process.env.REACT_APP_API_TOKEN}`
        );
        // console.log(res)
        dispatch({
          type: GET_USER_REGISTRATION,
          payload: res.data,
        });
      } catch (error) {
        dispatch({
          type: EVENT_ERROR,
          payload: error.response.data.message,
        });
      }
      deb1++;
    }
  };

  // SET LOADING
  const setLoading = () => dispatch({ type: SET_LOADING });

  return (
    <RegisteredUserContext.Provider
      value={{
        userRegistration: state.userRegistration,
        getUserRegistation,
        setLoading,
      }}
    >
      {props.children}
    </RegisteredUserContext.Provider>
  );
};

export default RegisteredUserState;
