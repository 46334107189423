import { Button, Modal, makeStyles } from '@material-ui/core';
import React from 'react';
// import { Modal, Button, makeStyles } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: 'white',
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: 'center',
  },
}));

function LeaveUserModal({ open, onClose, onReload }) {
  const classes = useStyles();

  return (
    <Modal open={open} className={classes.modal} onClose={onClose}  disableBackdropClick={true}>
      <div className={classes.paper}>
        <p>Are you still there on the page?</p>
        <Button variant="contained" color="primary" onClick={onReload}>
         Yes
        </Button>
      </div>
    </Modal>
  );
}

export default LeaveUserModal;
