import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';

// Components
import VideoPlayer from '../../components/players/video';
import WpAudioPlayer from '../../components/players/audio';
import NoPlayer from '../../components/players/NoPlayer';
import Chapters from '../../components/chapter/Chapters';

import './index.css';

const useStyles = makeStyles((theme) => ({
  chaptersWith: {
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  withoutPresenter:{
    gridArea: 'chapters',
    display: 'flex',
    justifyContent:'end'
  }
}));

const PlayerChapters = ({ event, withThumbs, preview, isOnlyVideo, ispresenter }) => {
  // NOTES
  // Last DIV is the video player background
  // currently it is not used as a stylistic element as it has not been appreciated or understood
  // for the moment he is put on the bench

  const [elementHeight, setElementHeight] = useState();

  const getHeight = (el) => {
    setElementHeight(el);
  };

  const classes = useStyles();

  return (
    <>
      <CssBaseline />
      <Container
        // maxWidth='xl'
        // className={
        //   !withThumbs
        //     ? 'activeRegionsContainerPlayerChapters'
        //     : 'activeRegionsContainerWithThumbs'
        // }
        className='activeRegionsContainerPlayerChapters'
        // style={{ backgroundColor: bgColor !== '' ? bgColor : null }}
      >
        <Grid
          container
          justify='center'
          id='playerChaptersBox'
          className='playerChaptersContainer'
        >
          <Grid
            item
            xs={12}
            // sm={12}
            // md={12}
            // lg={10}
            // xl={10}
            id='playerBox'
            className='playerChaptersBox'
          >
            <Box
              mb={0}
              // style={{ backgroundColor: bgColor !== '' ? bgColor : null }}
            >
              {event.assetType === 'video' ? (
                <VideoPlayer
                  media={event.media}
                  isLive={event.isLive}
                  presenters={event.presenters}
                  videoThumb={event.videoThumb}
                  title={event.title}
                  preview={preview}
                  isOnlyVideo={isOnlyVideo}
                  getHeight={getHeight}
                  ispresenter={ispresenter}
                />
              ) : event.assetType === 'audio' ? (
                <WpAudioPlayer
                  media={event.media}
                  isLive={event.isLive}
                  preview={preview}
                />
              ) : (
                <NoPlayer />
              )}
            </Box>
          </Grid>

          {!event.isLive && (
            <Grid
              item
              xs={10}
              // sm={12}
              // md={12}
              // lg={10}
              // xl={10}
              id='ChaptersBox'
              className={ispresenter  === 'off' ? classes.withoutPresenter :  'chaptersContainerInPlayerChaptersLayout' }
             
            >
              <Box className={classes.chaptersWith}  style={{marginTop:'20px'}}>
                <Chapters preview={preview} />
              </Box>
            </Grid>
          )}
        </Grid>
        {/* <div
          className={
            elementHeight !== undefined ? 'videoBackground' : undefined
          }
          style={{
            height: elementHeight !== undefined && elementHeight,
          }}
        ></div> */}
      </Container>
    </>
  );
};

export default PlayerChapters;
