import React, { useState, lazy, Suspense } from 'react';
import RegistrationUserState from '../context/registeredUser/registeredUserState.js';
import { Dialog, CssBaseline, Container, Grid, Box } from '@material-ui/core';

// Components
import HeaderBar from '../components/header/HeaderBar';
import BackgroundImg from '../components/UI/BackgroundImg';
import RegistrationEmail from './RegistrationEmail';

import './index.css';
import './RegistrationEmail.css';
// import { ASSETS_BASE_URL } from '../config/config.js';
const Logo = lazy(() => import("../components/UI/Logo"));

function isLogoTypeWebsite(logo) {
  return logo.type === 'website';
}

const RegistrationContainer = ({ event, match, loading, saveAuth }) => {
  // console.log('PROPS IN CONTAINER', props.match);

  const [open, setOpenDialog] = useState(true);

  //Set header 
  const [isHeader, setIsHeader] = useState(false)

  let brandingFound = false;
  let logoPadding = '';
  let logoPath = '';
  let headerTitle = '';

  let headerBackground = '#ffffff';
  if (event.items[0].webcast.colour.background !== '') {
    headerBackground = event.items[0].webcast.colour.background;
  }

  if (loading || event.length === 0) {
  } else {
    if (
      event.items[0].companies[0].logos.find(isLogoTypeWebsite) === undefined
    ) {
      logoPath = null;
    } else {
      logoPath =
        event.items[0].companies[0].logos.find(isLogoTypeWebsite).upload.path;
    }
    // event.items[0].companies[0].logos.forEach((item) => {
    //   if (item.type === 'website') {
    //     logoPath = item.upload.path;
    //   }
    // });

    event.items[0].webcast.branding.fonts.forEach((font) => {
      if (font.fontLinkHref !== '') brandingFound = true;
    });

    if (event.items[0].webcast.branding.logo) {
      logoPadding =
        event.items[0].webcast.branding.logo.padding !== ''
          ? event.items[0].webcast.branding.logo.padding
          : '10px';
    } else {
      logoPadding = '10px';
    }

    headerTitle = event.items[0].headerTitle
      ? event.items[0].headerTitle
      : event.items[0].title;
  }

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpenDialog(false);
    }
  };

  console.log('LOGO PATH', logoPath)

  return (
    <>
      {event.items[0].holdingSlide !== null ? (
        <BackgroundImg
          path={event.items[0].holdingSlide.path}
          img={true}
          colour={null}
        />
      ) : event.items[0].webcast.colour.background !== null ? (
        <BackgroundImg
          path={null}
          img={false}
          colour={event.items[0].webcast.colour.background}
        />
      ) : (
        <></>
      )}
      <CssBaseline />

      <Container maxWidth='xl' className='mainContainer'>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          id='firstRow'
          className='whiteBg'
        >
         {
            isHeader &&
          <HeaderBar
          eventId={match.match.params.eventId}
          eventHeaderTitle={headerTitle}
          logoPath={logoPath}
          logoPadding={logoPadding}
          headerBackground={headerBackground}
          brandingFound={brandingFound}
          />
        }
        </Grid>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby='form-dialog-title'
          disableEscapeKeyDown
        >

            {
              event.items[0]._id !== '6617f9d68d18254c64eac445' &&
              <Box style={{display:'flex', justifyContent:'center'}}>

                 <Suspense fallback={<></>}>
                 {logoPath && (
                   <Logo
                   src={`${process.env.REACT_APP_ASSETS_BASE_URL}/${logoPath}`} 
                   title={'headerTitle'}
                   logoPadding={logoPadding}
                   backgroundColor="#fff"
                   // backgroundColor={logoBackground}
                   />
                   )}
                    </Suspense>
           </Box>

}


          <RegistrationUserState>
            <RegistrationEmail
              match={match}
              event={event}
              saveAuth={false}
              loading={loading}
            />
          </RegistrationUserState>
        </Dialog>
      </Container>
    </>
  );
};

const areEqual = (prevProps, nextProps) => {
  return prevProps.event === nextProps.event;
};

export default React.memo(RegistrationContainer, areEqual);
