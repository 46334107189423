import React, { useState, useEffect, useContext } from 'react';
import CookieConsent from 'react-cookie-consent';
import './index.css';
import { useHistory,useParams  } from "react-router-dom"
import publicIp from 'react-public-ip';

import WebcastingContext from '../context/webcasting/webcastingContext';
// import {
//   REACT_APP_GOOGLE_ANALYTICS_ID,
//   STATS_API_TOKEN,
//   STATS_API,
// } from "../config/config";
// COMPONENTS
import SpinnerAbsolute from '../components/UI/SpinnerAbsolute';
import NotFound from '../components/UI/NotFound';
import PendingArchiveContainer from './PendingArchiveContainer';
import LiveLayout from './Live';
import ZoomRegistrationContainer from './ZoomRegistrationContainer';
import CheckAgmAuth from './CheckAgmAuth';
import WebcastingPlatform from './WebcastingPlatform';
import axios from 'axios';

// const Console = (props) => {
//   // console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>> passed data to log: ', props);
//   return null;
// };

const ZoomLayout = (props) => {
  let history = useHistory()

  const webcastingContext = useContext(WebcastingContext);
  const {
    loading,
    event,
    authorization,
    authListResponse,
    getAuthList,
    authList,
    localData,
    getEvent,
    getAuthorization,
    readLocalData,
    checkLocalDataAndRedirect,
    branding,
    // authListCompleted,
    getUserIP,
    userIp,
    getUserIpPermission,
    userIpPermission,
    updateLoginTime,
    validateUserID,
    getGeoData,
    addDeviceName,
    userVerified,
    setNoRegID,
    noRegID,
    noRegEventPassword,
    setGA
  } = webcastingContext;

  // const [branding, setBranding] = useState(false);
  const [eventFounded, setEventFounded] = useState(false);
  const [ipRestricted, setIpRestricted] = useState(false);
  const [noRegPassword_, setNoRegPassword] = useState(false)

  let { confirmationId } = useParams();

  /////////////////////// No reg 
  const [noRegStatus, setNoRegStatus] = useState(false);

  // Checking if there is sessionID/EventID present for the user in localstorage
  const checkLocalDataAndRedirectForNoReg = () => {
    let checkLocalNoReg = localStorage.getItem("noReglocalData");
    let parsedResultnoReglocalData = JSON.parse(checkLocalNoReg);
    // console.log('no reg event id', props.match.match.params.eventId)
    let result = parsedResultnoReglocalData?.user?.events.filter((eachData) => {
      return eachData.eventID === props.match.match.params.eventId;
    });

    return result;
  };

  // If sessionID/EventID is present then generate sessionUID and store with specific event
  const saveLocalDataAndRedirectForNoReg = async () => {

    try {
      let res = await axios.get(
        `${process.env.REACT_APP_STATS_API_URL}/event/generateUniqueSessionUID/?token=${process.env.REACT_APP_STATS_API_TOKEN}`
      );
      let token = res.data;
  
      //Initial object
      let noReglocalData = {
        user: {
          events: [],
        },
      };
  
      let sessionUID = token;
  
      // console.log("token", sessionUID);
  
      //If 'noReqlocalData' already present, then no need to create one
      // Skip if present
      if (!localStorage.getItem("noReglocalData")) {
        // console.log('no req local is present')
        localStorage.setItem("noReglocalData", JSON.stringify(noReglocalData));
      }
  
      let ResultnoReglocalData = localStorage.getItem("noReglocalData");
  
      let parsedResultnoReglocalData = JSON.parse(ResultnoReglocalData);
      localStorage.setItem(
        "noReglocalData",
        JSON.stringify(parsedResultnoReglocalData)
      );
  
      //  console.log('Local', parsedResultnoReglocalData)
  
      parsedResultnoReglocalData.user.events.push({
        eventID: props.match.match.params.eventId,
        sessionUID: sessionUID,
      });
  
      localStorage.setItem(
        "noReglocalData",
        JSON.stringify(parsedResultnoReglocalData)
      );
  
      //Component level state (SessionID) for no req event
      setNoRegID(sessionUID);
      setNoRegStatus(true)
    } catch (error) {
      setNoRegStatus(true)
    }

 


  };

  let getTokenAndSaveNoReglocal = async () => {
    let checkLocalNoReg = localStorage.getItem("noReglocalData");
    let _checkLocalDataAndRedirectForNoReg =
      checkLocalDataAndRedirectForNoReg();

    // console.log('No reg present', _checkLocalDataAndRedirectForNoReg)

    if (
      _checkLocalDataAndRedirectForNoReg?.length > 0 &&
      _checkLocalDataAndRedirectForNoReg !== undefined
    ) {
      // let eventid = _checkLocalDataAndRedirectForNoReg[0].eventID
      let sessionUID = _checkLocalDataAndRedirectForNoReg[0].sessionUID;

      // console.log('id,sessionUID', eventid, sessionUID)
      setNoRegID(sessionUID);
      setNoRegStatus(true)
    } else {
      //If sessionID/EventID is not present
      saveLocalDataAndRedirectForNoReg();
    }
  };



useEffect(()=>{
  // Set in react conext 
    console.log('GOT UID from context', noRegID)


  },[noRegID])



useEffect(()=>{
  setNoRegPassword(noRegEventPassword)
}, [noRegEventPassword])







  /////////////////////////////////// No reg


  useEffect(() => {
    console.log('props', props.match.location.search.includes('isverified=true'))
    getEvent(props.match.match.params.eventId);
    if(props.match.location.search.includes('ispresenter=off') || props.match.location.search.includes('ispresenter=on') || 
    props.match.location.search.includes('template=clean')  ||
     props.match.location.search.includes('controlbar=no') ||      props.match.location.search.includes('controlbar=yes') ||

     props.match.location.search.includes('reg=off')  
     ) {
        return
} else if(props.location.pathname.length === 35) {
      checkLocalDataAndRedirect(props.match.match.params.eventId)
    } 

    // if(!event.items[0].registrationRequired) {

      if(confirmationId && !props.match.location.search.includes('isverified=true')) {
        validateUserID(props.match.match.params.eventId, confirmationId)
      }
    // }


    // eslint-disable-next-line
  }, [props.match.match.params.eventId, userVerified]);


  useEffect(()=>{

    if(eventFounded){
   let hostName = window.location.hostname;
  //  console.log('buchanan or not', hostName.includes("stream.buchanan.uk.com"))
   if(hostName.includes("buchanan")  && (event.items[0].event_creator === 'brr' && event.items[0].event_creator === 'emperor')) {
     // Host is from Buchanan and event is from brr => so redirect
     history.push("/NotAuthorized")
   }


   if(hostName.includes("emperor")  && (event.items[0].event_creator === 'brr' && event.items[0].event_creator === 'buchanan')) {
    // Host is from emp and event is from brr => so redirect
    history.push("/NotAuthorized")
  }



  if (!props.match.match.path.includes("preview")) 
  {
    getTokenAndSaveNoReglocal();
    // Start logic for NoReg Event
    console.log('Registration????',event.items[0])
  }



    }
    // eslint-disable-next-line
  },[event, eventFounded])

 

  // >>>>>>>>>>>>>>> START CHECK AND SET GEO RESTRICTION
  useEffect(() => {
    if (event.success && event.items.length > 0) {
      if (event.items[0].geoRestricted !== undefined) {
        if (event.items[0].geoRestricted) getUserIP();
      } else {
        setEventFounded(true);
      }
    }
    // eslint-disable-next-line
  }, [event]);


  const getIp = async ()=>{
    getGeoData(await publicIp.v4(), props.match.match.params.eventId) 
    addDeviceName(await publicIp.v4(),props.match.match.params.eventId,  window.navigator.platform)
    //Also post user device information
        //send user's device information 
  }

  useEffect(() => {
    if (userIp !== '' && userIp !== undefined) {
      getUserIpPermission(userIp, props.match.match.params.eventId);
    }
  //  getIp()

    // eslint-disable-next-line
  }, [userIp]);

  useEffect(() => {
    if (Object.keys(userIpPermission).length > 0) {
      userIpPermission.result === 1
        ? setIpRestricted(true)
        : setEventFounded(true);
    }
  }, [userIpPermission]);
  // >>>>>>>>>>>>>>> FINISH CHECK AND SET GEO RESTRICTION

  // >>>>>>>>>>>>>>> GET AGM LIST IF AGM IS ENABLED
  useEffect(() => {
    if (event.items !== undefined) {
      if (event.items[0].agmCheck) {
        getAuthList(props.match.match.params.eventId);
      }
    }
    // eslint-disable-next-line
  }, [event, props.match.match.params.eventId]);

  useEffect(() => {
    readLocalData(event, authorization);

    // eslint-disable-next-line
  }, [event, authorization]);

  useEffect(() => {
    if (props.match.match.params.confirmationId) {
      getAuthorization(
        props.match.match.params.eventId,
        props.match.match.params.confirmationId
      );
    }
    // eslint-disable-next-line
  }, [
    props.match.match.params.confirmationId,
    props.match.match.params.eventId,
  ]);






  // @ Check for the font-> set branding to true, initially - false 
  // useEffect(() => {
  //   if (event.total === 0) return;
  //   if (event.items) {
  //     if (event.items[0].webcast.branding) {
  //       console.log(event.items[0].webcast.branding)
  //       if (event.items[0].webcast.branding.fonts) {
  //       setBranding();
  //       }
  //     }
  //   }

  //   // eslint-disable-next-line
  // }, [event]);

  const checkAuthEvent = (e) => {
    if (e.eventId === props.match.match.params.eventId && e.auth) {
      return true;
    } else return false;
  };

  const checkAuthList = (e) => {
    if (e.eventId === props.match.match.params.eventId) {
      return true;
    } else return false;
  };
  // const checkAuthList = (e) => {
  //   if (e.eventId === props.match.match.params.eventId && e.agmAuth) {
  //     return true;
  //   } else return false;
  // };

  return (
        noRegStatus && (
          <>
          
          {
      
      loading || event.length === 0 ? (
        <SpinnerAbsolute />
      ) : event.success === false ? (
        <NotFound msg={event.message.message} />
      ) : event.items.length === 0 ? (
        <NotFound msg='event not found' />
      ) : event.items[0].status === 'pending_approval' ? (
        <NotFound msg='event not found' />
      ) : event.items[0].status === 'closed_group2' ? (
        <NotFound msg='event not found' />
      ) : event.items[0].status === 'pending_archive' ? (
        
        <PendingArchiveContainer
          match={props.match}
          loading={loading}
          event={event}
          saveAuth={true}
          authorization={props.match.match.params.confirmationId}
          localData={localData}
          branding={branding}
        />
      // ) : (props.match.location.search.includes('reg=off') ) ? (
        ) : (props.match.location.search.includes('reg=off')  || (noRegPassword_ === true &&  !event.items[0].registrationRequired) ) ? (

        <>
           {event.items[0].isLive ? (
                      <LiveLayout
                        match={props.match}
                        loading={loading}
                        event={event}
                        saveAuth={false}
                        authorization={props.match.match.params.confirmationId}
                        localData={localData}
                        preview={false}
                      />
                    ) : (
                      <WebcastingPlatform
                      match={props.match}
                      loading={loading}
                      event={event}
                      saveAuth={true}
                      authorization={props.match.match.params.confirmationId}
                      localData={localData}
                      preview={props.preview}
                   
                      />
                    )}
        </>
      ) : ipRestricted ? (
        <NotFound msg={userIpPermission.message} statusCode='403' />
      ) : (
        eventFounded &&
        !ipRestricted &&
        (authList.success === true ? (
          
          authListResponse.success !== true &&
          authorization.success !== true ? (
            <CheckAgmAuth
              match={props.match}
              loading={loading}
              event={event}
              authList={authList}
              saveAuth={false}
            />
          ) : authListResponse.success &&
            (event.items[0].registrationRequired || event.items[0].passwordRestriction) &&
            authorization.success !== true ? (
            <>
              {localData ? (
                <>
                  {localData.user.events.some((event) =>
                    checkAuthList(event)
                  ) ? (
                    <>
                      {event.items[0].isLive ? (
                        <LiveLayout
                          match={props.match}
                          loading={loading}
                          event={event}
                          saveAuth={false}
                          authorization={
                            props.match.match.params.confirmationId
                          }
                          localData={localData}
                          preview={false}
                        />
                      ) : (
                        <WebcastingPlatform
                          match={props.match}
                          loading={loading}
                          event={event}
                          saveAuth={true}
                          authorization={
                            props.match.match.params.confirmationId
                          }
                          localData={localData}
                          preview={false}
                          branding={branding}
                        />
                      )}
                    </>
                  ) : (
                    <>
                      <ZoomRegistrationContainer
                        match={props.match}
                        loading={loading}
                        event={event}
                        saveAuth={false}
                      />
                    </>
                  )}
                </>
              ) : (
                <SpinnerAbsolute />
              )}
            </>
          ) : (
            authorization.success && (
              <>
                {event.items[0].isLive ? (
                  <LiveLayout
                    match={props.match}
                    loading={loading}
                    event={event}
                    saveAuth={false}
                    authorization={props.match.match.params.confirmationId}
                    localData={localData}
                    preview={false}
                  />
                ) : (
                  <WebcastingPlatform
                    match={props.match}
                    loading={loading}
                    event={event}
                    saveAuth={true}
                    authorization={props.match.match.params.confirmationId}
                    localData={localData}
                    preview={false}
                    branding={branding}
                  />
                )}
              </>
            )
          )
        ) : (event.items[0].registrationRequired || event.items[0].passwordRestriction)   ? (
          <>
            {localData ? (
              <>
                {localData.user.events.some((event) =>
                  checkAuthEvent(event)
                ) ? (
                  <>
                    {event.items[0].isLive ? (
                      <LiveLayout
                        match={props.match}
                        loading={loading}
                        event={event}
                        saveAuth={false}
                        authorization={props.match.match.params.confirmationId}
                        localData={localData}
                        preview={false}
                      />
                    ) : (
                      <WebcastingPlatform
                        match={props.match}
                        loading={loading}
                        event={event}
                        saveAuth={true}
                        authorization={props.match.match.params.confirmationId}
                        localData={localData}
                        preview={false}
                        branding={branding}
                      />
                    )}
                  </>
                ) : authorization.success ? (
                  <>
                    {event.items[0].isLive ? (
                      <LiveLayout
                        match={props.match}
                        loading={loading}
                        event={event}
                        saveAuth={false}
                        authorization={props.match.match.params.confirmationId}
                        localData={localData}
                        preview={false}
                      />
                    ) : (
                      <WebcastingPlatform
                        match={props.match}
                        loading={loading}
                        event={event}
                        saveAuth={true}
                        authorization={props.match.match.params.confirmationId}
                        localData={localData}
                        preview={false}
                        branding={branding}
                      />
                    )}
                  </>
                ) : (
                  <>
                    <ZoomRegistrationContainer
                      match={props.match}
                      loading={loading}
                      event={event}
                      saveAuth={false}
                    />
                  </>
                )}
              </>
            ) : (
              <SpinnerAbsolute />
            )}
          </>
        ) : event.items[0].isLive ? (
          <LiveLayout
            match={props.match}
            loading={loading}
            event={event}
            saveAuth={false}
            authorization={props.match.match.params.confirmationId}
            localData={localData}
            preview={false}
          />
        ) : (
          <WebcastingPlatform
            match={props.match}
            loading={loading}
            event={event}
            saveAuth={true}
            authorization={props.match.match.params.confirmationId}
            localData={localData}
            preview={false}
            branding={branding}
          />
        ))
      )}

      {!props.match.location.search.includes('template=clean') && ( !event.items[0].registrationRequired) && (
        <CookieConsent
        enableDeclineButton
        buttonText="Accept"
        declineButtonStyle={{background:'white', color:'black'}}
        buttonStyle={{
          background:'var(--clr-brr)',
          color:'white'
        }}
        declineButtonText="Decline"
        onAccept={()=>{
          setGA(true)
        }}
        onDecline={() => {
          alert("nay!");
        }}
          cookieName='mySiteCookieConsent'
          location='top'
          style={{ background: '#e3e3e3', color: '#2B373B', zIndex: 99000 }}
        >
          We use cookies to provide and improve our services. By using our site
          you consent to{' '}
          <a href='../../../../../cookies' target='_blank'>
            cookies
          </a>
        </CookieConsent>
      )}


      {
      event.items[0].registrationRequired && event.items[0]._id !== '6617f9d68d18254c64eac445'  &&    <CookieConsent

            cookieName='mySiteCookieConsent'
            location='top'
            style={{ background: '#e3e3e3', color: '#2B373B', zIndex: 99000 }}
          >
            We use cookies to provide and improve our services. By using our site
            you consent to{' '}
            <a href='../../../../../cookies' target='_blank'>
              cookies
            </a>
          </CookieConsent>
      }


          
          </>
        )
  );
};

export default React.memo(ZoomLayout);
