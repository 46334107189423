import { useEffect } from 'react';

function useConditionalTimeout(callback, delay, condition) {
  useEffect(() => {
    if (condition) {
      const timeoutId = setTimeout(callback, delay);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [callback, delay, condition]);
}

export default useConditionalTimeout;
