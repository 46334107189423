import React from 'react';
// import { isMobile } from 'react-device-detect';
// import { ANTMEDIA_STREAM_URL } from '../../../config/config';

/*

antMediaIngest: "antmediaingest.brrmedia.co.uk"
antMediaKey: "testStreaming111"
antMediaStreamer: "LiveApp"
antMediaHost: "https://antmedia.brrmedia.co.uk"

*/

const AntPlayer = (props) => {
  return (
    <div className={props.isOnlyVideo ? 'videoWrapper shadow' : 'videoWrapper'}>
      <iframe
        title={props.title}
        width={560}
        height={315}
        marginWidth={0}
        marginHeight={0}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          // minWidth: '560px',
          // minHeight: '315px',
          width: '100%',
          height: '100%',
        }}
        src={`${props.media.antMediaHost}/${props.media.antMediaStreamer}/play.html?name=${props.media.antMediaKey}`}
        frameBorder={0}
        allowFullScreen={true}
        playsInline={true}
      ></iframe>
    </div>
  );
};
export default React.memo(AntPlayer);
