import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import WebcastingContext from "../../context/webcasting/webcastingContext";

import './PresenterItemSmall.css';

const useStyles = makeStyles({
  card: {
    height: '50%',
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: '0px',
    boxShadow: 'none',
  },
  content: {
    padding: '0.7rem 2.1rem',
    borderRadius: '0px 0px 3px 3px',
    '&:last-child': {
      paddingBottom: '1rem',
    },
  },
  contentDefault: {
    height: '100%',
    padding: '1rem 2rem',
    // padding: '0.7rem 2.1rem',
    borderRadius: '0px',
    backgroundColor: '#fff',
    '&:last-child': {
      paddingBottom: '1rem',
    },
  },
  subtitle: {
    lineHeight: '1',
  },
});

const PresenterItemSmall = ({ active, isOnlyVideo }) => {
  const webcastingContext = useContext(WebcastingContext);
  const {event} = webcastingContext
  const [isFont_title, setIfFont_title] = useState({})
  const [isFont_description, setIfFont_description] = useState({})


  useEffect(()=>{
    if( event.items[0].webcast.branding.fonts.length >0) {
      if(Object.entries( event.items[0].webcast.branding.fonts[1]).length >0) {
        //Presenter Style Found 
        console.log('Presenter fonts', Object.entries( event.items[0].webcast.branding.fonts[1]).length)
        if(Object.entries( event.items[0].webcast.branding.fonts[1]).length > 0) {
          console.log('Presenter fonts',event.items[0].webcast.branding.fonts[1] )
          setIfFont_title(event.items[0].webcast.branding.fonts[1])
        }
      }


      if(Object.entries( event.items[0].webcast.branding.fonts[2]).length >0) {
        //Presenter Style Found 
        console.log('Presenter fonts', Object.entries( event.items[0].webcast.branding.fonts[2]).length)
        if(Object.entries( event.items[0].webcast.branding.fonts[2]).length > 0) {
          console.log('Presenter fonts',event.items[0].webcast.branding.fonts[2] )
          setIfFont_description(event.items[0].webcast.branding.fonts[2])
        }
      }


    }
},[event])




  // let presenterImage = '';
  let presenterName = '';
  let presenterPosition = '';
  if (active) {
    // if (active.images[0]) {
    //   if (active.images[0].upload) {
    //     if (active.images[0].upload.path) {
    //       presenterImage = active.images[0].upload.path;
    //     }
    //   }
    // }
    if (active.name) {
      presenterName = active.name;
    }
    if (active.occupations) {
      if (active.occupations[0]) {
        if (active.occupations[0].position) {
          presenterPosition = active.occupations[0].position;
        }
      }
    }
  }

  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent
        className={
          isOnlyVideo
            ? `${classes.content} only-video-content`
            : `${classes.contentDefault}`
        }
      >
        <Typography component='h5' variant='h6'
          className='title'
        // style={{
        //   fontFamily:Object.entries(isFont_title).length > 0 ? isFont_title.fontFamily : "",
        //   color:Object.entries(isFont_title).length > 0 ? isFont_title.fontColor : "",
        //   fontWeight:Object.entries(isFont_title).length > 0 ? isFont_title.fontWeight : "",
        //   fontSize:Object.entries(isFont_title).length > 0 ? isFont_title.fontSizeBase : "",
        // }}
        
        >
          {presenterName}
        </Typography>
        <Typography component='h5' variant='h6'
                  className='description'

          // className={classes.subtitle}
          // variant='body2'
          // color='textSecondary'
          // style={{
          //   fontFamily:Object.entries(isFont_description).length > 0 ? isFont_description.fontFamily : "",
          //   color:Object.entries(isFont_description).length > 0 ? isFont_description.fontColor : "",
          //   fontWeight:Object.entries(isFont_description).length > 0 ? isFont_description.fontWeight : "",
          //   fontSize:Object.entries(isFont_description).length > 0 ? isFont_description.fontSizeBase : "",
            
          // }}
        >
          {presenterPosition}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default React.memo(PresenterItemSmall);
