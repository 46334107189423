import React, { lazy, Suspense, useRef, useEffect, useState, useContext } from 'react';
import Split from 'react-split';

// MUI
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CssBaseline from '@material-ui/core/CssBaseline';

import './index.css';

// Components
import VideoPlayer from '../../components/players/video';
import WpAudioPlayer from '../../components/players/audio';
// import NoPlayer from '../../components/players/NoPlayer';
import SnackBar from '../../components/UI/snackbar/SnackBar';
import WebcastingContext from '../../context/webcasting/webcastingContext';
import useQnaStatus from './hooks/useQnaStatus';

const CarouselBox = lazy(() => import('../../components/carousel/CarouselBox'));

const ReversedPlayerSlides = ({ event, withThumbs, preview, ispresenter, socket_wc }) => {
  const assetType = event.assetType;
  const bgColor = event.webcast.colour.background;
  const webcastingContext = useContext(WebcastingContext)
  const {QnaStatus, onDemandFullScreenMode, authorization,noRegID} = webcastingContext
  const [closeSnackbarResize, setCloseSnackbarResize] = useState(false);
  const { componentSnackBarSlide } = useQnaStatus(QnaStatus, event);

  const resizeRef = useRef();
  const sendActivity = ()=>{

    if(event.registrationRequired) {
      let action = {
            userID:  Object.entries(authorization).length > 0 && authorization.registrants[0].findId ,
            action:Object.entries(authorization).length > 0 &&  JSON.parse(authorization.registrants[0].fields).email +  ' Used Slider to adjust screen layout',
            type:'SLIDER',
            eventID:event._id,
            time:new Date(),
            details:{      
            }
      }
      socket_wc.emit('userAction',action);  
    } else {
      let action = {
        userID:  noRegID.length > 0 && noRegID ,
        action:noRegID.length > 0 && noRegID +  ' Used Slider to adjust screen layout',
        type:'SLIDER',
        time:new Date(),
        eventID:event._id,
        details:{      
        }
  }
  socket_wc.emit('userAction',action);  
    }   
  }
  useEffect(() => {
    if (resizeRef.current) {
      const gutter = resizeRef.current.parent.children[1];
      gutter.addEventListener('mousedown', () => {
        setCloseSnackbarResize(true);
      });
    }
  }, []);

  return (
    <>
      <CssBaseline />
      <>
      
      {
   

     <Container
     maxWidth='xl'
     className={
       !withThumbs
         ? 'activeRegionsContainer'
         : 'activeRegionsContainerWithThumbs'
     }
     // style={{ backgroundColor: bgColor !== '' ? bgColor : null }}
   >
     {assetType === 'video' ? (
       event.isLive ? (
         <Split
           ref={resizeRef}
           direction='horizontal'
           cursor='col-resize'
          //  sizes={[65, 35]}
          //  minSize={[350, 350]}
          //  className='resize-container'
                 sizes={ QnaStatus ? [0,100] : [65, 35]}
          //  minSize={QnaStatus ? [350,350] : [350, 350]}
          gutterSize={QnaStatus ? 0.01: 10}
          onDragEnd={sendActivity}
          className='resize-container'
          style={{justifyContent:QnaStatus ? 'center' : ''}}
         >
          {
   !QnaStatus &&  <SnackBar
   open={componentSnackBarSlide}
   close={closeSnackbarResize}
   liveStarted={event.liveStarted}
   element={'resize'}
   reversed={true}
   position={{ vertical: 'top', horizontal: 'center' }}
   preview={preview}
 />
          }
        
           <div className='resize-item' style={{display:QnaStatus ? 'none' : ''}}>
             <Suspense fallback={null}>
               <Grid
                 item
                 xs={12}
                 sm={12}
                 md={7}
                 lg={7}
                 xl={7}
                 id='slidesBox'
                 className={QnaStatus ?  'slidesBox' :  'slidesBox leftIn'}
               >
                 <CarouselBox
                   slides={event.slides}
                   isLive={event.isLive}
                   media={event.media}
                   assetType={event.assetType}
                   preview={preview}
                   socket_wc={socket_wc}
                 />
               </Grid>
             </Suspense>
           </div>
           <div className= {QnaStatus ? 'resize-item videoTopIn' :'resize-item videoRightIn'} style={{  display:QnaStatus && 'flex', justifyContent:QnaStatus && 'center'}}>
             <Grid
               item
              xs={QnaStatus ? 12 : 12}
               sm={QnaStatus ? 12 : 12}
               md={QnaStatus ? 9 : 12}
               lg={QnaStatus ? 9 : 12}

               id='playerBox'
              //  className='playerSlidesBox'
             >
               <Box
                 mb={0}
                 className={QnaStatus ? '': 'playerWithSlides'}
                 style={{ backgroundColor: bgColor !== '' ? bgColor : null, height:'100%' }}
               >
                 <VideoPlayer
                   media={event.media}
                   isLive={event.isLive}
                   presenters={event.presenters}
                   ispresenter={ispresenter}

                   videoThumb={event.videoThumb}
                   title={event.title}
                   preview={preview}
                 />
               </Box>
             </Grid>
           </div>
         </Split>
       ) : (
         <Grid
           item
           xs={12}
           sm={12}
           md={onDemandFullScreenMode ? 12 : 5}
           lg={onDemandFullScreenMode ? 12 : 5}
           xl={onDemandFullScreenMode ? 12 : 5}
           id='playerChaptersBox'
           className={ onDemandFullScreenMode ?   '' : 'playerSlidesContainerReverse'}
         >
          {
          !onDemandFullScreenMode &&    <Suspense fallback={null}>
             <Grid
               item
               xs={12}
               sm={12}
               md={7}
               lg={7}
               xl={7}
               id='slidesBox'
               className='slidesBox leftIn'
             >
               <CarouselBox
                 slides={event.slides}
                 isLive={event.isLive}
                 media={event.media}
                 assetType={event.assetType}
                 preview={preview}
                 socket_wc={socket_wc}
               />
             </Grid>
           </Suspense>

          }
                            <Box style={{display:onDemandFullScreenMode && 'flex', justifyContent:onDemandFullScreenMode && 'center'}}>

           <Grid
             item
             xs={onDemandFullScreenMode ? 9 : 12}
           sm={onDemandFullScreenMode ? 9 : 12}
           md={onDemandFullScreenMode ? 9 : 12}
           lg={onDemandFullScreenMode ? 9 : 12}
           xl={onDemandFullScreenMode ? 9 : 12}
             id='playerBox'
             className='playerBox'
           >
             <Box
               mb={0}
               style={{
                 height: '100%',
               }}
               className={onDemandFullScreenMode ? 'QNAvideo' : ''}

             >
               <VideoPlayer
                 media={event.media}
                 isLive={event.isLive}
                 presenters={event.presenters}
                 ispresenter={ispresenter}

                 videoThumb={event.videoThumb}
                 title={event.title}
                 preview={preview}
               />
             </Box>
           </Grid>
           </Box>
         </Grid>
       )
     ) : (
       event.assetType === 'audio' && (
         <Grid
           item
           xs={12}
           sm={12}
           md={QnaStatus ? 12 :5}
           lg={QnaStatus ? 12 :5}
           xl={QnaStatus ? 12 :5}
           id='playerChaptersBox'
           className= {
            QnaStatus ? "" :
            'playerSlidesContainerReverse gridWithAudioReverse'
           }
           
           
         >
           <div className='resize-item' style={{display:QnaStatus ? 'none' : ''}}>

          <Suspense fallback={null}>
             <Grid
               item
               xs={12}
               sm={12}
               md={7}
               lg={7}
               xl={7}
               id='slidesBox'
               className={QnaStatus ?  'slidesContainer' :  'slidesContainer leftIn'}
             >
               <CarouselBox
                 slides={event.slides}
                 isLive={event.isLive}
                 media={event.media}
                 assetType={event.assetType}
                 preview={preview}
                 socket_wc={socket_wc}
               />
             </Grid>
           </Suspense>

          </div>
           

           <div className={QnaStatus ? '' :'resize-item resize-video-chapter-container'} style={{  display:QnaStatus && 'flex', justifyContent:QnaStatus && 'center'}}>

           <Grid
             item
             xs={QnaStatus ? 9 : 12}
             sm={QnaStatus ? 9 : 12}
             md={QnaStatus ? 9 : 12}
             lg={QnaStatus ? 9 : 12}
             xl={QnaStatus ? 9 : 12}
             id='playerBox'
             className='playerSlidesBox'
           >
             <Box
               mb={0}
               className={QnaStatus ?'playerWithSlides gridPlayerAudio videoTopIn': 'playerWithSlides gridPlayerAudio rightIn' }
               style={{ backgroundColor: bgColor !== '' ? bgColor : null }}
             >
               <WpAudioPlayer
                 media={event.media}
                 isLive={event.isLive}
                 preview={preview}
               />
             </Box>
           </Grid>
</div>


         </Grid>
       )
     )}
   </Container>


      }
      
      </>
      



    </>
  );
};

export default ReversedPlayerSlides;
