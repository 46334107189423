import React, { Fragment, useState } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CssBaseline from '@material-ui/core/CssBaseline';

// Components
import VideoPlayer from '../../components/players/video';
import WpAudioPlayer from '../../components/players/audio';
import NoPlayer from '../../components/players/NoPlayer';

import './index.css';

const PlayerOnly = ({ event, withThumbs, isOnlyVideo, preview, ispresenter }) => {
  // NOTES
  // Last DIV is the video player background
  // currently it is not used as a stylistic element as it has not been appreciated or understood
  // for the moment he is put on the bench

  const [elementHeight, setElementHeight] = useState();

  const getHeight = (el) => {
    setElementHeight(el);
  };

  return (
    <Fragment>
      <CssBaseline />
      <Container
        // maxWidth='md'
        // maxWidth='lg'
        className={
          !withThumbs
            ? 'activeRegionsContainerPlayerOnly'
            : 'activeRegionsContainerWithThumbs'
        }
      >
        <Grid container justify='center' className='playerOnlyContainer'>
          <Grid
            item
            xs={12}
            // sm={12}
            // md={12}
            // lg={10}
            // xl={12}
            id='playerBox'
            className='playerBox'
          >
            <Box mb={0} height='100%'>
              {event.assetType === 'video' ? (
                <VideoPlayer
                  media={event.media}
                  isLive={event.isLive}
                  presenters={event.presenters}
                  ispresenter={ispresenter}

                  videoThumb={event.videoThumb}
                  title={event.title}
                  isOnlyVideo={isOnlyVideo}
                  preview={preview}
                  getHeight={getHeight}
                />
              ) : event.assetType === 'audio' ? (
                <WpAudioPlayer
                  media={event.media}
                  isLive={event.isLive}
                  preview={preview}
                />
              ) : (
                <NoPlayer />
              )}
            </Box>
          </Grid>
        </Grid>
        {/* {isOnlyVideo && (
          <div
            className={
              elementHeight !== undefined ? 'videoBackground' : undefined
            }
            style={{
              height: elementHeight !== undefined && elementHeight,
            }}
          ></div>
        )} */}
      </Container>
    </Fragment>
  );
};

export default PlayerOnly;
