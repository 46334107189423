import React, { lazy, Suspense, useContext, useState, useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import publicIp from 'react-public-ip';

import "./index.css";

// Heplers
import { checkLayout } from "../helpers";
import moment from "moment/moment";
import { gaEvent } from "../helpers/reactGA";
import ReactGA, { event } from "react-ga";
// import {
//   REACT_APP_GOOGLE_ANALYTICS_ID,
//   STATS_API_TOKEN,
//   STATS_API,
// } from "../config/config";
import { isMobile } from "react-device-detect";

// Context
import WebcastingContext from "../context/webcasting/webcastingContext";

// Active Regions Components
import PlayerOnly from "./layouts/PlayerOnly";
import PlayerSlides from "./layouts/PlayerSlides";
import ReversedPlayerSlides from "./layouts/ReversedPlayerSlides";
import PlayerChapters from "./layouts/PlayerChapters";
import PlayerSlidesChapters from "./layouts/PlayerSlidesChapters";
import ReversedPlayerSlidesChapters from "./layouts/ReversedPlayerSlidesChapters";
import UpcomingEvent from "./UpcomingEvent";

// Components
import HeaderBar from "../components/header/HeaderBar";
import BackgroundImg from "../components/UI/BackgroundImg";
import Toolbar from "../components/toolbar";
import HeadStyle from "./HeadStyle";
import axios from "axios";
import HeadStyleForNoReg from "./HeadStyleForNoReg";
import io from 'socket.io-client';
import SocketIOHOC from "../components/websocket/SocketIOHOC";
import useConditionalTimeout from "../hooks/useConditionalTimeout";
import LeaveUserModal from "../components/UI/LeaveUserModal";
import submitLogs from "../config/AWSGlobalLog";

const Thumbs = lazy(() => import("../components/carousel/Thumbs"));

// ReactGA.initialize(`${process.env.REACT_APP_GOOGLE_ANALYTICS_ID}`, {
//   debug: false,
//   titleCase: false,
//   redactEmail: false,
//   gaOptions: { siteSpeedSampleRate: 100 },
// });
function isLogoTypeWebsite(logo) {
  return logo.type === "website";
}

const WebcastingPlatform = (props) => {
  let eventAssetType = "";
  let eventIsLive = false;
  let logoPath = "";
  // let brandingFound = false;
  let logoPadding = "";
  let headerTitle = "";
  let headerBackground = "#ffffff";

  const webcastingContext = useContext(WebcastingContext);

  const {
    loading,
    localData,
    authorization,
    updateLoginTime,
    branding,
    userStartTime,
    sendUserStartAndEndTime,
    setUserStartTiming,
    userStartTimeTracking,
    getGeoData,
    addDeviceName,
    //noreq
    getGeoDataAndDeviceInfo,
    sendUserStartAndEndTimeNoReq,
    liveStatus,
    noRegID,
    onDemandFullScreenMode,
    referrerDomain
    } = webcastingContext;

  const [isPreview, setIsPreview] = useState(false);
  const [socketWc, setSocketWc] = useState(null);
  const [showModal, setShowModal] = useState(false);

  let connectSocket = null;

  const removeFromLiveUsersList = () => {
    setShowModal(true);
    socketWc.emit('leaveEvent', props.event.items[0]._id,
    props.event.items[0].registrationRequired ? (Object.entries(authorization).length > 0 && authorization.registrants[0].findId) : noRegID
      );  
        // console.log('TIMEOUTTIMEOUTTIMEOUTTIMEOUTTIMEOUT')
  };

  // Use the custom hook to conditionally set a timeout
  useConditionalTimeout(removeFromLiveUsersList, 3600000,  !props.event.items[0].isLive);


  // const [noReqID, setnoReqID] = useState("");

  const date = new Date();
  const handleReload = () => {
    // Reload the page
    window.location.reload();
  };

  
  useEffect(() => {
    console.log('REACT_RERENDER loaded')
    const socket_wc = io(`${process.env.REACT_APP_SOCKET_IO_URL}/webcasting`,{
      reconnectionDelay: 5000
    }); // Update with your server URL

    socket_wc.on('connect', () => {
      
      if (socket_wc.recovered) {
        submitLogs(
          JSON.stringify({
              status: 'RECOVERD',
              message: `Socket.io RECOVERD, for user ${props.event.items[0].registrationRequired ? (Object.entries(authorization).length > 0 && authorization.registrants[0].findId) : noRegID}`
  
          }) )
       console.log('RECOVERD', socket_wc)
      } 
  });


  socket_wc.on('reconnect', (attemptNumber) => {
    console.log(`Reconnected after setDelay ${attemptNumber} attempts`);
  
});


    setSocketWc(socket_wc)

    return () => {
      console.log('REACT_RERENDER close')
      socket_wc.emit('leaveEvent', props.event.items[0]._id,
      props.event.items[0].registrationRequired ? (Object.entries(authorization).length > 0 && authorization.registrants[0].findId) : noRegID
        );
      socket_wc.off('connect')
      };
  }, []);


  useEffect(()=>{
    console.log('socketWc MAJOR', socketWc)
  },[socketWc])



  const getIp = async ()=>{
    if(props.event.items[0].registrationRequired) {
      Object.entries(authorization).length > 0 && getGeoData(await publicIp.v4(), props.match.match.params.eventId, authorization.registrants[0]._id) 
      Object.entries(authorization).length > 0 &&  addDeviceName(authorization.registrants[0]._id,props.match.match.params.eventId,  window.navigator.platform)
      //Also post user device information
      //send user's device information 
    }
  }

  const getIpNoReq = async ()=>{
      //This request add (Geo data, platform info and also referrer domain for NoReg )
      getGeoDataAndDeviceInfo(noRegID, await publicIp.v4(), props.event.items[0]._id, document.referrer)
      
    }



    useEffect(() => {
      
      if(noRegID) {
        
        console.log('no reg post', noRegID)
        // console.log("Event", props.event.items[0]);
        getIpNoReq() 
        //start time 
        setUserStartTiming(new Date().toUTCString());


      }


    
  }, [noRegID]);


//Geo And Platform Event with Req
useEffect(()=>{
  authorization &&  getIp()
  Object.entries(authorization).length > 0  && updateLoginTime(authorization.registrants[0]._id);
},[authorization])

  useEffect(() => {
  if(props.event.items[0].registrationRequired) {

    if (Object.entries(authorization).length > 0) {

      //send window referrer
      referrerDomain(authorization.registrants[0]._id, document.referrer)
      
      ReactGA.initialize(`${process.env.REACT_APP_GOOGLE_ANALYTICS_ID}`, {
        debug: false,
        titleCase: false,
        redactEmail: false,
        gaOptions: { siteSpeedSampleRate: 100 },
      });     
    }
  }
  }, [authorization])


  useEffect(() => {
    if (props.match.match.path.includes("preview")) setIsPreview(true);

    // eslint-disable-next-line
  }, []);

  // const liveUsersRequest = async () => {
  //   try {
  //     Object.entries(authorization).length > 0 && await axios.get(
  //       `${process.env.REACT_APP_STATS_API_URL}/event/liveUsers/${props.event.items[0]._id}/?userID=${authorization.registrants[0]._id}&token=${process.env.REACT_APP_STATS_API_TOKEN}`
  //     );
      
  //   } catch (error) {
  //     console.log('Stats failed', error)
  //   }
  // };


  // const liveUsersRequestNoReq = async () => {
  //   try {
  //     await axios.get(
  //       `${process.env.REACT_APP_STATS_API_URL}/event/liveUsersNoReq/${props.event.items[0]._id}/?uid=${noRegID}&token=${process.env.REACT_APP_STATS_API_TOKEN}`
  //     );
      
  //   } catch (error) {
  //     console.log('Stats failed', error)

  //   }
  // };




  // useEffect(() => {
  //   console.log('presenter on or off', props.event.items[0].webcast.layout.filter((item)=>  item.name === 'Presenter').length )
  //   if (
  //     !props.match.match.path.includes("preview")  &&
  //     props.event.items[0].registrationRequired !== false
  //   ) {
  //       Object.entries(authorization).length > 0  && updateLoginTime(authorization.registrants[0]._id);

  //     authorization && setUserStartTiming(new Date().toUTCString());

  //     authorization && liveUsersRequest();

  //     if (isMobile) {
  //       window.addEventListener("pageshow", function () {
  //         console.log("Page show");
  //         if(props.event.items[0].registrationRequired) {
  //           liveUsersRequest();
  //         } else {
  //           liveUsersRequestNoReq()
  //         }
  //       });

  //       window.addEventListener("focus", function () {
  //         console.log("Page Focus");
  //               if(props.event.items[0].registrationRequired) {
  //           liveUsersRequest();
  //         } else {
  //           liveUsersRequestNoReq()

  //         }
  //       });
  //     }

  //     // getTokenAndSaveNoReglocal();

  //   }
  //   //Without registration
  //   else {
  //     console.log('go for NoReg')
  //     // getTokenAndSaveNoReglocal();
  //   }

  //   // eslint-disable-next-line
  // }, []);

  // window.addEventListener("pagehide", (event) => {}, false);

  // useEffect(() => {
  //   authorization && console.log("userStart", userStartTimeTracking);

  //   // check if the user is using mobile phone
  //   //@dec -> Exit event for mobile devices
  //   // comment t=below code for mobile while working local 
  //   if (isMobile) {
  //       let removeUser = false;
  //       window.addEventListener('blur', function() {
  //         console.log('Blurr ')
  //         mobileUserAlert()
  //         removeUser = true;
  //      });
  //       document.onvisibilitychange = () => {
  //         if (document.visibilityState === 'hidden' || document.visibilityState === 'prerender')  {
  //             if(!removeUser) {
  //               mobileUserAlert()
  //         }
  //       };
  //     }
  //   } else {
  //     // window.addEventListener("beforeunload", alertUser);
  //   }
  //   // window.addEventListener("unload", (event) => {
  //   //   alertUser()
  //   // });
  //   return () => {
  //     window.removeEventListener("beforeunload", alertUser);
  //     // window.removeEventListener('pagehide', alertUser);
  //   };
  // }, [userStartTimeTracking, userStartTimeTracking]);

  // //For tracking when the browser is closed on phone
  // const mobileUserAlert = async () => {
    
  //   if(props.event.items[0].registrationRequired) {
  //     console.log('This is event with reg')
  //     // Object.entries(authorization).length > 0 && sendUserStartAndEndTime(
  //     //   authorization.registrants[0]._id,
  //     //   userStartTimeTracking
  //     // );
  //     //Remove from live users
  //    Object.entries(authorization).length > 0 &&  await axios.get(
      
  //       `${process.env.REACT_APP_STATS_API_URL}/event/removeLiveUser/${props.event.items[0]._id}/?userID=${authorization.registrants[0]._id}&token=${process.env.REACT_APP_STATS_API_TOKEN}`
  //     );
  //     sendUserStartAndEndTimeNoReq(
  //       noRegID,
  //       userStartTimeTracking,
  //       props.event.items[0]._id
  //     );
  //     let res = await axios.get(
  //       // `${process.env.REACT_APP_STATS_API_URL}/event/removeLiveUserNoReq/${props.event.items[0]._id}/?userID=${noReqID}&token=${process.env.REACT_APP_STATS_API_TOKEN}`
  //       `${process.env.REACT_APP_STATS_API_URL}/event/removeLiveUserNoReq/${props.event.items[0]._id}/?token=${process.env.REACT_APP_STATS_API_TOKEN}&userID=${noRegID}`

  //     );  
  //   } else {
  //     console.log('This is event with no reg')

  //     sendUserStartAndEndTimeNoReq(
  //       noRegID,
  //       userStartTimeTracking,
  //       props.event.items[0]._id
  //     );
  //     let res = await axios.get(
  //       // `${process.env.REACT_APP_STATS_API_URL}/event/removeLiveUserNoReq/${props.event.items[0]._id}/?userID=${noReqID}&token=${process.env.REACT_APP_STATS_API_TOKEN}`
  //       `${process.env.REACT_APP_STATS_API_URL}/event/removeLiveUserNoReq/${props.event.items[0]._id}/?token=${process.env.REACT_APP_STATS_API_TOKEN}&userID=${noRegID}`

  //     );  
  //   }
  // };

  // //For tracking when the browser is closed on PC
  // const alertUser = async (e) => {
  //   e.preventDefault();
  //   e.returnValue = "";
  //   // console.log('end time' , new Date())
  //   if(props.event.items[0].registrationRequired) {
  //     // sendUserStartAndEndTime(
  //     //   authorization.registrants[0]._id,
  //     //   userStartTimeTracking
  //     // );
  //     //Remove from live users
  //     let res = await axios.get(
  //       `${process.env.REACT_APP_STATS_API_URL}/event/removeLiveUser/${props.event.items[0]._id}/?userID=${authorization.registrants[0]._id}&token=${process.env.REACT_APP_STATS_API_TOKEN}`
  //     );

  //     sendUserStartAndEndTimeNoReq(
  //       noRegID,
  //       userStartTimeTracking,  
  //       props.event.items[0]._id
  //     );
  //     let res1 = await axios.get(
  //       `${process.env.REACT_APP_STATS_API_URL}/event/removeLiveUserNoReq/${props.event.items[0]._id}/?token=${process.env.REACT_APP_STATS_API_TOKEN}&userID=${noRegID}`
  //       // `${process.env.REACT_APP_STATS_API_URL}/event/removeLiveUserNoReq/${props.event.items[0]._id}/?userID=${noReqID}&token=${process.env.REACT_APP_STATS_API_TOKEN}`
  //     );


  //   } else {

  //     sendUserStartAndEndTimeNoReq(
  //       noRegID,
  //       userStartTimeTracking,
  //       props.event.items[0]._id
  //     );
  //     let res = await axios.get(
  //       `${process.env.REACT_APP_STATS_API_URL}/event/removeLiveUserNoReq/${props.event.items[0]._id}/?token=${process.env.REACT_APP_STATS_API_TOKEN}&userID=${noRegID}`
  //       // `${process.env.REACT_APP_STATS_API_URL}/event/removeLiveUserNoReq/${props.event.items[0]._id}/?userID=${noReqID}&token=${process.env.REACT_APP_STATS_API_TOKEN}`
  //     );
  //   }

  // };

  if (props.loading || props.event.length === 0) {
  } else {
    if (
      props.event.items[0].companies[0].logos.find(isLogoTypeWebsite) ===
      undefined
    ) {
      logoPath = null;
    } else {
      logoPath =
        props.event.items[0].companies[0].logos.find(isLogoTypeWebsite).upload
          .path;
    }

    // logoPath =
    //   props.event.items[0].companies[0].logos.find(isLogoTypeWebsite).upload
    //     .path;

    eventAssetType = props.event.items[0].assetType;
    eventIsLive = props.event.items[0].isLive;

    // props.event.items[0].webcast.branding.fonts.forEach((font) => {
    //   if (font.fontLinkHref !== '') brandingFound = true;
    // });

    if (props.event.items[0].webcast.branding.logo) {
      logoPadding =
        props.event.items[0].webcast.branding.logo.padding !== ""
          ? props.event.items[0].webcast.branding.logo.padding
          : "10px";
    } else {
      logoPadding = "10px";
    }

    headerTitle = props.event.items[0].headerTitle
      ? props.event.items[0].headerTitle
      : props.event.items[0].title;
  }

  if (props.event.items[0].webcast) {
    if (props.event.items[0].webcast.colour) {
      if (props.event.items[0].webcast.colour.background) {
        headerBackground = props.event.items[0].webcast.colour.background;
      }
    }
  }

  /////////////////////////////////////////////
  // Active Regions
  let activeRegionsApi;
  let currentLayout;
  let thumbs = false;
  let isOnlyVideo = false;
  let presenters = 'off';
  let reverseLayout = props.event.items[0].webcast.reverseLayout;
  const audio = ["Audio"];
  const video = ["Video"];
  const audioSlidesLayout = ["Audio", "Slides"];
  const videoSlidesLayout = ["Slides", "Video"];
  const audioChaptersLayout = ["Chapters", "Audio"];
  const videoChaptersLayout = ["Chapters", "Video"];
  const audioSlidesChapersLayout = ["Audio", "Slides", "Chapters"];
  const videoSlidesChapersLayout = ["Video", "Slides", "Chapters"];

  if (props.event.items[0].webcast) {
    let filterdActiveRegions;
    const getActiveRegionsApi = props.event.items[0].webcast.layout.map(
      (r) => r.name
    );

    if (eventAssetType.toLowerCase() === "video") {
      filterdActiveRegions = getActiveRegionsApi.filter(
        (el) => el.toLowerCase() !== "audio"
      );
    }

    if (eventAssetType.toLowerCase() === "audio") {
      filterdActiveRegions = getActiveRegionsApi.filter(
        (el) => el.toLowerCase() !== "video"
      );
    }

    thumbs = filterdActiveRegions.find((el) => el === "Thumbs")
      ? (thumbs = true)
      : thumbs;

    activeRegionsApi = filterdActiveRegions.filter(
      (region) => region !== "Thumbs"
    );

    presenters = filterdActiveRegions.find((el) => el === "Presenter")
      ? (presenters = 'on')
      : presenters;

      activeRegionsApi = filterdActiveRegions.filter(
        (region) => region !== "Presenter"
      );

  }

  // Check if event is only with Video Player
  if (activeRegionsApi.length === 0 && eventAssetType === "video") {
    isOnlyVideo = true;
  }
  if (activeRegionsApi.length === 1) {
    isOnlyVideo = activeRegionsApi[0] === "Video";
  }
  // Set Current Layout
  if (
    activeRegionsApi.length === 0 ||
    checkLayout(activeRegionsApi, video) ||
    checkLayout(activeRegionsApi, audio)
  ) {
    currentLayout = (
      socketWc &&    <PlayerOnly
        event={props.event.items[0]}
        withThumbs={thumbs}
        isOnlyVideo={isOnlyVideo}
        preview={props.preview}
        ispresenter={ presenters}
        socket_wc={socketWc}
      />
    );
  } else if (
    checkLayout(activeRegionsApi, audioSlidesLayout) ||
    checkLayout(activeRegionsApi, videoSlidesLayout)
  ) {
    if (reverseLayout === true) {
      currentLayout = (
        socketWc &&    <ReversedPlayerSlides
          event={props.event.items[0]}
          withThumbs={thumbs}
          preview={props.preview}
          ispresenter={ presenters}
          socket_wc={socketWc}
          // ispresenter={props.match.location.search.includes('ispresenter=off') ? 'off' : 'on'}
        />
      );
    } else {
      currentLayout = (
        socketWc &&   <PlayerSlides
          event={props.event.items[0]}
          withThumbs={thumbs}
          preview={props.preview}
          ispresenter={ presenters}
          socket_wc={socketWc}
          // ispresenter={props.match.location.search.includes('ispresenter=off') ? 'off' : 'on'}
        />
      );
    }
  } else if (
    checkLayout(activeRegionsApi, audioChaptersLayout) ||
    checkLayout(activeRegionsApi, videoChaptersLayout)
  ) {
    currentLayout = (
      socketWc &&   <PlayerChapters
        event={props.event.items[0]}
        withThumbs={thumbs}
        preview={props.preview}
        isOnlyVideo={true}
        ispresenter={ presenters}
        socket_wc={socketWc}
        // ispresenter={props.match.location.search.includes('ispresenter=off') ? 'off' : 'on'}
      />
    );
  } else if (
    checkLayout(activeRegionsApi, audioSlidesChapersLayout) ||
    checkLayout(activeRegionsApi, videoSlidesChapersLayout)
  ) {
    if (reverseLayout === true) {
      currentLayout = (
        socketWc &&   <ReversedPlayerSlidesChapters
          event={props.event.items[0]}
          withThumbs={thumbs}
          preview={props.preview}
          ispresenter={ presenters}
          socket_wc={socketWc}
          // ispresenter={props.match.location.search.includes('ispresenter=off') ? 'off' : 'on'}
        />
      );
    } else {
      currentLayout = (
        socketWc &&   <PlayerSlidesChapters
          event={props.event.items[0]}
          withThumbs={thumbs}
          preview={props.preview}
          ispresenter={ presenters}
          socket_wc={socketWc}
          // ispresenter={props.match.location.search.includes('ispresenter=off') ? 'off' : 'on'}
        />
      );
    }
  } else {
    currentLayout = (
      socketWc &&    <PlayerSlidesChapters
        event={props.event.items[0]}
        withThumbs={thumbs}
        preview={props.preview}
        ispresenter={ presenters}
        socket_wc={socketWc}
        // ispresenter={props.match.location.search.includes('ispresenter=off') ? 'off' : 'on'}
      />
    );
  }

  const ssss = (event) => {
    let start = moment(event.items[0].releaseTime, " YYYY-MM-DDTHH:mm:ss.SSSZ");
    let now = moment();
    let diff = start.diff(now);
    return diff;
  };

  const [completed, setCompleted] = useState(false);
  const isCompleted = () => {
    setTimeout(() => {
      setCompleted(true);
    }, 1000);
  };

  return (
    <SocketIOHOC socket_wc={socketWc} eventID={props.event.items[0]._id} onDemand={!props.event.items[0].isLive}  isEventLive={props.event.items[0].registrationRequired} authorization={authorization} noRegID={noRegID}>

    <>
    <LeaveUserModal
        open={showModal}
        onClose={() => setShowModal(false)}
        onReload={handleReload}
        
      />
      {props.branding ? <HeadStyleForNoReg branding={props.branding} /> : <HeadStyle />}
      {!completed &&
      !isPreview &&
      props.event.items[0].isLive === false &&
      ssss(props.event) > 0 ? (
        <>
          <UpcomingEvent
            match={props.match}
            loading={loading}
            event={props.event}
            saveAuth={true}
            authorization={props.match.match.params.confirmationId}
            localData={localData}
            timeToEvent={ssss(props.event)}
            brandingFound={branding}
            countDownCompleted={isCompleted}
          />
        </>
      ) : (
        <>
          {props.match.location.search.includes("template=clean") ? (
            <></>
          ) : props.event.items[0].webcast.background ? (
            <>
              {props.event.items[0].webcast.background.page !== null ? (
                <BackgroundImg
                  path={props.event.items[0].webcast.background.page.path}
                  img={true}
                  colour={null}
                />
              ) : props.event.items[0].webcast.colour.background !== null ? (
                <BackgroundImg
                  path={null}
                  img={false}
                  colour={props.event.items[0].webcast.colour.background}
                />
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
          <CssBaseline />

          <Container maxWidth="xl" className="mainContainer">
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              id="firstRow"
              className="whiteBg"
            >
              {props.match.location.search.includes("template=clean") ? (
                <Box
                  p={2}
                  // style={{
                  //   backgroundColor:
                  //     props.event.items[0].webcast.colour.border || '#fff',
                  // }}
                ></Box>
              ) : (
                <HeaderBar
                  eventId={props.match.match.params.eventId}
                  eventHeaderTitle={headerTitle}
                  logoPath={logoPath}
                  logoPadding={logoPadding}
                  headerBackground={headerBackground}
                  brandingFound={branding}
                  isTransparent={props.event.items[0].webcast.colour.transparentHeader}
                  titleStyles={props.event.items[0].webcast.branding.fonts[1]}

                />
              )}
            </Grid>

            <Grid
              container
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              direction="row"
              spacing={0}
              id="secondRow"
              className="whiteBg"
            >
              {currentLayout}
            </Grid>

            <Suspense fallback={null}>
              {thumbs && !eventIsLive &&  !onDemandFullScreenMode && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  id="thumbsBox"
                  className="thumbsContainer"
                >
                  
                  <Thumbs
                    slides={props.event.items[0].slides}
                    preview={props.preview}
                    socket_wc={socketWc}
                  />
                </Grid>
              )}
            </Suspense>
          </Container>
          {props.match.location.search.includes("controlbar=no") ||
          !props.event.items[0].webcast.controlPanel ? (
            <></>
          ) : (
              socketWc &&   <Toolbar socket_wc={socketWc} event={props.event.items[0]} preview={props.preview} />
          )}
        </>
      )}
    </>
    </SocketIOHOC>
  );
};

export default React.memo(WebcastingPlatform);
