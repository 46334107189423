import React from 'react';
import { CssBaseline, Container, Grid, Typography } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ListItemText from '@material-ui/core/ListItemText';
import { PRIVACY_POLICY_LINK_URL } from '../../config/config';
import './CookiePrivacyPolicyPage.css';

const TermsAndConditionsPage = () => {
  return (
    <Container maxWidth='md' className='policy-container'>
      <CssBaseline />
      <Grid container spacing={4}>
        <Grid item className='policy-title'>
          <Typography variant='h4'>Terms of Use</Typography>
        </Grid>
        <Grid item>
          <Typography paragraph={true}>
            These BRR Media Terms of Use (the “Terms of Use”) govern your access
            to or use of the online and hybrid events platform and services
            generally described at www.brrmedia.co.uk and its associated
            services (online and offline), products, software, mobile
            applications, information, networks, distribution channels,
            components, APIs, and documentation (”Services”) offered or made
            available by BRR Media Ltd (“BRR Media” &quot;we&quot;
            &quot;us&quot; or “our&quot;).
          </Typography>
          <Typography paragraph={true}>
            These Terms of Use form a legally binding contract between you and
            us.
          </Typography>
          <Typography paragraph={true}>
            Review the{' '}
            <Link href={PRIVACY_POLICY_LINK_URL} target='_blank'>
              BRR Media Privacy Policy
            </Link>{' '}
            for more information on how we collect and use data relating to the
            use of our services and products.
          </Typography>
          <Typography paragraph={true}>1. Who these terms apply to</Typography>
          <Typography paragraph={true}>
            You are subject to these Terms of Use (a “User”) if you are a
            “Participant” which is defined as any individual or entity that
            signs up for, or who registers for, attends, participates in, or
            interacts at, an Event.
          </Typography>
          <Typography paragraph={true}>
            You are also subject to these terms if you are a “Team Member”,
            which is defined as any individual or entity that is affiliated with
            the Host (see below) and that uses or accesses the Services in any
            way. Examples include without limitation, employees or
            sub-contracted parties of the Host.
          </Typography>
          <Typography paragraph={true}>1.1 BRR Media's role</Typography>
          <Typography paragraph={true}>
            Events are organized and administered by the Host, not BRR Media. We
            are a third party providing the Services and its incorporated
            technology platforms (the “Platform”) to facilitate an Event taking
            place. For the purpose of this Agreement, the Services includes the
            Platform.
          </Typography>
          <Typography paragraph={true}>
            The term “Host” refers to the company, entity, institution,
            organization, or individual who is the public facing “owner” of the
            content delivered via the virtual or hybrid event (“Event”).
          </Typography>
          <Typography paragraph={true}>
            We are not responsible and cannot be held liable for any matters
            described in the Host Responsibility section (below).
          </Typography>
          <Typography paragraph={true}>1.2 Host's responsibilities</Typography>
          <Typography paragraph={true}>
            The Host has full control over the running of any Event. This
            includes the invitee list, when Events are created and how they are
            configured, and what functionalities are available for use at each
            Event.
          </Typography>
          <Typography paragraph={true}>
            The Host is solely responsible:
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon />
              </ListItemIcon>
              <ListItemText primary='if an Event is cancelled;' />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon />
              </ListItemIcon>
              <ListItemText primary='for how an Event is run;' />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon />
              </ListItemIcon>
              <ListItemText primary='for any content produced or provided;' />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon />
              </ListItemIcon>
              <ListItemText primary='for any activities carried out during an Event;' />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon />
              </ListItemIcon>
              <ListItemText primary=' and for monitoring and managing the conduct of Users.' />
            </ListItem>
          </List>
          <Typography paragraph={true}>
            Please contact the Host directly for any of the above matters. It is
            solely the Host's responsibility to respond to and resolve any
            dispute between the Host and any Participant or Team Member. The
            Host may also block or revoke a User's access to its Event at any
            time and in its sole discretion.
          </Typography>
          <Typography paragraph={true}>2. Right to use BRR Media</Typography>
          <Typography paragraph={true}>2.1. Licence</Typography>
          <Typography paragraph={true}>
            Subject to these Terms of Use, we grant you a limited,
            non-sublicensable licence to access and use BRR Media:
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon />
              </ListItemIcon>
              <ListItemText primary='where you are a Team Member, for the internal business purposes of the Host only, to create, organise, manage and host Events on BRR Media; and' />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon />
              </ListItemIcon>
              <ListItemText primary='where you are a Participant, to register for, attend and participate in Events for your personal, non-commercial purposes only, subject to any additional terms imposed by the Host.' />
            </ListItem>
          </List>
          <Typography paragraph={true}>2.2 Restrictions</Typography>
          <Typography paragraph={true}>
            You agree that you will not (unless you have our express prior
            permission):
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon />
              </ListItemIcon>
              <ListItemText primary='reproduce, duplicate, copy or resell any part of the Services or related content;' />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon />
              </ListItemIcon>
              <ListItemText
                primary='resell, rent, lease, sub-licence, loan, provide, distribute, or otherwise make available, your right to use or access BRR Media or any part of it, in any form, in whole or in part to any person without prior written consent from us or in respect to Event content except as permitted by the Host;
'
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon />
              </ListItemIcon>
              <ListItemText
                primary='use BRR Media maliciously, for example, by hacking into or inserting malicious code, such as viruses, or harmful data, into BRR Media or any operating system;
'
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon />
              </ListItemIcon>
              <ListItemText primary='use BRR Media in a way that could damage, disable, overburden, impair or compromise our systems or security or interfere with other Users, including by removing, circumventing, damaging or otherwise interfering with security-related features that prevent or restrict use or copying, pasting, printing, downloading or sharing of any content, or that enforce limitations on the use of the Services or related content;' />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon />
              </ListItemIcon>
              <ListItemText primary='access, collect, harvest or modify any information, content or data related to the Services using any robot, spider, scraper or other automated means or any manual process for any purpose, or attempt to decipher any transmissions to or from the servers running BRR Media;' />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon />
              </ListItemIcon>
              <ListItemText primary='access without authority, interfere with, damage or disrupt any part of the Services, any equipment or network on which the Services is stored, any software used in the provision of the Services, or any equipment or network or software owned or used by any third party;' />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon />
              </ListItemIcon>
              <ListItemText primary='use another user’s information (including email addresses) to contact them outside of BRR Media without their express permission or authority, or to create or distribute mailing lists or other collections of contact or user profile information for other Users for use outside of BRR Media; and' />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon />
              </ListItemIcon>
              <ListItemText primary='impersonate someone else to create an account, create or use an account for anyone other than yourself, permit anyone else to use your account, or provide personal information for purposes of account registration other than your own.' />
            </ListItem>
          </List>
          <Typography paragraph={true}>
            3. Contributions and Personal Data
          </Typography>
          <Typography paragraph={true}>
            3.1 As part of an Event, you may submit or make available certain
            content, information, and data, such as messages or files
            (“Contributions”). You are responsible for your Contributions, and
            you assume all risks associated with your Contributions, including
            anyone's reliance on its quality, accuracy, reliability,
            appropriateness, or any disclosure that makes you or anyone else
            personally identifiable. You represent and warrant that you own or
            have the necessary rights, consents, and permissions to use and
            authorise the use of Contributions as described herein. You may not
            imply that Contributions are in any way sponsored or endorsed by BRR
            Media.
          </Typography>
          <Typography paragraph={true}>
            We are not responsible or liable to you or any third-party for the
            content or accuracy of any Contributions.
          </Typography>
          <Typography paragraph={true}>
            3.2 Personal data in Contributions
          </Typography>
          <Typography paragraph={true}>
            As between BRR Media and the Host, the Host is the data controller
            of recordings of Event content, including any of your personal data
            embedded therein. We explain our role as the data processor of such
            personal data in the{' '}
            <Link href={PRIVACY_POLICY_LINK_URL} target='_blank'>
              BRR Media Privacy Policy
            </Link>{' '}
            but we strongly encourage you to review the privacy notices and
            policies of the Host and other participants in the Event as
            applicable.
          </Typography>
          <Typography paragraph={true}>
            4. Intellectual property rights
          </Typography>
          <Typography paragraph={true}>
            All intellectual property rights in the Services throughout the
            world belong to us (or our licensors). You have no intellectual
            property rights in, or to, the Services other than the right to use
            it in accordance with these Terms of Use.
          </Typography>
          <Typography paragraph={true}>
            All intellectual property rights in the Services throughout the
            world belong to us (or our licensors). You have no intellectual
            property rights in, or to, the Services other than the right to use
            it in accordance with these Terms of Use.
          </Typography>

          <Typography paragraph={true}>5. Limitation of Liability</Typography>
          <Typography paragraph={true}>5.1 Disclaimer of Warranties</Typography>
          <Typography paragraph={true}>
            The services are provided for general information purposes only. It
            does not offer advice on which you should rely. You must obtain
            professional or specialist advice before taking, or refraining from,
            any action on the basis of information obtained from BRR Media.
          </Typography>
          <Typography paragraph={true} className='capitalized'>
            THE SERVICES ARE PROVIDED “AS-IS” AND ON AN “AS AVAILABLE” BASIS AND
            YOU USE IT SOLELY AT YOUR OWN RISK. BRR Media DOES NOT REPRESENT OR
            WARRANT THAT THE FUNCTIONALITY OF ANY OF THE SERVICES WILL BE IN
            GOOD REPAIR OR ERROR-FREE OR FREE FROM DELAYS, DEFECTS, OMISSIONS,
            INTERRUPTIONS OR INACCURACIES. ALL REPRESENTATIONS AND WARRANTIES,
            WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, WITHOUT
            LIMITATION, ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A
            PARTICULAR PURPOSE, OR NON-INFRINGEMENT OF THIRD-PARTY RIGHTS, ARE
            HEREBY DISCLAIMED BY BRR MEDIA TO THE MAXIMUM EXTENT PERMITTED BY
            APPLICABLE LAW.
          </Typography>
          <Typography paragraph={true}>
            Therefore,{' '}
            <span className='capitalized'>
              WITHOUT LIMITING THE RELEASE OF LIABILITY:
            </span>
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon />
              </ListItemIcon>
              <ListItemText primary='to the fullest extent permissible by law, BRR Media hereby disclaims and make no representations, warranties, endorsements, or promises, express or implied, as to: (i) the services (including Contributions); (ii) the functions, features, or any other elements on, or made accessible through, the services, including whether the services will be available at any particular time or location; (iii) whether information or instructions offered or referenced at or linked through the services is accurate, complete, correct, adequate, useful, timely, or reliable; (iv) whether the services or the servers that make the services available are free from any harmful components; and (v) whether your use of the services is lawful in any particular jurisdiction.' />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon />
              </ListItemIcon>
              <ListItemText
                className='capitalized'
                primary='THE SERVICES MAY BE SUBJECT TO LIMITATIONS, DELAYS, AND OTHER PROBLEMS INHERENT IN THE USE OF THE INTERNET AND ELECTRONIC COMMUNICATIONS. BRR MEDIA IS NOT RESPONSIBLE FOR ANY DELAYS, DELIVERY FAILURES, OR OTHER DAMAGE RESULTING FROM SUCH PROBLEMS.'
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon />
              </ListItemIcon>
              <ListItemText
                className='capitalized'
                primary='IN NO EVENT SHALL BRR MEDIA BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, PUNITIVE, OR ANY OTHER DAMAGES OF ANY KIND OR INJURIES ARISING FROM THE SERVICES; AND'
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon />
              </ListItemIcon>
              <ListItemText
                className='capitalized'
                primary='YOU HEREBY ACKNOWLEDGE AND AGREE THAT, EXCEPT AS MAY OTHERWISE BE LIMITED BY LAW, BRR MEDIA IS NOT RESPONSIBLE OR LIABLE FOR ANY CLAIM, INCLUDING THOSE THAT ARISE OUT OF OR RELATE TO (A) ANY RISK, DANGER, OR HAZARD DESCRIBED IN THIS AGREEMENT, (B) YOUR USE OF, OR INABILITY TO USE, ANY OF THE SERVICES, (C) YOUR BREACH OF THESE TERMS OF USE AND/OR YOUR VIOLATION OF ANY LAW, RULE, REGULATION, AND/OR ORDINANCE, (D) ANY NEGLIGENCE, MISCONDUCT, AND/OR OTHER ACTION AND/OR INACTION BY YOU OR ANY THIRD PARTY.'
              />
            </ListItem>
          </List>
          <Typography paragraph={true} className='capitalized'>
            SOME JURISDICTIONS DO NOT ALLOW DISCLAIMERS OF VARIOUS WARRANTIES,
            SO ONE OR MORE OF THE ABOVE DISCLAIMERS MAY NOT APPLY TO YOU. TO THE
            EXTENT SUCH WARRANTIES CANNOT BE DISCLAIMED UNDER THE LAWS OF YOUR
            JURISDICTION, WE LIMIT THE DURATION AND REMEDIES OF SUCH WARRANTIES
            TO THE FULL EXTENT PERMISSIBLE UNDER THOSE LAWS.
          </Typography>
          <Typography paragraph={true}>
            5.2 We are not responsible for events outside our control
          </Typography>
          <Typography paragraph={true}>
            If the provisioning of the Services is delayed by an event outside
            our control, we will contact you as soon as reasonably possible to
            let you know and we will take steps to minimize the effect of the
            delay. Provided we have acted reasonably in letting you know about
            the delay where it is possible for us to do so, we will not be
            liable for delays caused by the event.
          </Typography>
          <Typography paragraph={true}>
            5.3 We are not responsible for third party websites and services
          </Typography>
          <Typography paragraph={true}>
            The Services may contain links or referrals to third party and other
            independent websites and services which are not provided by us. Such
            websites and services are not under our control, and we are not
            responsible for and have not checked and approved their content or
            their privacy policies (if any). You will need to make your own
            independent judgement about whether to use them, including whether
            to buy any products or services offered by them.
          </Typography>
          <Typography paragraph={true}>6. Termination</Typography>
          <Typography paragraph={true}>
            6.1 You may terminate these Terms of Use at any time by ceasing to
            use the services of BRR Media.
          </Typography>
          <Typography paragraph={true}>
            6.2 We can, in our sole discretion, terminate these Terms of Use
            and/or your right to access or use BRR Media at any time, without
            notice, for any reason, including where we reasonably consider that
            (a) your use of the Services violates these Terms of Use or
            applicable law; or (b) we are unable to continue providing BRR Media
            to you due to technical or legitimate business reasons. In the event
            of such termination:
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon />
              </ListItemIcon>
              <ListItemText primary='BRR Media revokes any licenses granted to you hereunder;' />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon />
              </ListItemIcon>
              <ListItemText primary='You must stop all activities authorized by these Terms of Use, including your use of BRR Media.' />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon />
              </ListItemIcon>
              <ListItemText primary='We may take all steps necessary to prevent you from accessing or using BRR Media.' />
            </ListItem>
          </List>
          <Typography paragraph={true}>7. Technical requirements</Typography>
          <Typography paragraph={true}>
            In order to use BRR Media, it is necessary that you have a device
            that allows you access to the Internet, and an email. You expressly
            acknowledge and agree that we are not responsible for satisfying the
            foregoing requirements, and that the quality of messages,
            information or files sent, posted, streamed, published or otherwise
            transmitted using BRR Media may differ from the uploaded stream or
            file, in order to conform and adapt to standards of transferring
            data, connecting networks, devices or requirements of BRR Media.
          </Typography>
          <Typography paragraph={true}>8. Other important terms</Typography>
          <Typography paragraph={true}>8.1 Contacting us</Typography>
          <Typography paragraph={true}>
            If you are having any issues using the Services, please visit
            contact BRR Media via{' '}
            <Link href='mailto:info@brrmedia.co.uk'>info@brrmedia.co.uk</Link>
          </Typography>
          <Typography paragraph={true}>
            If we have to contact or notify you, we will do so by the contact
            information you provided us or via notification on the Platform or
            other reasonable method.
          </Typography>
          <Typography paragraph={true}>8.2 Updates to BRR Media</Typography>
          <Typography paragraph={true}>
            From time to time we may automatically update and change the
            Services to improve performance, enhance functionality, reflect
            changes to the operating system or address security issues. We will
            not need your consent to do this, and your continued use of the
            Services following the update will be subject to these Terms of Use.
          </Typography>
          <Typography paragraph={true}>
            8.3 If someone else owns the phone or device you are using
          </Typography>
          <Typography paragraph={true}>
            If you stream BRR Media onto any phone, laptop, desktop or other
            device not owned by you, you must have the owner's permission to do
            so. You will be responsible for complying with these Terms of Use,
            whether or not you own the phone or other device.
          </Typography>
          <Typography paragraph={true}>
            8.4 Changes to these Terms of Use
          </Typography>
          <Typography paragraph={true}>
            We may change these Terms of Use to reflect changes in law or best
            practice, or to address changes in our Services. If you do not
            accept the changes, you are not permitted to continue using the
            Services.
          </Typography>
          <Typography paragraph={true}>
            8.5 If a court finds part of this agreement illegal, the rest will
            continue in force
          </Typography>
          <Typography paragraph={true}>
            Each of the paragraphs of these Terms of Use operates separately. If
            any court or relevant authority decides that any of them are
            unlawful, the remaining paragraphs will remain in full force and
            effect.
          </Typography>
          <Typography paragraph={true}>
            8.6 Even if we delay in enforcing this agreement, we can still
            enforce it later
          </Typography>
          <Typography paragraph={true}>
            Even if we delay in enforcing these Terms of Use, we can still
            enforce it later. If we do not insist immediately that you do
            anything you are required to do under these Terms of Use, or if we
            delay in taking steps against you in respect of your breaking this
            agreement, that will not mean that you do not have to do those
            things and it will not prevent us taking steps against you at a
            later date.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default React.memo(TermsAndConditionsPage);
