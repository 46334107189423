import React, { useEffect, useRef } from 'react';
// import { isMobile } from 'react-device-detect';
import ReactPlayerLoader from '@brightcove/react-player-loader';
// import {
//   BRIGHTCOVE_ACCOUNT_ID,
//   BRIGHTCOVE_PLAYER_ID,
// } from '../../../config/config';

// HELPERS
import { gaEvent } from '../../../helpers/reactGA';

import '../../../static/video-js.css';
import './index.css';
import submitLogs from '../../../config/AWSGlobalLog';

const MemoizedVideoJs = ({
  bcVideoId,
  seekToTime,
  getSeekingTime,
  getPlayerCurrentTime,
  hasMetaData,
  preview,
}) => {
  const playerRef = useRef();
  const oldSeekToTimeRef = useRef(seekToTime);
  const seekToTimeRef = useRef(0);

  const onFailure = (error) => {
    submitLogs(
      JSON.stringify({
          status:'ERROR',
          message:'BRIGHTCOVE',
          error:error
      })
    )
    console.log('ERROR:', error);
  };

  seekToTimeRef.current = seekToTime;

  playerRef.current && playerRef.current.currentTime(seekToTimeRef.current);

  const handlePlayer = (success) => {
    console.log('seekToTime in handlerPlayer: ', seekToTimeRef.current);
    if (success !== undefined) {
      playerRef.current = success.ref;

      success.ref.on('loadedmetadata', function () {
        hasMetaData();
      });
      success.ref.on('play', function () {
        if (!preview) gaEvent('video', 'play', `${success.ref.currentTime()}`);

        if (seekToTime !== oldSeekToTimeRef.current) {
          getSeekingTime(success.ref.currentTime());
          oldSeekToTimeRef.current = seekToTime;
        }
      });
      success.ref.on('pause', function () {
        if (!preview) gaEvent('video', 'pause', `${success.ref.currentTime()}`);
      });
      success.ref.on('timeupdate', function () {
        getPlayerCurrentTime(success.ref.currentTime());
      });
      success.ref.on('ended', function () {
        if (!preview) gaEvent('video', 'ended', `${success.ref.currentTime()}`);
      });
      success.ref.on('fullscreenchange', function () {
        let isFS = success.ref.isFullscreen();

        if (!preview) {
          isFS &&
            gaEvent('video', 'full screen', `${success.ref.currentTime()}`);
        }
      });
      success.ref.on('volumechange', function () {
        if (!preview) {
          if (success.ref.volume() === 0) {
            gaEvent('video', 'volume change', '0');
            gaEvent('volume_change', 'video', '0');
          }
          success.ref.muted()
            ? gaEvent('video', 'mute', `${success.ref.currentTime()}`)
            : gaEvent('video', 'unmute', `${success.ref.currentTime()}`);
        }
      });
      success.ref.on('enterpictureinpicture', function () {
        if (!preview)
          gaEvent('video', 'openpip', `${success.ref.currentTime()}`);
      });
      success.ref.on('leavepictureinpicture', function () {
        if (!preview)
          gaEvent('video', 'closepip', `${success.ref.currentTime()}`);
      });
      success.ref.controlBar.progressControl.seekBar.on(
        'mouseup',
        function (e) {
          getSeekingTime(success.ref.currentTime());
          if (!preview)
            gaEvent('video', 'seek', `${success.ref.currentTime()}`);
        }
      );

      // Allow to play pause on mobile clicking on the video
      success.ref.on('touchstart', function (e) {
        if (e.target.nodeName === 'VIDEO') {
          if (success.ref.paused()) {
            success.ref.play();
          } else {
            success.ref.pause();
          }
        }
      });
    }
  };

  const options = {
    playsinline: true,
    controlBar: {
      // fullscreenToggle: true,
      // playToggle: true,
      // progressControl: {
      //   seekBar: true,
      // },
      // responsive: true,
      // breakpoints: {
      //   tiny: 300,
      //   xsmall: 400,
      //   small: 500,
      //   medium: 600,
      //   large: 700,
      //   xlarge: 800,
      //   huge: 900,
      // },
    },
  };


  useEffect(()=>{
      if(getPlayerCurrentTime > 10) {
        console.log('current time stamp getPlayerCurrentTime',getPlayerCurrentTime )

      }
  },[getPlayerCurrentTime])

  return (
    <>
      <ReactPlayerLoader
        accountId={process.env.REACT_APP_BRIGHTCOVE_ACCOUNT_ID}
        videoId={bcVideoId}
        playerId={process.env.REACT_APP_BRIGHTCOVE_PLAYER_ID}
        onSuccess={handlePlayer}
        onFailure={onFailure}
        controls={true}
        id='myPlayerID'
        options={options}
      />
    </>
  );
};

const areEqual = (prevProps, nextProps) => {
  // console.log('areEqual: ', prevProps.seekToTime);
  return prevProps.seekToTime === nextProps.seekToTime;
};

export default React.memo(MemoizedVideoJs, areEqual);
