import {
  GET_EVENT,
  GET_AUTHORIZATION,
  GET_CHECK_AUTHLIST,
  AUTHLIST_RESPONSE,
  SET_AUTHLIST,
  SET_REGISTRANT,
  SET_QUESTIONSENT,
  SET_NOTESENT,
  RESET_NOTESENT_VALUE,
  GET_LOGO,
  SET_BRANDING,
  SET_LOADING,
  EVENT_ERROR,
  SAVE_LOCAL_DATA,
  READ_LOCAL_DATA,
  CURRENT_THUMB,
  CURRENT_PRESENTER,
  SEEKTO_TIME,
  GET_PLAYER_CURRENT_TIME,
  GET_PROGRESS_BAR_CURRENT_TIME,
  WEBSOCKET_CLIENT,
  LIVE_STATUS,
  LIVE_LAYOUT,
  GET_MESSAGE_TO_ALL,
  CLEAR_ERROR,
  HASMETADATA,
  SET_AUDIO_PIP,
  USER_IP,
  GET_USER_IP_PERMISSION,
  UPDATE_LOGIN_TIME,
  SET_USER_START_TIME,
  USER_VERIFIED,
  SET_NOREG_ID,
  EVENT_PASSWORD_VERIFIED,
  QUERY_SENT,
  SET_FONT_STYLING,
  QNA_STATUS,
  FULL_SCREEN_MODE_ON_DEMAND,
  SET_GA,
} from '../types.js';

export default (state, action) => {
  switch (action.type) {
    case GET_EVENT:
      console.log('set qna mode', action.payload.items[0].QnaStatus)
      return {
        ...state,
        event: action.payload,
        QnaStatus:action.payload.items[0].QnaStatus,
        onDemandFullScreenModeTimings:action.payload.items[0].QnaStatusOnDemandTimings,
        loading: false,
      };
    case GET_USER_IP_PERMISSION:
      return {
        ...state,
        userIpPermission: action.payload,
        loading: false,
      };

    case GET_AUTHORIZATION:
      return {
        ...state,
        authorization: action.payload,
        loading: false,
      };
      
    case GET_CHECK_AUTHLIST:
      return {
        ...state,
        authList: action.payload,
        loading: false,
      };
    case AUTHLIST_RESPONSE:
      return {
        ...state,
        authListResponse: action.payload,
        loading: false,
      };
    case SET_AUTHLIST:
      return {
        ...state,
        authListCompleted: action.payload,
        loading: false,
      };
    case SET_QUESTIONSENT:
      return {
        ...state,
        questionSent: action.payload,
        loading: false,
      };
    case SET_REGISTRANT:
      return {
        ...state,
        registrant: action.payload,
        loading: false,
      };

      case SET_NOREG_ID:
        return {
          ...state,
          noRegID:action.payload
        };

      case USER_VERIFIED:
      return {
        ...state,
        userVerified: true,
      };

      case EVENT_PASSWORD_VERIFIED:
        return {
          ...state,
          noRegEventPassword:true
        }

    case UPDATE_LOGIN_TIME:
      return {
        ...state,
        updateRegistrantLoginTime: {
          loggedInTime: action.payload.registrant.loggedInTime,
          updated_at: action.payload.registrant.updated_at,
        },
        userStartTime:new Date().toUTCString(),
        loading: false,
      };

    case SET_USER_START_TIME:
      return {
        ...state,
        userStartTimeTracking:action.payload,
        loading:false
      }
    case SET_NOTESENT:
      return {
        ...state,
        noteSent: action.payload,
        loading: false,
      };
    case RESET_NOTESENT_VALUE:
      return {
        ...state,
        noteSent: action.payload,
        loading: false,
      };
    case GET_LOGO:
      return {
        ...state,
        logo: action.payload,
        loading: false,
      };
    // @branding - Bool 
    case SET_BRANDING:
      return {
        ...state,
        branding:action.payload
      };

      //Set Fonts

    case SET_FONT_STYLING:

        console.log('Fonts in reducers', action.payload)

    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case EVENT_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case SAVE_LOCAL_DATA:
      return {
        ...state,
        resultSaveLocal: action.payload,
        loading: false,
      };
    case READ_LOCAL_DATA:
    
      return {
        ...state,
        localData: action.payload,
        loading: false,
      };

      case SET_GA:
      return {
        ...state,
        ga_enabled: action.payload,
      };
      
    case CURRENT_THUMB:
      return {
        ...state,
        currentThumb: action.payload,
        loading: false,
      };
    case USER_IP:
      return {
        ...state,
        userIp: action.payload,
        loading: false,
      };
    case CURRENT_PRESENTER:
      return {
        ...state,
        currentPresenter: action.payload,
        loading: false,
      };
    case SEEKTO_TIME:
      return {
        ...state,
        seekToTime: action.payload,
        loading: false,
      };
    case GET_PLAYER_CURRENT_TIME:
      return {
        ...state,
        playerCurrentTime: action.payload,
        loading: false,
      };
    case GET_PROGRESS_BAR_CURRENT_TIME:
      return {
        ...state,
        progressBarCurrentTime: action.payload,
        loading: false,
      };
    case WEBSOCKET_CLIENT:
      return {
        ...state,
        event: action.payload,
        loading: false,
      };
    case LIVE_STATUS:
      return {
        ...state,
        liveStatus: action.payload,
        loading: false,
      };
      
    case LIVE_LAYOUT:
      return {
        ...state,
        liveLayout: action.payload,
        loading: false,
      };
      case QNA_STATUS:
        return {
          ...state,
          QnaStatus: action.payload,
          loading: false,
        };
      case FULL_SCREEN_MODE_ON_DEMAND:
      return {
        ...state,
        onDemandFullScreenMode: action.payload,
        loading: false,
      };
    case GET_MESSAGE_TO_ALL:
      return {
        ...state,
        messageToAll: action.payload,
        loading: false,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case HASMETADATA:
      return {
        ...state,
        hasmetadata: action.payload,
      };

      case QUERY_SENT:
        return {
          ...state,
          querySent:action.payload
        };
  


    case SET_AUDIO_PIP:
      return {
        ...state,
        audioPip: action.payload,
      };
    default:
      return state;
  }
};
