import React, { useState, useEffect, useContext } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import { isMobileOnly } from 'react-device-detect';

import WebcastingContext from '../../../context/webcasting/webcastingContext';

const useStyle = makeStyles((theme) => ({
  snackToolbar: {
    marginBottom: '3rem',
  },
  snackSlides: {
    position: 'absolute',
    top: '-4px',
    right: '50px',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  snackWowza: {
    position: 'absolute',
    top: 'calc(100% - 100px)',
    left: '5px',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  snackResize: {
    position: 'absolute',
    top: 'calc(100% - 65%)',
    left: '35%',
    [theme.breakpoints.down(1023)]: {
      display: 'none',
    },
  },
  snackResizeReversed: {
    position: 'absolute',
    top: 'calc(100% - 80%)',
    left: '65%',
    [theme.breakpoints.down(1023)]: {
      display: 'none',
    },
  },
  questionMsg: {
    position: 'relative',
    padding: '6px 16px 0px 16px',
    '& div': {
      padding: '8px 0px 0px 0px',
      textAlign: 'center',
    },
    '& > div:nth-child(2)': {
      top: '-21px',
      left: '-13px',
    },
  },
  wowzaMsg: {
    padding: '6px 16px 0px 16px',
    '& div': {
      padding: '8px 0px 0px 0px',
      textAlign: 'center',
    },
    '& > div:nth-child(2)': {
      top: '-21px',
      right: '-5px',
    },
  },
  slidesMsg: {
    padding: '6px 4px 6px 16px',
    '& > div:nth-child(1)': {
      display: 'flex',
    },
    '& > div:nth-child(2)': {
      top: '-12px',
      left: '-28px',
    },
  },
  closeBtn: {
    '& > div:nth-child(2)': {
      position: 'absolute',
      '& button': {
        backgroundColor: '#2196f3',
      },
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const SnackBar = ({
  open,
  close,
  liveStarted,
  element,
  reversed,
  position,
  preview,
  hideBtnFunc = undefined,
}) => {
  const webcastingContext = useContext(WebcastingContext);

  const { liveStatus, QnaStatus } = webcastingContext;

  const [checkIfCanOpen, setCheckIfCanOpen] = useState(false);
  const [canOpen, setCanOpen] = useState(false);
  const [timeBeforeOpen, setTimeBeforeOpen] = useState(5000);

  const [isTooltiplocal, setIsTooltiplocal] = useState(false)


  useEffect(()=>{
    console.log('whats going on', !isMobileOnly &&  !QnaStatus)
    return ()=>{
      localStorage.removeItem('tooltip')

    }
  },[])


  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      let isTooltip = localStorage.getItem('tooltip')
      if(isTooltip) {
        setIsTooltiplocal(true)
      } else {
        let initialToolTip = localStorage.setItem('tooltip', 'yes')
        setIsTooltiplocal(false)
      }
      // NEXT LINE - REMOVE THE ESCLAMATION POINT FOR TESTING IN PREVIEW
      if (!preview) {
        console.log('toolTip loaded', isTooltiplocal)
        if (open) {
          if (liveStatus === 'start' || liveStarted === '1') {
            setCheckIfCanOpen(true);
          }
          if (liveStatus === 'stop') {
            setCheckIfCanOpen(false);
          }
        }
      }
    }

    return () => {
      isMounted = false;
    };

    // eslint-disable-next-line
  }, [liveStatus, open]);


  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if (element === 'slides') setTimeBeforeOpen(3000);
      if (element === 'video') setTimeBeforeOpen(2000);
      if (element === 'resize') setTimeBeforeOpen(2500);
    }

    return () => {
      isMounted = false;
    };
  }, [element]);

  useEffect(() => {
    const counter = setTimeout(() => {
      if (checkIfCanOpen) {
        setCanOpen(true);
      }
    }, timeBeforeOpen);

    return () => {
      clearTimeout(counter);
    };

    // eslint-disable-next-line
  }, [checkIfCanOpen]);

  useEffect(() => {
    if (close) handleCloseSnack();

    // eslint-disable-next-line
  }, [close]);

  ///////////////////// SNACKBAR MESSAGES - START
  const questionMsg = (
    <>
      <h3>Q&A Session Enabled</h3>
      <ExpandMoreRoundedIcon />
    </>
  );

  const slidesMsg = (
    <>
      <h3>Click the button to enlarge the slide</h3>
      <ChevronRightRoundedIcon />
    </>
  );

  const wowzaMsg = (
    <>
      <h3>Unmute Here</h3>
      <ExpandMoreRoundedIcon />
    </>
  );

  const resizeMsg = (
    <>
      <h3>Drag here to resize</h3>
      <ExpandMoreRoundedIcon />
    </>
  );
  ///////////////////// SNACKBAR MESSAGES - FINISH

useEffect(()=>{
  handleCloseSnack()
},[QnaStatus])


  const handleCloseSnack = (event, reason) => {
    
    if (reason === 'clickaway') {
      return;
    }

    if (hideBtnFunc !== undefined) {
      hideBtnFunc();
    }
    setCanOpen(false);
  };

  const classes = useStyle();
  const classToUse =
    element === 'toolbar'
      ? 'snackToolbar'
      : element === 'slides'
      ? 'snackSlides'
      : element === 'video'
      ? 'snackWowza'
      : reversed === true
      ? 'snackResizeReversed'
      : 'snackResize';

  return (
    (!isMobileOnly &&  !QnaStatus) && (
      <Snackbar
        open={canOpen}
        // autoHideDuration={element === 'resize' ? 3000 : null}
        anchorOrigin={position}
        onClose={handleCloseSnack}
        className={classes[classToUse]}
      >
        {element === 'toolbar'   ? (
          <Alert
            severity='info'
            icon={false}
            className={`${classes.questionMsg} ${classes.closeBtn}`}
            onClose={handleCloseSnack}
          >
            {questionMsg}
          </Alert>
        ) : element === 'slides' ? (
          <Alert
            severity='info'
            icon={false}
            className={`${classes.slidesMsg} ${classes.closeBtn}`}
            onClose={handleCloseSnack}
          >
            {slidesMsg}
          </Alert>
        ) : element === 'video' ? (
          <Alert
            severity='info'
            icon={false}
            className={`${classes.wowzaMsg} ${classes.closeBtn}`}
            onClose={handleCloseSnack}
          >
            {wowzaMsg}
          </Alert>
        ) : (
          element === 'resize' && (
            <Alert
              severity='info'
              icon={false}
              className={`${classes.wowzaMsg} ${classes.closeBtn}`}
              onClose={handleCloseSnack}
            >
              {resizeMsg}
            </Alert>
          )
        )}
      </Snackbar>
    )
  );
};

export default SnackBar;
