import React, { useEffect, useContext, useState } from 'react';

import './index.css';

import WebcastingContext from '../context/webcasting/webcastingContext';

// Components
import SpinnerAbsolute from '../components/UI/SpinnerAbsolute';
import NotFound from '../components/UI/NotFound';
import MemoizedPreviewLayout from './MemoizedPreviewLayout';
import HeadStyle from './HeadStyle';

const Console = (props) => {
  console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>> passed data to log: ', props);
  return null;
};

///////// NOTES /////////
// Recived props are React Route related such as: history, location, match
const PreviewLayout = (props) => {
  const webcastingContext = useContext(WebcastingContext);
  const { loading, event, getEvent, readLocalData, localData, branding } =
    webcastingContext;

  // const [branding, setBranding] = useState(false);

  useEffect(() => {
    getEvent(props.match.match.params.eventId);
    // eslint-disable-next-line
  }, [props.match.match.params.eventId]);

  useEffect(() => {
    readLocalData(event, null);
    // eslint-disable-next-line
  }, [event]);

  // useEffect(() => {
  //   if (event) {
  //     if (Array.isArray(event.items) && event.items.length) {
  //       if (event.items[0].webcast.branding.fonts.length > 0) {
  //     setBranding();            

  //       }
  //     }
  //   }
  // }, [event]);

  return (
    <>
      {loading || event.length === 0 ? (
        <SpinnerAbsolute />
      ) : event.success === false ? (
        <>
          <NotFound msg={event.message.message} />
          <Console msg={event} />
        </>
      ) : event.items.length === 0 ? (
        <>
          <NotFound msg='event not found' />
          <Console msg={event} />
        </>
      ) : (
        <>
          {event.items[0] && (
            <>
              {branding ? <HeadStyle branding={branding} /> : <HeadStyle />}
              <MemoizedPreviewLayout
                match={props.match}
                loading={loading}
                event={event}
                saveAuth={false}
                authorization={props.match.match.params.confirmationId}
                localData={localData}
                preview={true}
              />
            </>
          )}
        </>
        // <div>
        //   {event.items[0].isLive ? (
        //     <LiveLayout
        //       match={props.match}
        //       loading={loading}
        //       event={event}
        //       saveAuth={false}
        //       authorization={props.match.match.params.confirmationId}
        //       localData={localData}
        //       preview={true}
        //     />
        //   ) : (
        //     <WebcastingPlatform
        //       match={props.match}
        //       loading={loading}
        //       event={event}
        //       preview={true}
        //     />
        //   )}
        // </div>
      )}
    </>
  );
};

export default React.memo(PreviewLayout);
