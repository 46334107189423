import React, { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import './Chapters.css';

import WebcastingContext from '../../context/webcasting/webcastingContext';

// HELPERS
import { getCurrentIndex } from '../../helpers';
import { gaEvent } from '../../helpers/reactGA';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const Chapters = ({ preview }) => {
  const classes = useStyles();
  const webcastingContext = useContext(WebcastingContext);
  const {
    event,
    seekToTime,
    playerCurrentTime,
    getCurrentThumb,
    getSeekingTime,
    getProgressBarCurrentTime,
  } = webcastingContext;

  let chaptersArr = [];
  const [activeChap, setActiveChap] = useState(0);

  if (event.items[0].slides) {
    event.items[0].slides.filter((slide, index) => {
      if (slide.name !== '-') {
        chaptersArr.push({ index, name: slide.name, time: slide.time });
      }
      return null;
    });
  }

  useEffect(() => {
    let t = playerCurrentTime || seekToTime;

    setActiveChap(getCurrentIndex(t, chaptersArr));

    // eslint-disable-next-line
  }, [playerCurrentTime, seekToTime]);

  const GAchapterOpen = () => {
    gaEvent('chapter', 'chapter_list', 'open');
  };

  return (
    <>
      {chaptersArr.length !== 0 ? (
        <div className='chapters-container'>
          <FormControl variant='outlined' className={classes.formControl}>
            <InputLabel id='chapters-select' style={{ zIndex: 0 }}>
              Chapters
            </InputLabel>
            <Select
              labelId='chapters-select'
              id='chapters-select'
              value={activeChap}
              onOpen={GAchapterOpen}
              onChange={(event) => {
                getSeekingTime(chaptersArr[event.target.value].time);
                getCurrentThumb(chaptersArr[event.target.value].index);
                getProgressBarCurrentTime(chaptersArr[event.target.value].time);
                setActiveChap(event.target.value);
                gaEvent(
                  'chapter',
                  'changed',
                  `chapter name: ${chaptersArr[event.target.value].name}`
                );
              }}
              label='Chapters'
            >
              {chaptersArr.map((c, i) => (
                <MenuItem key={i} title={c.name} value={i}>
                  {c.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default React.memo(Chapters);
