import React from 'react';
import './NoPlayer.css';

const NoPlayer = () => {

  return (
    <div></div>
  );
};

export default React.memo(NoPlayer);
