import React, { useContext, useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

// Components
import PresenterAvatarAudio from '../UI/PresenterAvatarAudio';
import WebcastingContext from "../../context/webcasting/webcastingContext";

import './PresenterItem.css';

const useStyles = makeStyles({
  contentVideo: {
    height: '100%',
    padding: '1rem 2rem',
    borderRadius: '0px 0px 3px 3px',
    '&:last-child': {
      paddingBottom: '1.2rem',
    },
  },
  contentAudio: {
    padding: '0.7rem 2.1rem',
    textAlign: 'center',
    // borderRadius: '0px',
    '&:last-child': {
      paddingBottom: '1.4rem',
    },
  },
  subtitle: {
    lineHeight: '1',
  },
});

const PresenterItem = ({active, assetType, bgColor
, checkIfChaptersInLayout, isLive, presenterCardColors,small}) => {

  const webcastingContext = useContext(WebcastingContext);
  const {event} = webcastingContext
  console.log('presenter item', event)
  const [isFont_title, setIfFont_title] = useState({})
  const [isFont_description, setIfFont_description] = useState({})



  useEffect(()=>{
    if( event.items[0].webcast.branding.fonts.length >0) {
      if(Object.entries( event.items[0].webcast.branding.fonts[1]).length >0) {
        //Presenter Style Found 
        console.log('Presenter fonts', Object.entries( event.items[0].webcast.branding.fonts[1]).length)
        if(Object.entries( event.items[0].webcast.branding.fonts[1]).length > 0) {
          console.log('Presenter fonts',event.items[0].webcast.branding.fonts[1] )
          setIfFont_title(event.items[0].webcast.branding.fonts[1])
        }
      }


      if(Object.entries( event.items[0].webcast.branding.fonts[2]).length >0) {
        //Presenter Style Found 
        console.log('Presenter fonts', Object.entries( event.items[0].webcast.branding.fonts[2]).length)
        if(Object.entries( event.items[0].webcast.branding.fonts[2]).length > 0) {
          console.log('Presenter fonts',event.items[0].webcast.branding.fonts[2] )
          setIfFont_description(event.items[0].webcast.branding.fonts[2])
        }
      }


    }
},[event])



  let presenterName = '';
  let presenterPosition = '';
  const [brandignActive, setBrandingActive] = useState(false);

  // const [presenterBgColorStart, setPresenterBgColorStart] = useState('');
  // const [presenterBgColorEnd, setPresenterBgColorEnd] = useState('');
  // const [textColor, setTextColor] = useState('');

  useEffect(() => {
    console.log('Presenter Styles?????????', presenterCardColors)
    if (small!== true && Object.keys(presenterCardColors).length) {
      setBrandingActive(true);

      // setPresenterBgColorStart(props.presenterCardColors.backgroundColorStart);
      // setPresenterBgColorEnd(props.presenterCardColors.backgroundColorEnd);
      // setTextColor(props.presenterCardColors.fontColor);
    }
    // eslint-disable-next-line
  }, []);

  if (active) {
    // Presenter Image is already passed to PresenterAvatar as props.active
    // Next IF statement is unnecessary
    // if (props.active.images[0]) {
    //   if (props.active.images[0].upload) {
    //     if (props.active.images[0].upload.path) {
    //       presenterImage = props.active.images[0].upload.path;
    //     }
    //   }
    // }
    if (active.name) {
      presenterName = active.name;
    }
    if (active.occupations) {
      if (active.occupations[0]) {
        if (active.occupations[0].position) {
          presenterPosition = active.occupations[0].position;
        }
      }
    }
  }

  const linearGradientBg = {
    background:
      assetType === 'audio' && brandignActive
        ? `linear-gradient(${presenterCardColors.backgroundColorStart}, ${presenterCardColors.backgroundColorEnd} 75%)`
        : 'linear-gradient(#dddcd7bf, #fefefe 75%)',
  };

  // DO NOT CANCEL YET - MAYBE WE NEED FOR CUSTOMIZATION
  // style={{
  //   background:
  //     props.isLive &&
  //     `linear-gradient(${
  //       bgColor !== '' ? bgColor : '#b4b4b4'
  //     } 35%, #f7f7f7 75%)`,
  // }}

  const classes = useStyles();

  return (
    <div
      className={
        assetType === 'audio'
          ? 'presenterItemContainerInAudioPlayer'
          : null
      }
      style={linearGradientBg}
    >
      <Card
        className={
          assetType === 'audio'
            ? 'presenterItemInAudioPlayer'
            : 'presenterItemInVideoPlayer'
        }
      >
        {assetType === 'audio' && (
          <>
            <PresenterAvatarAudio
              active={active}
              checkIfChaptersInLayout={checkIfChaptersInLayout}
            />
          </>
        )}
        <CardContent
          className={
            assetType === 'audio'
              ? classes.contentAudio
              : classes.contentVideo
          }
        >
          <Typography
            component='h5'
            variant='h6'
            className='title'
            // style={{
            //   fontFamily:Object.entries(isFont_title).length > 0 ? isFont_title.fontFamily : "",
            //   color:Object.entries(isFont_title).length > 0 ? isFont_title.fontColor : "",
            //   fontWeight:Object.entries(isFont_title).length > 0 ? isFont_title.fontWeight : "",
            //   fontSize:Object.entries(isFont_title).length > 0 ? isFont_title.fontSizeBase : "",
              
            // }}
          >
            {presenterName}
          </Typography>

          <Typography
            // className={classes.subtitle}
            variant='body2'
            className='description'
            // style={{ color: brandignActive ? presenterCardColors.fontColor : 'textSecondary' }}
            // color='textSecondary'
            // style={{
            //   fontFamily:Object.entries(isFont_description).length > 0 ? isFont_description.fontFamily : "",
            //   color:Object.entries(isFont_description).length > 0 ? isFont_description.fontColor : "",
            //   fontWeight:Object.entries(isFont_description).length > 0 ? isFont_description.fontWeight : "",
            //   fontSize:Object.entries(isFont_description).length > 0 ? isFont_description.fontSizeBase : "",
              
            // }}
          >
            {presenterPosition}
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

export default React.memo(PresenterItem);
