import React, { useContext, useEffect } from 'react';
import { CssBaseline, Container, Grid, Typography, Card, CardActions, CardContent, Button } from '@material-ui/core';
import './CookiePrivacyPolicyPage.css';
import WebcastingContext from '../../context/webcasting/webcastingContext';

const CheckYourEmail = (props) => {

  const webcastingContext = useContext(WebcastingContext);

  const {validateUserID} = webcastingContext

  useEffect(()=>{
    console.log(props.match.params)
  },[])

  const verifyAgain = ()=>{
    let eventID = props.match.params.eventID;
    let findID = props.match.params.findID;
    console.log('Event id', eventID, 'find id', findID)
    validateUserID(eventID,findID)
  }

  return (
    <Container maxWidth='md' className='policy-container'>
      <CssBaseline />
      <Grid container spacing={4} style={{display:'flex', justifyContent:'center'}}>


    <Card style={{padding:'10%', marginTop:'20%', width:'80%', textAlign:'center'}}>
      <CardContent >

      <Grid  item className='policy-title'>
          <Typography variant='h4'>Verify your email</Typography>
        </Grid>
        <Grid item>
        <Typography >Email verification link has been sent to your email.</Typography>
      
        <Typography variant='h6' style={{textAlign:'center', marginTop:'5%'}} >Already verified? <Button onClick={verifyAgain} color='primary' variant='contained'> Click here</Button></Typography>

        </Grid>
      </CardContent>
    </Card>

      </Grid>
    </Container>
  );
};

export default React.memo(CheckYourEmail);
