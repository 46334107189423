import React, { useState, useEffect, useContext } from 'react';
import './index.css';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CssBaseline,
  Container,
  Collapse,
  Grid,
  IconButton,
  Link,
  Checkbox,
} from '@material-ui/core';

import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {
  GDPR_TEXT,
  PRIVACY_POLICY_LINK_TEXT,
  PRIVACY_POLICY_LINK_URL,
  TERMS_AND_CONDITIONS_LINK_TEXT,
  TERMS_AND_CONDITIONS_LINK_URL,
} from '../config/config';

// Components
import HeadStyle from './HeadStyle';
// import Spinner from '../components/UI/Spinner';
import HeaderBar from '../components/header/HeaderBar';
import BackgroundImg from '../components/UI/BackgroundImg';

import WebcastingContext from '../context/webcasting/webcastingContext';

import './RegistrationEmail.css';
const useStyles = makeStyles((theme) => ({
  formRoot: {
    padding: '0.5rem',
  },
  contentText: {
    marginBottom: '0px',
  },
  input: {
    '& ::before': {
      borderBottom: '2px solid #b3b3b3',
    },
    '& ::after': {
      borderBottom: '2px solid #cccccc !important',
    },
    '& :hover::before': {
      borderBottom: '2px solid #7e7e7e !important',
    },
  },
  footer: {
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '16px 24px 12px',
    fontSize: '11px',
    '& div': {
      '& span': {
        padding: '0px',
      },
    },
    '& p': {
      margin: '0px 0.2rem',
      lineHeight: 1.3,
      fontSize: '11px',
    },
    '& a': {
      color: '#333',
      fontWeight: '700',
      lineHeight: 1.3,
      textDecoration: 'underline',
    },
    '& button': {
      color: '#333',
      backgroundColor: '#fff',
      '&:hover': {
        color: '#fff',
        backgroundColor: '#333',
      },
    },
  },
}));

let countTimes = 1;

const CheckAgmAuth = ({ match, loading, event, authList, saveAuth }) => {
  // console.log(event.items[0].webcast.branding);

  const classes = useStyles();

  const webcastingContext = useContext(WebcastingContext);
  const {
    checkAuthListFormData,
    authListResponse,
    localData,
    saveLocalData,
    setAuthList,
    error,
    clearError,
    branding
  } = webcastingContext;
  const [eventId, setEventId] = useState('');
  const [paramType, setParamType] = useState('');
  const [fields, setFields] = useState([]);
  const [registrationData, setRegistrationData] = useState({});
  const [errorMsg, setErrorMsg] = useState('');
  const [buttonDataText, setButtonDataText] = useState('Send');
  const [submittedData, setSubmittedData] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [open, setOpenDialog] = useState(true);
  // const [brandingFound, setBrandingFound] = useState(false);
  const [logoPath, setLogoPath] = useState('');
  const [logoPadding, setLogoPadding] = useState('8px');
  const [headerTitle, setHeaderTitle] = useState('');
  const [disclaimer, setDisclainer] = useState(false);
  const [disclaimerText, setDisclaimerText] = useState('');
  const [checkedPrivacy, setCheckedPrivacy] = useState(false);
  const [checkedDisclaimer, setCheckedDisclaimer] = useState(false);

  // >>>>>>>>>>
  //      UNUSED STATE - DOUBLE CHECK IF WE NEED OR NOT - IF NOT CAN BE DELETED
  //      most of them are used in handleSubmitData
  // <<<<<<<<<<
  // const [formData, setFormData] = useState({});
  // const [submitReady, setSubmitReady] = useState(false);
  // const [readOnly, setReadOnly] = useState(false);
  // const [disabledData, setDisabledData] = useState(true);

  let headerBackground = '';
  if (event.items[0].webcast.colour.background !== '') {
    headerBackground = event.items[0].webcast.colour.background;
  }

  function isLogoTypeWebsite(logo) {
    return logo.type === 'website';
  }

  // SET LOGO, HEADER TITLE & CHECK BRANDING
  useEffect(() => {
    if (
      event.items[0].companies[0].logos.find(isLogoTypeWebsite) === undefined
    ) {
      setLogoPath(null);
    } else {
      setLogoPath(
        event.items[0].companies[0].logos.find(isLogoTypeWebsite).upload.path
      );
    }

    // event.items[0].companies[0].logos.forEach((el) => {
    //   if (el.type === 'website') {
    //     setLogoPath(el.upload.path);
    //   }
    // });

    // event.items[0].webcast.branding.fonts.forEach((font) => {
    //   if (font.fontLinkHref !== '') setBrandingFound(true);
    // });



    if (event.items[0].webcast.branding.logo.padding !== '') {
      setLogoPadding(event.items[0].webcast.branding.logo.padding);
    }

    if (!event.items[0].headerTitle) {
      setHeaderTitle(event.items[0].title);
    } else {
      setHeaderTitle(event.items[0].headerTitle);
    }
    // eslint-disable-next-line
  }, []);

  // SET PARAMS AGM
  useEffect(() => {
    setParamType(authList.listType);
    setEventId(authList.eventId);
    // eslint-disable-next-line
  }, []);

  // SET INPUTS FIELDS
  useEffect(() => {
    paramType === '2param' && setFields(['id', 'pin']);
  }, [paramType]);

  // SET DISCLAIMER
  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if (event.items[0]) {
        if (event.items[0].disclaimerRequired) {
          setDisclainer(true);
          setDisclaimerText(event.items[0].disclaimerBody);
        }
      }
    }

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, []);

  // MANAGE ERROR
  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      setSubmittedData(false);
      if (error !== null) {
        setErrorMsg('The given data are not in the list!');
        setOpenSnack(true);
        setButtonDataText('Send');
        clearError();
      }
    }

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [authListResponse, error]);

  // SETREGISTRANT
  // useEffect(() => {
  // submitReady && setRegistrant(match.match.params.eventId, formData);
  // eslint-disable-next-line
  // }, [submitReady]);

  const handleChangeData = (event) => {
    setRegistrationData({});
    setErrorMsg('');
    clearError();
    setOpenSnack(false);
    setButtonDataText('Send');
    const registrationDataKey = event.target.name;
    const registrationDataValue = event.target.value;

    setRegistrationData({
      ...registrationData,
      [`${registrationDataKey}`]: registrationDataValue,
    });
  };

  const handleSubmitData = (e) => {
    e.preventDefault();

    checkAuthListFormData(eventId, paramType, registrationData);
    setButtonDataText('Sending...');
    // setSubmittedData(true);

    // setReadOnly(true);

    // setFormData({
    //   eventid: eventId,
    //   fields: registrationData,
    // });

    // setSubmitReady(true);
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpenDialog(false);
    }
  };

  const handleCloseSnack = () => {
    setRegistrationData({});
    setErrorMsg('');
    clearError();
    setOpenSnack(false);
    setButtonDataText('Send');
  };

  const handleCheckboxPrivacy = (event) => {
    setCheckedPrivacy(event.target.checked);
  };

  const handleCheckboxDisclaimer = (event) => {
    setCheckedDisclaimer(event.target.checked);
  };

  // >>>>>>>>>>
  //      NEXT FUNC CAN ALSO BE DELETED
  //      the user need to check the checkbox anyway
  // <<<<<<<<<<
  // const validatorListenerData = (result) => {
  //   setDisabledData(!result);
  // };

  const saveLocalAuthAndGo = () => {
    let check = true;
    if (countTimes === 1) {
      countTimes++;
      if (localData.user.events.length > 0) {
        localData.user.events.forEach((event, i) => {
          if (event.eventId === match.match.params.eventId) {
            localData.user.events[i].agmAuth = true;
            check = false;
          }
        });
        if (check) {
          localData.user.events.push({
            eventId: match.match.params.eventId,
            agmAuth: true,
          });
        }
      } else {
        localData.user.events.push({
          eventId: match.match.params.eventId,
          agmAuth: true,
        });
      }
      // console.log('going to save: ', localData);
      saveLocalData(localData);
      setAuthList();
      // window.location.href = `../../broadcast/${match.match.params.eventId}`;
    } else {
      // console.log('debouncing');
    }
  };

  return (
    <>
      <HeadStyle />
      {event.items[0].webcast.background ? (
        event.items[0].webcast.background.page !== null && (
          <BackgroundImg
            path={event.items[0].webcast.background.page.path}
            img={true}
            colour={null}
          />
        )
      ) : event.items[0].webcast.colour.background !== null ? (
        <BackgroundImg
          path={null}
          img={false}
          colour={event.items[0].webcast.colour.background}
        />
      ) : (
        <></>
      )}
      <CssBaseline />

      <Container maxWidth='xl' className='mainContainer'>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          id='firstRow'
          className='whiteBg'
        >
          <HeaderBar
            eventHeaderTitle={headerTitle}
            logoPath={logoPath}
            logoPadding={logoPadding}
            headerBackground={headerBackground}
          />
        </Grid>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby='form-dialog-title'
          disableEscapeKeyDown
        >
          <ValidatorForm
            onSubmit={handleSubmitData}
            onError={(errors) => console.log(errors)}
            className={classes.formRoot}
          >
            {errorMsg !== '' && (
              <Collapse in={openSnack}>
                <Alert
                  variant='filled'
                  severity='error'
                  action={
                    <IconButton
                      aria-label='close'
                      color='inherit'
                      size='small'
                      onClick={handleCloseSnack}
                    >
                      <CloseIcon fontSize='inherit' />
                    </IconButton>
                  }
                >
                  {errorMsg}
                </Alert>
              </Collapse>
            )}

            {branding ? (
              <h2 className={'title branding-title'}>{event.items[0].title}</h2>
            ) : (
              <DialogTitle id='form-dialog-title' className='event-title'>
                {event.items[0].title}
              </DialogTitle>
            )}

            <DialogContent>
              <DialogContentText
                className={`content-text ${classes.contentText}`}
              >
                Please fill out details below
              </DialogContentText>

              {fields.map((field, idx) => (
                <TextValidator
                  className={classes.input}
                  key={idx}
                  // key={field.replace(/\W/g, '')}
                  margin='dense'
                  fullWidth
                  label={field}
                  onChange={handleChangeData}
                  name={field}
                  value={registrationData[`${field}`] || ''}
                  validators={['required']}
                  errorMessages={['this field is required']}
                  // validatorListener={validatorListenerData}
                  disabled={submittedData}
                />
              ))}
            </DialogContent>

            <DialogActions className={`footer ${classes.footer}`}>
              <div className={'footer__content'}>
                <div>
                  <Checkbox
                    checked={checkedPrivacy}
                    onChange={handleCheckboxPrivacy}
                    inputProps={{ 'aria-label': 'checkbox' }}
                  />
                  <Typography vairant='body2'>
                    {GDPR_TEXT}
                    <Link href={TERMS_AND_CONDITIONS_LINK_URL} target='_blank'>
                      {TERMS_AND_CONDITIONS_LINK_TEXT}
                    </Link>
                    and{' '}
                    <Link href={PRIVACY_POLICY_LINK_URL} target='_blank'>
                      {PRIVACY_POLICY_LINK_TEXT}
                    </Link>
                  </Typography>
                </div>
                {disclaimer && (
                  <div>
                    <Checkbox
                      checked={checkedDisclaimer}
                      onChange={handleCheckboxDisclaimer}
                      inputProps={{ 'aria-label': 'disclaimer checkbox' }}
                    />
                    <Typography vairant='body2'>
                      I have read and accepted the <span>disclaimer</span> down
                      below
                    </Typography>
                  </div>
                )}
              </div>
              <Button
                variant='contained'
                type='submit'
                disabled={!checkedPrivacy || submittedData}
              >
                {buttonDataText}
              </Button>
            </DialogActions>
            {disclaimer && (
              <DialogContent className='disclaimer-content'>
                <h3>Disclaimer</h3>
                <div dangerouslySetInnerHTML={{ __html: disclaimerText }}></div>
              </DialogContent>
            )}
          </ValidatorForm>
        </Dialog>
      </Container>
      <>{authListResponse.success === true && <>{saveLocalAuthAndGo()}</>}</>
    </>
  );
};

export default React.memo(CheckAgmAuth);
