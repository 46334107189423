import ReactGA from 'react-ga';

export function gaEvent(category, action, label) {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
}
export function gaEvent1(category, action, label,value) {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
    value:value
  });
}
