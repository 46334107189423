import React from 'react';
import VideoPlayer from 'react-video-js-player';

import './index.css';

const HoldingVideo = ({ srcData }) => {
  console.log('src data', srcData)
  return (
    <div className='holding-video-container'>
      <VideoPlayer
        className='holding-video'
        autoplay={'true'}
        // poster='video/posterHoldingVideo.png'
        src={srcData}
        hideControls={['playbackrates', 'fullscreen', 'timer']}
      />
    </div>
  );
};

export default HoldingVideo;
