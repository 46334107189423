import React, { useContext, useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
// import { WEBCASTING_PLATFORM_URL } from '../config/config';

import WebcastingContext from '../context/webcasting/webcastingContext';

const HeadStyle = ({ branding }) => {
  const webcastingContext = useContext(WebcastingContext);
  const { event } = webcastingContext;

  const [eventId, setEventId] = useState('');
  const [eventTitle, setEventTitle] = useState('');
  const [eventDescription, setEventDescription] = useState('');
  const [fontsFounded, setFontsFounded] = useState(false);
  const [fontDefault, setFontDefault] = useState([]);
  const [fontTitle, setFontTitle] = useState([]);
  const [fontDescription, setFontDescription] = useState([]);
  const [cssFormat, setCssFormat] = useState([]);
  const [woffFormat, setWoffFormat] = useState([]);
  const [weHaveFonts, setWeHaveFonts] = useState(false);
  const [ogImageUrl, setOgImageUrl] = useState('');

  function filterFont(arr, key, value) {
    return arr.filter((e) => e[key] === value);
  }

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      if (event.items) {
        const eventData = event.items[0];

        setEventId(eventData._id);
        eventData.title && setEventTitle(eventData.title);
        eventData.description && setEventDescription(eventData.description);

        if (eventData.webcast.branding.fonts.length > 0) {
          setFontsFounded(true);
          let fontsArr = eventData.webcast.branding.fonts;
          
          console.log('All woff format' ,fontsArr)

          setFontDefault(filterFont(fontsArr, 'fontPosition', 'default'));
          setFontTitle(filterFont(fontsArr, 'fontPosition', 'title'));
          setFontDescription(
            filterFont(fontsArr, 'fontPosition', 'description')
          );

          setCssFormat(filterFont(fontsArr, 'fontFormat', 'css'));
          setWoffFormat(filterFont(fontsArr, 'fontFormat', 'woff'));
          setWoffFormat(filterFont(fontsArr, 'fontFormat', 'woff2'));

        }

        if (eventData.players.cover) {
          setOgImageUrl(eventData.players.cover);
        }
      }
    }

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line
  }, []);


  useEffect(() => {
    // if (fontTitle.length > 0) setWeHaveFonts(true);
    // if (fontDefault.length > 0) {
    //   if (fontDefault[0].fontLinkHref !== '') {
    //     setWeHaveFonts(true);
    //   }
    // }
    // if (fontTitle.length > 0) {
    //   if (fontTitle[0].fontLinkHref !== '') {
    //     setWeHaveFonts(true);
    //   }
    // }
    // if (fontDescription.length > 0) {
    //   if (fontDescription[0].fontLinkHref !== '') {
    //     setWeHaveFonts(true);
    //   }
    // }
    setWeHaveFonts(true);
  }, [branding]);

  return (
    <HelmetProvider>
      <Helmet>
        <title>{eventTitle}</title>
        <meta property='og:title' content={eventTitle} />
        <meta property='og:description' content={eventDescription} />
        <meta property='og:type' content='website' />
        <meta property='og:image' content={ogImageUrl} />
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_WEBCASTING_PLATFORM_URL}/broadcast/${eventId}`}
        />
        <meta name='twitter:title' content={eventTitle} />
        <meta name='twitter:description' content={eventDescription} />
        <meta name='twitter:image' content={ogImageUrl} />
        <meta
          name='twitter:url'
          content={`${process.env.REACT_APP_WEBCASTING_PLATFORM_URL}/broadcast/${eventId}`}
        />
        {fontsFounded && (
          <>
            {cssFormat.length > 0 &&
              cssFormat.map((e, idx) => (
                <link
                  key={idx}
                  href={`${e.fontLinkHref}`}
                  rel='stylesheet'
                ></link>
              ))}

            {woffFormat.length > 0 &&
              woffFormat.map((e, idx) => (
                <link
                  crossorigin='anonymous'
                  key={idx}
                  as='font'
                  href={`${e.fontLinkHref}`}
                  rel='preload'
                  // rel='stylesheet preload'
                  type='font/woff'
                />
              ))}
            {woffFormat.length > 0 &&
              woffFormat.map((e, idx) => (
              
                <style key={idx}>
                  {`
                  @font-face {
                    src: url(${e.fontLinkHref}) format(${e.fontFormat});
                    font-family: ${e.fontFamily};
                  `}
                </style>
              ))}

            {weHaveFonts && (
              <style type='text/css'>{`
            .title {
              font-family: ${
                fontTitle[0].fontFamily !== ''
                  ? fontTitle[0].fontFamily
                  : fontDefault[0].fontFamily
              }, sans-serif;
              font-size: ${
                fontTitle[0].fontSizeBase || fontDefault[0].fontSizeBase
              };
              font-weight: ${
                fontTitle[0].fontWeight || fontDefault[0].fontWeight
              };
              color: ${
                fontTitle[0].fontColor !== ''
                  ? fontTitle[0].fontColor
                  : fontDefault[0].fontColor
              };
            }
            .description{
              font-family: ${
                fontDescription[0].fontFamily !== ''
                  ? fontDescription[0].fontFamily
                  : fontDefault[0].fontFamily
              }, sans-serif;
              font-size: ${
                fontDescription[0].fontSizeBase || fontDefault[0].fontSizeBase
              };
              font-weight: ${
                fontDescription[0].fontWeight || fontDefault[0].fontWeight
              };
              color: ${
                fontDescription[0].fontColor !== ''
                  ? fontDescription[0].fontColor
                  : fontDefault[0].fontColor
              };
            }
            `}</style>
            )}
          </>
        )}
      </Helmet>
    </HelmetProvider>
  );
};

export default HeadStyle;
