import React, { useState, useContext, useEffect, useRef } from 'react';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import WebcastingContext from '../../context/webcasting/webcastingContext';

// MUI
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  DialogContent,
  Container,
  Grid,
  Box,
  List,
  ListItem,
  Typography,
  Snackbar,
  Checkbox
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

// HELPERS
import { gaEvent } from '../../helpers/reactGA';

import './index.css';

const useStyles = makeStyles((theme) => ({
  dContent: {
    [theme.breakpoints.down('md')]: {
      padding: '8px 0px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '10px',
    },
  },
  questionBtn: {
    marginTop: '0.7rem',
    color: '#333',
    backgroundColor: '#fff',
    borderColor: '#333',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#333',
      borderColor: '#333',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  inputData: {
    width: '75%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  alert: {
    padding: '0px 24px',
    '& .MuiCollapse-wrapper': {
      justifyContent: 'flex-end',
      '& .MuiCollapse-wrapperInner': {
        width: '50%',
        [theme.breakpoints.down('md')]: {
          width: '100%',
        },
      },
    },
    [theme.breakpoints.down('md')]: {
      padding: '0px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0px 10px',
    },
  },
  closeBtn: {
    left: 'calc(100% - 48px)',
    marginTop: '32px',
  },
}));

function Alert(props) {
  return <MuiAlert elevation={24} {...props} />;
}

const QuestionsDrawer = (props) => {
  let {socket_wc} = props
  const webcastingContext = useContext(WebcastingContext);
  const { setMessage, questionSent, resetQuestionSent, localData, event, authorization } =
    webcastingContext;

  const [formData, setFormData] = useState({});
  const [submitReady, setSubmitReady] = useState(false);
  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState(true);
  const [checked, setChecked] =useState(false)


console.log('Question Drawer', socket_wc)
  const handleChangeAnonymous = (event) => {
    console.log('CHECKEDDDDDDDDD', event.target.checked)
    setChecked(event.target.checked);
  };
  // @setOpen: resetting the state to true with setTimeout, allows to send subsequent messages to the manager
  const [open, setOpen] = useState(
    true,
    setTimeout(() => {
      setOpen(true);
    }, 1000)
  );

  const nameRef = useRef(null);
  const organisationRef = useRef(null);
  const emailRef = useRef(null);

  let question = {};
  question.eventid = props.event._id;
  question.findId = '';
  question.name = '';
  question.organisation = '';
  question.email = '';
  question.status = 'new';
  question.content = '';

  let user = {};
  let isUserRegistered = false;

  let fieldObj = question;

  const userData = () => {
    if (localData.user) {
      if (localData.user.events.length > 0) {
        localData.user.events.forEach((el) => {
          if (el.eventId === props.event._id) {
            user = el.registrant;
            fieldObj.findId = question.findId = el.findId;
            isUserRegistered = true;
          }
        });
      }
    }

    if (user) {
      let keys = Object.keys(user);
      keys.forEach((el) => {
        if (el.toLowerCase() === 'fields') {
          // fieldObj['organisation'] = JSON.parse(user[el]).organisation;
          fieldObj['organisation'] = JSON.parse(user[el]).organisation ? JSON.parse(user[el]).organisation : JSON.parse(user[el]).company;

        }

        fieldObj[el.toLowerCase()] = user[el];
      });
    }
  };
  userData();

  const [questionData, setQuestionData] = useState(fieldObj);

  // >>>>>>>>>>>>>>>> Forms fields handler
  const handleChangeData = (event) => {
    const questionDataKey = event.target.name;
    const questionDataValue = event.target.value;
    setQuestionData({
      ...questionData,
      [`${questionDataKey}`]: questionDataValue,
    });

    if (questionDataValue.length < 2) {
      setDisabledSubmitBtn(true);
    } else {
      setDisabledSubmitBtn(false);
    }

    setSubmitReady(false);
  };

  // >>>>>>>>>>>>>>>> Set form data to useState
  const handleSubmit = (event) => {
    event.preventDefault();
    try {
      let data = {
        eventID:props.event._id,
        type:'new',
        from:'webcast',
        messageContent:questionData?.content
      }
      socket_wc.emit('messageSent',data);  
    } catch (error) {
      console.log('messageSent',error)
    }



    setFormData({
      eventid: props.event._id,
      fields: {...questionData,anonymous: checked, email:authorization && authorization?.userData?.email},
    });
    setQuestionData({ ...questionData, [`content`]: '' });
    setDisabledSubmitBtn(true);
    setSubmitReady(true);
    

    let action = {
      userID:Object.entries(authorization).length > 0 && authorization.registrants[0].findId,
      action: Object.entries(authorization).length > 0 &&  JSON.parse(authorization.registrants[0].fields).email + ' just asked the question',
      eventID:props.event._id,
      type:'QUESTION',
      time:new Date(),
      details:{      
      }
}
      socket_wc.emit('userAction',action);  





    // setOpenSnack(true);
  };

  useEffect(() => {
    const companyRefCurrent = organisationRef.current;
    const emailRefCurrent = emailRef.current;
    const nameRefCurrent = nameRef.current;

    let isMounted = true;

    if (isMounted) {
      const sessionStorageQuestionData =
        JSON.parse(sessionStorage.getItem('wpUserQuestionData')) || {};
        // console.log(sessionStorageQuestionData);
        
      if (Object.keys(sessionStorageQuestionData).length > 0) {
        if (
          sessionStorageQuestionData.organisation !== '' &&
          !isUserRegistered
        ) {
          setQuestionData((prevState) => ({
            ...prevState,
            organisation: sessionStorageQuestionData.organisation,
          }));
        }
        if (sessionStorageQuestionData.email !== '' && !isUserRegistered) {
          setQuestionData((prevState) => ({
            ...prevState,
            email: sessionStorageQuestionData.email,
          }));
        }
        if (sessionStorageQuestionData.name !== '' && !isUserRegistered) {
          setQuestionData((prevState) => ({
            ...prevState,
            name: sessionStorageQuestionData.name,
          }));
        }
      }
    }

    return () => {
      
      sessionStorage.setItem(
        'wpUserQuestionData',
        JSON.stringify({
          organisation: !isUserRegistered ? companyRefCurrent.state.value : '',
          email: !isUserRegistered ? emailRefCurrent.state.value : '',
          name: !isUserRegistered ? nameRefCurrent.state.value : '',
        })
      );

      isMounted = false;
    };
    // eslint-disable-next-line
  }, []);

  // >>>>>>>>>>>>>>>> Set message to context
  useEffect(() => {
    const timer = setTimeout(() => {
      if (submitReady) {
        console.log('sessionStorageQuestionData.email', authorization && authorization.userData)
        setMessage(formData);
        props.onClose(`left`, false);
        gaEvent('submit_question', 'submit', 'submitted question');
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line
  }, [submitReady]);

  // >>>>>>>>>>>>>>>> Material UI handler
  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setQuestionData({ ...questionData, [`content`]: '' });
    setSubmitReady(false);
    resetQuestionSent();
    // setOpenSnack(false);
  };

  const classes = useStyles();

  return (
    <div role='presentation'>
      <Container maxWidth='xl'>
        <Grid item mt={4} xs={12} sm={12} md={12} lg={12} xl={12}>
          <IconButton
            aria-label='close'
            disableRipple={true}
            onClick={props.onClose}
            className={classes.closeBtn}
          >
            <CloseIcon />
          </IconButton>
          <ValidatorForm
            onSubmit={handleSubmit}
            onError={(errors) => console.log(errors)}
          >
            <Box mt={3} className={classes.dContent}>
              <Typography
                variant='h4'
                id='form-dialog-title'
                className='question-title'
              >
                Questions
              </Typography>
              <Typography variant='subtitle1' className='question-subtitle'>
                Please type in your question and click "Submit"
              </Typography>

     

              </Box>
            <DialogContent className={classes.dContent}>
              <Box mt={2} className='input-text'>
                <TextValidator
                  autoFocus
                  margin='dense'
                  id='content'
                  fullWidth
                  label='Your Question'
                  onChange={handleChangeData}
                  name='content'
                  multiline
                  rows={4}
                  value={questionData['content']}
                  validators={['required']}
                  errorMessages={['this field is required']}
                  disabled={false}
                  variant='outlined'
                />
              </Box>
              {isUserRegistered === true ? (
                <>
                  <Box>
                    <Grid item xs={12} style={{ textAlign: 'right' }}>
                      <Button
                        className={classes.questionBtn}
                        variant='outlined'
                        type='submit'
                        disabled={
                          disabledSubmitBtn || questionSent === 'sending'
                        }
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Box>
                </>
              ) : (
                <>
                  <Box mt={2}>
                    <Typography variant='caption' display='block' gutterBottom>
                      The following data will be displayed to the speaker:
                    </Typography>
                    <List dense>
                      <ListItem className={classes.listItem}>
                        <div className={classes.inputData}>
                          <TextValidator
                            ref={nameRef}
                            margin='dense'
                            id='name'
                            fullWidth
                            label='Name'
                            onChange={handleChangeData}
                            name='name'
                            value={questionData['name'] || ''}
                            validators={['required']}
                            errorMessages={['this field is required']}
                            disabled={false}
                            variant='outlined'
                          />
                        </div>
                      </ListItem>
                      <ListItem className={classes.listItem}>
                        <div className={classes.inputData}>
                          <TextValidator
                            ref={organisationRef}
                            margin='dense'
                            id='organisation'
                            fullWidth
                            label='Organisation'
                            onChange={handleChangeData}
                            name='organisation'
                            value={questionData['organisation'] || ''}
                            validators={['required']}
                            errorMessages={['this field is required']}
                            disabled={false}
                            variant='outlined'
                          />
                        </div>
                      </ListItem>
                      <ListItem className={classes.listItem}>
                        <div className={classes.inputData}>
                          <TextValidator
                            ref={emailRef}
                            margin='dense'
                            id='email'
                            fullWidth
                            label='Email'
                            onChange={handleChangeData}
                            name='email'
                            value={questionData['email'] || ''}
                            validators={['required', 'isEmail']}
                            errorMessages={[
                              'this field is required',
                              'please type a valid email',
                            ]}
                            disabled={false}
                            variant='outlined'
                          />
                        </div>
                      </ListItem>
                    </List>
                  </Box>
                  <Box>
                    <Grid item xs={12} style={{ textAlign: 'right' }}>
                      <Button
                        className={classes.questionBtn}
                        variant='outlined'
                        type='submit'
                        disabled={
                          disabledSubmitBtn || questionSent === 'sending'
                        }
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Box>
                </>
              )}
            </DialogContent>
          </ValidatorForm>
          { 
event.items[0].webcast.allowAnonymous &&
         <Box style={{display:'flex', placeContent:'center', placeItems:'center'}}>
         <Checkbox
         defaultChecked={true}
checked={checked}
onChange={handleChangeAnonymous}
color="default"/>
<Typography>Check this box if you would like your question asked anonymously
</Typography>
       </Box>
}
        </Grid>
        {questionSent === 'ok' ? (
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            open={open}
            autoHideDuration={300}
            onClose={() => {
              setOpen(false);
              handleCloseSnack();
            }}
            message='Question Sent'
          >
            <Alert
              severity='success'
              onClose={() => {
                setOpen(false);
                handleCloseSnack();
              }}
            >
              Question Sent
            </Alert>
          </Snackbar>
        ) : questionSent === 'error' ? (
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            open={open}
            autoHideDuration={4000}
            onClose={() => {
              setOpen(false);
              handleCloseSnack();
            }}
          >
            <Alert
              severity='error'
              onClose={() => {
                setOpen(false);
                handleCloseSnack();
              }}
            >
              An error occurred, please try again
            </Alert>
          </Snackbar>
        ) : questionSent === 'sending' ? (
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            open={open}
          >
            <Alert severity='warning'>Sending...</Alert>
          </Snackbar>
        ) : (
          <></>
        )}
      </Container>
    </div>
  );
};

export default React.memo(QuestionsDrawer);
