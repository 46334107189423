import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';
import SocketContainerWithReg from './SoceketIOContainer_WithReg';
import SocketContainerNoReg from './SocketContainerNoReg';

const SocketIOHOC = ({ isEventLive,onDemand, socket_wc,authorization, noRegID,eventID, children }) => {

  const [socketManager, setSocketManager] = useState(null);


  // useEffect(() => {
  //   console.log('REACT_RERENDER manager')
  //   const socket_manager = io('http://localhost:1000/manager'); // Update with your server URL
  //   setSocketManager(socket_manager)

  //   return () => {
  //     console.log('REACT_RERENDER close')
  //     };
  // }, []);


  //Other events

  // useEffect(()=>{

  //         //Event Status -> start


  //         //Event Status -> stop


  //         //ToogleQnaStatus
 

  //         //Listen to Slides


  //         //Listen to Presenter Change


  //         //Liten to Messages
  //         if(socketManager) {

  //           socketManager.emit('joinManagerSocket',eventID);
            
  //           socketManager.on('LiveParticipantRequest', (msg)=>{
  //             console.log('LiveParticipantRequest manager', msg)
  //           });
            
  
  //           //Errors
  //           socketManager.on('disconnect', ()=>{
  //             console.log('Socket disconnected')
  //           });
  //           socketManager.on('error', ()=>{
  //             console.log('Socket Error')
  //           });
  //         }


  // },[socketManager])
  

  if (isEventLive) {
    console.log('SoceketIOContainer_WithReg', socket_wc)
    return (
      <SocketContainerWithReg onDemand={onDemand} socket_wc={socket_wc} isEventLive={isEventLive} authorization={authorization} noRegID={noRegID}>
        {children}
      </SocketContainerWithReg>
    );
  } else {
    return (
      <SocketContainerNoReg onDemand={onDemand} socket_wc={socket_wc} eventID={eventID} isEventLive={isEventLive}  noRegID={noRegID}>
      {children}
    </SocketContainerNoReg>
    );
  }
};

export default SocketIOHOC;
