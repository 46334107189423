import { Typography } from "@material-ui/core";
import React from "react";

const MayfairVideo = ({showLink, componentRef, isMobile})=>{
    console.log("componentRef.currentcomponentRef.currentcomponentRef.current", componentRef.current && (componentRef.current.getBoundingClientRect().bottom))
    return(
        showLink && <div style={{
            position:'absolute',zIndex:999,margin:'1rem', padding:'1rem', textAlign:'center',transition:'1s linear',
          width:isMobile ?componentRef.current && componentRef.current.getBoundingClientRect().width :componentRef.current && componentRef.current.getBoundingClientRect().width,
          top :isMobile ?
          componentRef.current && componentRef.current.getBoundingClientRect().bottom - 130 : 
          componentRef.current && componentRef.current.getBoundingClientRect().bottom - 170 , 
          display:'flex', justifyContent:'center'
          }}>   
              <Typography style={{ fontSize:!isMobile && '2rem'  ,color:'white', background: '#23325c'}}>
                For more information, please
                <a href="https://www.mayfairequity.com/" target="_blank" style={{color:'#f5f6fa', marginLeft:'10px'}}>click here</a> 
              </Typography>
              
          </div>  
    )
}

export default MayfairVideo;