import React, { useState, useEffect } from 'react';

// MUI
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import YouTubeIcon from '@material-ui/icons/YouTube';
import LinkIcon from '@material-ui/icons/Link';

// HELPERS
import { getHostnameFromRegex } from '../../helpers';

// MUI STYLE FUNCTION
const useStyles = makeStyles(() => ({
  socialMediaBtn: {
    textTransform: 'lowercase',
    color: 'var(--clr-g-3)',
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#fff',
      color: (iconColor) => iconColor.color,
      textDecoration: 'none',
    },
  },
  hostName: {
    '& svg': {
      marginRight: '0.35rem',
    },
  },
}));

const PresenterSocialMediaLink = ({ value, type }) => {
  const [socialMediaContent, setSocialMediaContent] = useState(null);
  const [iconColor, setIconColor] = useState({ color: '#333' });
  const [hostName, setHostName] = useState('');
  const [otherMedia, setOtherMedia] = useState(false);

  const selectIcon = (type) => {
    const socialMedia = type.toLowerCase();

    if (socialMedia === 'other' || socialMedia === 'company') {
      setHostName(getHostnameFromRegex(value));
      setOtherMedia(true);
      setSocialMediaContent(<LinkIcon />);
      setIconColor({ ...iconColor, color: '#2a90ef' });
    }
    if (socialMedia === 'linkedin') {
      setSocialMediaContent(<LinkedInIcon />);
      setIconColor({ ...iconColor, color: '#0077B5' });
    }
    if (socialMedia === 'twitter') {
      setSocialMediaContent(<TwitterIcon />);
      setIconColor({ ...iconColor, color: '#1DA1F2' });
    }
    if (socialMedia === 'facebook') {
      setSocialMediaContent(<FacebookIcon />);
      setIconColor({ ...iconColor, color: '#4267B2' });
    }
    if (socialMedia === 'youtube') {
      setSocialMediaContent(<YouTubeIcon />);
      setIconColor({ ...iconColor, color: '#FF0000' });
    }
  };

  useEffect(() => {
    selectIcon(type);
    // eslint-disable-next-line
  }, []);

  const classes = useStyles(iconColor);

  return (
    <Button
      component={Link}
      href={value}
      variant='contained'
      target='_blank'
      rel='noreferrer'
      title={!otherMedia && type}
      className={`${classes.socialMediaBtn} ${
        otherMedia ? classes.hostName : undefined
      }`}
    >
      {socialMediaContent}
      {otherMedia && hostName}
    </Button>
  );
};

export default PresenterSocialMediaLink;
