import React, { useContext, useRef, useEffect, useState } from 'react';
import WebcastingContext from '../../../context/webcasting/webcastingContext';

import MemoizedVideoJs from './MemoizedVideoJs';
import { isMobile } from "react-device-detect";
import { Typography } from '@material-ui/core';
import MayfairVideo from '../../eventSpecific/mayfair';

const VideoJsContainer = ({ media, isOnlyVideo, preview, getHeight }) => {
  const { bcVideoId } = media;
  const webcastingContext = useContext(WebcastingContext);
  const {
    seekToTime,
    getSeekingTime,
    getPlayerCurrentTime,
    hasMetaData,
    hasmetadata,
    playerCurrentTime,
    onDemandFullScreenModeTimings,
    setOnDemandFullScreenMode,
    event
  } = webcastingContext;

  const componentRef = useRef();
  const [showLink, setShowLink] = useState(false)


function manageFullScreen(currentTime, timeRanges) {
  for (const range of timeRanges) {
      if (currentTime >= range.fullScreenON && currentTime < range.fullScreenOFF) {
          console.log("Turning ON fullscreen mode");
          setOnDemandFullScreenMode(true)

          return;
      }
  }
  setOnDemandFullScreenMode(false)

  console.log("Turning OFF fullscreen mode");
}

  useEffect(()=>{
    console.log("Turning playerCurrentTime", playerCurrentTime);
    if(onDemandFullScreenModeTimings && onDemandFullScreenModeTimings.length > 0) {

          manageFullScreen(Math.round(playerCurrentTime), onDemandFullScreenModeTimings);
    }
    if(Math.round(playerCurrentTime) >= 100) {
      setShowLink(true)
    } else {
      setShowLink(false)

    }
    if (componentRef.current) {
      const rect = componentRef.current.getBoundingClientRect();
      console.log('Interactive Top:', 'rect.bottom', rect.bottom , 'rect.top', rect.top)
    }



  },[playerCurrentTime])



  // Read component height when component mount
  useEffect(() => {
    let count = 0;
    if (hasmetadata && count === 0 && isOnlyVideo) {
      const { height } = componentRef.current.getBoundingClientRect();
      getHeight(height);

      count++;
    }
  }, [hasmetadata, getHeight, isOnlyVideo]);

  // Read component height when win resize
  useEffect(() => {
    if (hasmetadata && isOnlyVideo) {
      const onWinResize = () => {
        const { height } = componentRef.current.getBoundingClientRect();
        getHeight(height);
      };
      window.addEventListener('resize', onWinResize);
    }
  }, [hasmetadata, getHeight, isOnlyVideo]);

  return (
  <>


    <div ref={componentRef} className='videoWrapper'>
    {

event.items[0]._id === '64feec4284cbf5eec802ddb3' && (
  <MayfairVideo  showLink={showLink} componentRef={componentRef} isMobile={isMobile} />


)
}

      <MemoizedVideoJs  
        bcVideoId={bcVideoId}
        seekToTime={seekToTime}
        getSeekingTime={getSeekingTime}
        getPlayerCurrentTime={getPlayerCurrentTime}
        hasMetaData={hasMetaData}
        preview={preview}
        getHeight={getHeight}
      />
    </div>
    </>
  );
};

export default VideoJsContainer;
