import React from "react";
import { Route, Switch } from "react-router-dom";

import MainLayout from "../layout/index";
import NotFound from "../components/UI/NotFound";
import CheckYourEmail from "../components/UI/CheckYourEmail";

const BroadcastRouter = ({ match }) => {
  return (
    <Switch>
      <Route
        exact
        path={`${match.path}/:eventId`}
        render={(match) => <MainLayout {...match} match={match} />}
      />
      <Route
        exact
        path={`${match.path}/:eventId/:confirmationId`}
        render={(match) => <MainLayout {...match} match={match} />}
      />

      <Route
        exact
        path={`${match.path}/:eventId?template=clean&controlbar=no&reg=off&ispresenter=off`}
        render={(match) => <MainLayout {...match} match={match} />}
      />



      <Route component={NotFound} />
    </Switch>
  );
};

export default BroadcastRouter;
