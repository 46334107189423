import { useState, useEffect, useRef } from 'react';

function useQnaStatus(QnaStatus, event) {

  console.log('EVENT', event)
  const [componentQnaStatus, setComponentQnaStatus] = useState(QnaStatus);
  const [componentSnackBarSlide, setComponentSnackBarSlide] = useState(false);

  const prevQnaStatus = usePrevious(QnaStatus);

  useEffect(() => {
    setComponentQnaStatus(QnaStatus);
  }, [QnaStatus]);

  useEffect(() => {
    if (prevQnaStatus !== undefined) {
      if (prevQnaStatus === true && QnaStatus === false) {
        if (event.isLive) {
          setTimeout(() => {
            setComponentSnackBarSlide(false);
            console.log('componentQnaStatus true-false');
          }, 2000);
        }
      } else if (prevQnaStatus === false && QnaStatus === true) {
        setComponentSnackBarSlide(false);
        console.log('componentQnaStatus false-true');
      }
    }  else if(QnaStatus) {
      setComponentSnackBarSlide(false);

    }
  }, [QnaStatus, prevQnaStatus]);

  useEffect(() => {
    function handleUserInteraction() {
      if(QnaStatus) {
        setComponentSnackBarSlide(false);
  
      } else {
        
        setComponentSnackBarSlide(true);
      }
      console.log('User interacted with the page');
    }

    window.addEventListener('mousemove', handleUserInteraction);

    return () => {
      window.removeEventListener('mousemove', handleUserInteraction);
    };
  }, []);

  // useEffect(() => {
  //   const hasLoadedBefore = localStorage.getItem('hasLoadedBefore');
  //   if (!hasLoadedBefore) {
  //     setComponentSnackBarSlide(true);
  //     localStorage.setItem('hasLoadedBefore', 'true');
  //   }
  // }, []);

  // useEffect(() => {
  //   const eventKey = `eventLoaded-${event._id}`;
  //   const hasLoadedBefore = localStorage.getItem(eventKey);

  //   if (!hasLoadedBefore) {
      
  //     setComponentSnackBarSlide(true);
  //     localStorage.setItem(eventKey, 'true');
  //   }
  // }, [event._id]);


  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  return { componentQnaStatus, componentSnackBarSlide };
}

export default useQnaStatus;
