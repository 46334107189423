import React from 'react';
import './NotFound.css';

const NotFound = ({ msg, statusCode = '404' }) => {
  return (
    <div className={statusCode === '403' ? 'main403' : 'main404'}>
      <div className='main404upper'>
        <div className='main404upper2'>
          <h1 className='main404h1'>{statusCode}</h1>
          {statusCode === '403' ? (
            <h2 className='main404h2'>
              Sorry you not have access rights to the content
            </h2>
          ) : (
            <h2 className='main404h2'>
              We are sorry, we couldn't find your webcast
            </h2>
          )}
          <div>{msg ? <h3 className='main404h3'>{msg}</h3> : <></>}</div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(NotFound);
