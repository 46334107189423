import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import MuiAlert from '@material-ui/lab/Alert';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formRoot: {
    padding: '0.5rem',
  },
  input: {
    '& ::before': {
      borderBottom: '2px solid #b3b3b3',
    },
    '& ::after': {
      borderBottom: '2px solid #cccccc !important',
    },
    '& :hover::before': {
      borderBottom: '2px solid #7e7e7e !important',
    },
  },
  footerOnlyBtn: {
    justifyContent: 'flex-end',
    padding: '6px 24px 12px',
    '& button': {
      color: '#333',
      backgroundColor: '#fff',
      '&:hover': {
        color: '#fff',
        backgroundColor: '#333',
      },
    },
  },
}));

const PasswordForm = ({ setPasswordIsCorrect, eventPassword }) => {
  const [password, setPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [disablePasswordBtn, setDisablePasswordBtn] = useState(true);
  const [buttonPasswordText, setButtonPasswordText] = useState('Submit');
  const [openDialog, setOpenDialog] = useState(true);
  const [, setOpenSnack] = useState(false);
  const [showPsw, setShowPsw] = useState(false);

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpenDialog(false);
    }
  };

  const handleChangePassword = (event) => {
    const password = event.target.value;
    setPassword(password);

    password.length < 1
      ? setDisablePasswordBtn(true)
      : setDisablePasswordBtn(false);
  };

  const handleSubmitPassword = (event) => {
    // event.preventDefault()
    let passwordMatch = false;

    if (password !== eventPassword) {
      setErrorMsg('The given password is not allowed');
      setDisablePasswordBtn(true);
    } else {
      passwordMatch = true;
    }

    if (passwordMatch) {
      setButtonPasswordText('sending...');
      setPasswordIsCorrect(true);
      setOpenDialog(false);
    }
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
  }

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    if (errorMsg !== '') {
      setErrorMsg('');
      setPassword('');
      setOpenSnack(false);
      setButtonPasswordText('Submit');
    }

    setOpenSnack(false);
  };

  const handleClickShowPassword = () => {
    setShowPsw(!showPsw);
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  const classes = useStyles();

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      aria-labelledby='form-dialog-title'
      disableEscapeKeyDown
    >
      <ValidatorForm
        onSubmit={handleSubmitPassword}
        onError={(errors) => console.log(errors)}
        className={classes.formRoot}
      >
        {errorMsg !== '' && (
          <Alert severity='error' onClose={handleCloseSnack}>
            {errorMsg}
          </Alert>
        )}
        <DialogContent>
          <DialogContentText>
            Please enter the password to access this event
          </DialogContentText>
          <TextValidator
            className={classes.input}
            label='Password'
            onChange={handleChangePassword}
            name='password'
            type={showPsw ? 'text' : 'password'}
            validators={['required']}
            errorMessages={['this field is required']}
            value={password}
            disabled={errorMsg !== ''}
            autoFocus
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPsw ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
        <DialogActions className={`footer ${classes.footerOnlyBtn}`}>
          <Button
            type='submit'
            variant='contained'
            disabled={disablePasswordBtn}
          >
            {buttonPasswordText}
          </Button>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  );
};

export default React.memo(PasswordForm);
