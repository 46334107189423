import React from 'react';
import {
  CssBaseline,
  Container,
  Grid,
  Typography,
  Link,
} from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ListItemText from '@material-ui/core/ListItemText';
import './CookiePrivacyPolicyPage.css';

const PrivacyPolicyPage = () => {
  return (
    <Container maxWidth='md' className='policy-container'>
      <CssBaseline />
      <Grid container spacing={4}>
        <Grid item className='policy-title'>
          <Typography variant='h4'>Privacy Policy</Typography>
        </Grid>
        <Grid item>
          <Typography paragraph={true}>
            At BRR Media, we are committed to protecting your privacy. This
            policy details the information we gather from you, how we may use or
            disclose that information, and our efforts to protect it.
          </Typography>
          <Typography paragraph={true}>
            Please note this application has hyperlinks that connect you to
            other websites run by third parties and BRR Media is not responsible
            for any information contained within those websites.
          </Typography>
          <Typography variant='h6'>
            The types of personal data that we collect and hold
          </Typography>
          <Typography paragraph={true}>
            Our servers will automatically collect your IP address or domain
            name, along with information about which pages you visit when you
            visit. We use this information to perform our normal business
            activities including the provision of post broadcast reporting to
            clients who host the broadcast and to assist us to respond to any
            complaints and/or issues you may have with our site. In connection
            with your use of our application, we may ask for and collect a
            variety of personal data from and about you. For example, when you
            register to view a webcast, we may ask for and store your name,
            organisation and email address, on behalf of the client or company
            who we have produced the content for.
          </Typography>
          <Typography variant='h6'>For how long do we keep it?</Typography>
          <Typography paragraph={true}>
            We retain the personal data processed by us in a live environment
            for as long as is considered necessary for the purpose(s) for which
            it was collected (including as required by applicable law or
            regulation, typically 6 years). We may keep data for longer to
            establish, exercise, or defend our legal rights.
          </Typography>
          <Typography paragraph={true}>
            In addition, personal data may be securely archived with restricted
            access and other appropriate safeguards where there is a need to
            continue to retain it.
          </Typography>
          <Typography variant='h6'>
            Cookies and Other Tracking Technologies
          </Typography>
          <Typography paragraph={true}>
            The use of cookies is an industry-standard and BRR Media's web
            application utilise cookies and other tracking technologies to
            deliver some of the features of our web broadcasts technology. A
            “cookie” is a small text file that may be used, for example, to
            collect information about Web site activity. Some cookies and other
            technologies may serve to recall personal data previously indicated
            by a web user. Most browsers allow you to control cookies, including
            whether to accept them and how to remove them.
          </Typography>
          <Typography paragraph={true}>
            You may set most browsers to notify you if you receive a cookie, or
            you may choose to block cookies with your browser. If you set your
            browser to refuse cookies you may not be able to take full advantage
            of the functionality of our services.
          </Typography>
          <Typography variant='h6'>
            Integrity and Security of your Information
          </Typography>
          <Typography paragraph={true}>
            Personal data that is collected is protected and stored on servers
            not available to the public and we will use all reasonable effort to
            ensure the privacy of that information.
          </Typography>
          <Typography variant='h6'>
            Disclosure to third parties and overseas
          </Typography>
          <Typography paragraph={true}>
            Save for registration details collected on behalf of companies whose
            webcasts we produce, BRR Media will not provide your personal data
            to other companies or individuals who are not related to BRR Media
            without your express consent. If you wish to stop informational
            e-mails from us, you do so by emailing us at{' '}
            <Link href='mailto:info@brrmedia.co.uk'>info@brrmedia.co.uk</Link>
          </Typography>
          <Typography paragraph={true}>
            We may occasionally hire other companies to provide services on our
            behalf. Those companies will be permitted to obtain only the
            personal data they need to deliver the service. BRR Media takes
            reasonable steps to ensure that these organisations are bound by
            confidentiality and privacy obligations in relation to the
            protection of your personal data.
          </Typography>
          <Typography paragraph={true}>
            Where possible, personal data resides within the UK territory but
            may be transferred to, and stored at, a destination outside the
            European Economic Area (EEA). It may also be processed by staff
            operating outside the EEA who work for us or for one of our
            suppliers. We will take all reasonable steps to ensure that your
            data is treated securely, in accordance with this privacy statement.
          </Typography>
          <Typography paragraph={true}>
            We have taken steps to ensure all personal data is provided with
            adequate protection and that all transfers of personal data outside
            the EU are done lawfully. Where we transfer personal data outside of
            the EU to a country not determined by the European Commission as
            providing an adequate level of protection for personal data, the
            transfers will be under an agreement which covers the EU
            requirements for the transfer of personal data outside the EU.
          </Typography>
          <Typography>
            Examples of Services provided by Third Parties on our behalf:
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon />
              </ListItemIcon>
              <ListItemText primary='IT technical support and development' />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon />
              </ListItemIcon>
              <ListItemText primary='Data hosting and streaming servers' />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon />
              </ListItemIcon>
              <ListItemText primary='Editorial and production services' />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon />
              </ListItemIcon>
              <ListItemText primary='Office administrative support' />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon />
              </ListItemIcon>
              <ListItemText primary='Production venue and equipment hire' />
            </ListItem>
          </List>
          <Typography variant='h6'>Your rights</Typography>
          <Typography>
            Individuals have certain rights over their personal data and data
            controllers are responsible for fulfilling these rights as follows:
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon />
              </ListItemIcon>
              <ListItemText primary='Individuals may request access to their personal data held by us as a data controller. ' />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon />
              </ListItemIcon>
              <ListItemText primary='Individuals may request us to rectify personal data submitted to us or, where appropriate, contact us via the relevant website registration page or by amending the personal details held on relevant applications with which they registered.' />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon />
              </ListItemIcon>
              <ListItemText primary='Individuals may request that we erase their personal data.' />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon />
              </ListItemIcon>
              <ListItemText primary='Where we process personal data based on consent, individuals may withdraw their consent at any time by contacting us or clicking on the unsubscribe link in an email received from us.' />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon />
              </ListItemIcon>
              <ListItemText primary='Individuals may have other rights to restrict or object to our processing of personal data and the right to data portability.' />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecordIcon />
              </ListItemIcon>
              <ListItemText primary='Individuals may request information about, or human intervention into, any automated data processing that we may undertake.' />
            </ListItem>
          </List>
          <Typography paragraph={true}>
            If you wish to exercise any of these rights, please send an email to{' '}
            <Link href='mailto:info@brrmedia.co.uk'>info@brrmedia.co.uk</Link>
          </Typography>
          <Typography variant='h6'>Complaints</Typography>
          <Typography paragraph={true}>
            We hope that you won’t ever need to, but if you do want to complain
            about our use of personal data, please send an email with the
            details of your complaint to{' '}
            <Link href='mailto:info@brrmedia.co.uk'>info@brrmedia.co.uk</Link>
          </Typography>
          <Typography paragraph={true}>
            We will investigate and respond to any privacy-related complaints we
            receive.
          </Typography>
          <Typography paragraph={true}>
            You also have the right to lodge a complaint with the UK data
            protection regulator, the Information Commissioner's Office (“ICO”).
            For further information on your rights and how to complain to the
            ICO, please refer to the ICO website{' '}
            <Link
              href='https://ico.org.uk/concerns'
              target='_blank'
              rel='noopener'
            >
              https://ico.org.uk/concerns
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default React.memo(PrivacyPolicyPage);
