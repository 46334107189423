import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
// import { ASSETS_BASE_URL } from '../../config/config';
import './BackgroundImg.css';

const BackgroundImg = (props) => {
  return (
    <div>
      {props.img ? (
        <div id='bg'>
          <LazyLoadImage
            className='bg bg-img'
            alt='Background Image'
            src={`${process.env.REACT_APP_ASSETS_BASE_URL}/${props.path}`}
            effect='blur'
          />
        </div>
      ) : (
        <div
          id='bg'
          style={{
            backgroundColor: props.colour,
          }}
          className='bg'
        ></div>
      )}
    </div>
  );
};

export default React.memo(BackgroundImg);
