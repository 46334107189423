import { useState, useEffect } from 'react';
// import { ASSETS_BASE_URL } from '../config/config';

export default function useLogo(loading, event) {
  const [logoPadding, setLogoPadding] = useState('12px');
  const [logoBackground, setLogoBackground] = useState('#ffffff');
  const [logoPath, setLogoPath] = useState();
  const [logoArr, setLogoArr] = useState([]);

  useEffect(() => {
    if (loading || event.length === 0) return;
    if (event.items[0].companies[0]) {
      event.items[0].companies[0].logos.forEach((item) => {
        if (item.type === 'website') {
          // console.log('LOGOS', item);
          setLogoArr((logoArr) => [...logoArr, item]);
          // return setLogoPath(`${process.env.REACT_APP_ASSETS_BASE_URL}/${item.upload.path}`);
        }
      });
    }

    if (event.items[0].webcast.branding) {
      if (event.items[0].webcast.branding.logo) {
        if (event.items[0].webcast.branding.logo.padding !== '') {
          const logo = event.items[0].webcast.branding.logo;
          setLogoPadding(logo.padding);
        }
      }
    }

    if (event.items[0].webcast) {
      if (event.items[0].webcast.colour) {
        if (event.items[0].webcast.colour.background) {
          setLogoBackground(event.items[0].webcast.colour.background);
        }
      }
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (logoArr.length > 0) {
      setLogoPath(`${process.env.REACT_APP_ASSETS_BASE_URL}/${logoArr[0].upload.path}`);
    }
  }, [logoArr]);

  return [logoPath, logoPadding, logoBackground];
}
