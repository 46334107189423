export const GET_EVENT = 'GET_EVENT';
export const GET_AUTHORIZATION = 'GET_AUTHORIZATION';
export const GET_CHECK_AUTHLIST = 'GET_CHECK_AUTHLIST';
export const AUTHLIST_RESPONSE = 'AUTHLIST_RESPONSE';
export const SET_REGISTRANT = 'SET_REGISTRANT';
export const SET_QUESTIONSENT = 'SET_QUESTIONSENT';
export const SET_NOTESENT = 'SET_NOTESENT';
export const RESET_NOTESENT_VALUE = 'RESET_NOTESENT_VALUE';
export const GET_LOGO = 'GET_LOGO';
export const SET_LOADING = 'SET_LOADING';
export const EVENT_ERROR = 'EVENT_ERROR';
export const SAVE_LOCAL_DATA = 'SAVE_LOCAL_DATA';
export const SAVE_USER_DATA = 'SAVE_USER_DATA';
export const READ_LOCAL_DATA = 'READ_LOCAL_DATA';
export const READ_USER_DATA = 'READ_USER_DATA';
export const CURRENT_THUMB = 'CURRENT_THUMB';
export const CURRENT_PRESENTER = 'CURRENT_PRESENTER';
export const SEEKTO_TIME = 'SEEKTO_TIME';
export const GET_PLAYER_CURRENT_TIME = 'GET_PLAYER_CURRENT_TIME';
export const GET_PROGRESS_BAR_CURRENT_TIME = 'GET_PROGRESS_BAR_CURRENT_TIME';
export const WEBSOCKET_CLIENT = 'WEBSOCKET_CLIENT';
export const LIVE_STATUS = 'LIVE_STATUS';
export const LIVE_LAYOUT = 'LIVE_LAYOUT';
export const GET_CHAPTERS = 'GET_CHAPTERS';
export const GET_MESSAGE_TO_ALL = 'GET_MESSAGE_TO_ALL';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const AUTHLIST_COMPLETED = 'AUTHLIST_COMPLETED';
export const SET_AUTHLIST = 'SET_AUTHLIST';
export const HASMETADATA = 'HASMETADATA';
export const SET_AUDIO_PIP = 'SET_AUDIO_PIP';
export const GET_USER_REGISTRATION = 'GET_USER_REGISTRATION';
export const USER_IP = 'USER_IP';
export const GET_USER_IP_PERMISSION = 'GET_USER_IP_PERMISSION';
export const UPDATE_LOGIN_TIME = 'UPDATE_LOGIN_TIME';
export const GET_USER_IP = 'GET_USER_IP'
export const USER_VERIFIED = 'USER_VERIFIED'
export const SET_NOREG_ID = 'SET_NOREG_ID'

export const QUERY_SENT = 'QUERY_SENT'

export const QUERY_SENT_FAILD = 'QUERY_SENT_FAILD'


export const EVENT_PASSWORD_VERIFIED = 'EVENT_PASSWORD_VERIFIED'
export const SET_USER_START_TIME = 'SET_USER_START_TIME';
// BRANDING - BOOL 


export const SET_BRANDING = 'SET_BRANDING';
export const SET_FONT_STYLING = 'SET_FONT_STYLING';

//Qna Stats
export const QNA_STATUS = 'QNA_STATUS'
export const FULL_SCREEN_MODE_ON_DEMAND = 'FULL_SCREEN_MODE_ON_DEMAND'

//GA
export const SET_GA = 'SET_GA'