var {isMobile} = require('react-device-detect')
var _moment = require("moment");

var _moment2 = _interopRequireDefault(_moment);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

export function getCurrentIndex(time, array) {
  let index = 0;
  array.forEach((el, idx) => {
    if (el.time <= time) {
      index = idx;
    }
  });

  return index;
}

export function checkLayout(a, b) {
  return JSON.stringify(a.sort()) === JSON.stringify(b.sort());
}

//@ Date Manipulation
const pluralRules = new Intl.PluralRules('en-US', {
  type: 'ordinal',
});

const suffixes = {
  one: 'st',
  two: 'nd',
  few: 'rd',
  other: 'th',
};

const convertToOrdinal = (number) =>
  `${number}${suffixes[pluralRules.select(number)]}`;

const extractValueAndCustomizeDayOfMonth = (part) => {
  if (part.type === 'day') {
    return convertToOrdinal(part.value);
  }
  return part.value;
};

const longEnUSFormatter = new Intl.DateTimeFormat(undefined, {
  weekday: 'long',
  day: 'numeric',
  month: 'long',
  year: 'numeric',
});

export const getFullDate = (date) => {
  const releaseTime = new Date(date);
  const newDate = new Date(
    releaseTime.toISOString().split('T')[0].split('-').join(',')
  );

  return longEnUSFormatter
    .formatToParts(newDate)
    .map(extractValueAndCustomizeDayOfMonth)
    .join('')
    .split(',')
    .join(' ');
};

export const getTime = (date) => {
  const newDate = new Date(date).toLocaleString(undefined, {
    timeZone: 'Europe/London',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  });

  let time = newDate.split(' ')[0];
  if (time.charAt(0) === '0') {
    time = time.replace('0', '12');
  }

  return {
    time: time,
    suffix: newDate.split(' ')[1],
  };
};

export const getBSTorGMT = (date) => {
  const startOfBST = new Date('2022-03-27T01:00:00.000Z').getTime();
  const endOfBST = new Date('2022-10-30T01:00:00.000Z').getTime();
  const release = new Date(date).getTime();

  if (release > startOfBST && release < endOfBST) {
    return 'BST';
  } else {
    return 'GMT';
  }
};

export const styleReleaseDate = (releaseDate, dateFormat) => {
  const londonTime = getTime(dateFormat);

  const regChar = new RegExp(/(-|,)/g);

  const dayWithComma = `${releaseDate.replaceAll(regChar, '').split(' ')[0]},`;

  let onlyDate = releaseDate
    .replaceAll(regChar, '')
    .split(' ')
    .slice(1, -2)
    .join(' ');

  const onlyDateWithComma = `${dayWithComma} ${onlyDate}`;

  if (releaseDate.includes('/')) {
    onlyDate = releaseDate.split(' ').slice(0, -3).join(' ');
  }

  return {
    onlyDate: onlyDateWithComma,
    hrsMins: londonTime.time,
    suffix: londonTime.suffix,
  };
};

const requestOptions = {
  method: 'GET',
};

export const onDownload = (url, name) => {
  fetch(url, requestOptions)
    .then((res) => {
      return res.blob();
    })
    .then((blob) => {
      const href = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((err) => {
      console.log('Something went wrong', err);
      return Promise.reject({ Error: 'Something Went Wrong', err });
    });
};

export const getHostnameFromRegex = (url) => {
  const matches = url.match(/^https?:\/\/([^/?#]+)(?:[/?#]|$)/i);
  return matches && matches[1];
};

export const titleCase = (str) => {
  return str
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};


export const handleGenerateCalendarLink = (type,startTime, endTime, location, title, description) =>{
  const formattedDescription = description.replace(/\n/g, "\\n");

    if(type === 'apple' || type === 'outlook') {
      var calendarUrl = "";
      calendarUrl = ["BEGIN:VCALENDAR", "VERSION:2.0", "BEGIN:VEVENT", "URL:" + document.URL, "DTSTART:" + formatTime(startTime), "DTEND:" + formatTime(endTime), "SUMMARY:" + title, "DESCRIPTION:" + formattedDescription, "LOCATION:" + "", "END:VEVENT", "END:VCALENDAR"].join("\n");
        calendarUrl = encodeURI("data:text/calendar;charset=utf8," + calendarUrl);
        console.log('Calender Link', calendarUrl)
        window.open(calendarUrl, "_blank", "noreferrer");
    } else if(type === 'google') {
      var calendarUrl = "";

      console.log('Start time', startTime, 'End time', endTime )
      calendarUrl = "https://calendar.google.com/calendar/render";
      calendarUrl += "?action=TEMPLATE";
      calendarUrl += "&dates=" + formatTime(startTime);
      calendarUrl += "/" + formatTime(endTime);
      calendarUrl += "&location=" + encodeURIComponent(location);
      
      calendarUrl += "&text=" + encodeURIComponent(title);
      calendarUrl += "&details=" + encodeURIComponent(description); 

      console.log('Calender Link', calendarUrl)
      window.open(calendarUrl, "_blank", "noreferrer");

    } 
}


export const handleCustomCalendarLink_pepco = ()=>{
  let calendarUrl = 'https://outlook.live.com/calendar/0/action/compose?allday=false&body=Please%20join%20Stephan%20Borchert%2C%20Pepco%20Group%20CEO%2C%20and%20other%20leaders%20for%20a%20Group%20Townhall.&enddt=2024-12-11T10%3A00%3A00&location=&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=2024-12-11T09%3A00%3A00&subject=Pepco%20group%20-%20Townhall'
  window.open(calendarUrl, "_blank", "noreferrer");
}

export const handleGenerateZoomCalendarLink = (type,startTime, endTime, location, title, description) =>{
  const formattedDescription = description.replace(/\n/g, "\\n");

    if(type === 'apple' || type === 'outlook') {
      var calendarUrl = "";
      calendarUrl = ["BEGIN:VCALENDAR", "VERSION:2.0", "BEGIN:VEVENT", "DTSTART:" + formatTime(startTime), "DTEND:" + formatTime(endTime), "SUMMARY:" + title, "DESCRIPTION:" + formattedDescription, "LOCATION:" + "", "END:VEVENT", "END:VCALENDAR"].join("\n");
        calendarUrl = encodeURI("data:text/calendar;charset=utf8," + calendarUrl);
        console.log('Calender Link', calendarUrl)
        window.open(calendarUrl, "_blank", "noreferrer");
    } else if(type === 'google') {
      var calendarUrl = "";

      console.log('Start time', startTime, 'End time', endTime )
      calendarUrl = "https://calendar.google.com/calendar/render";
      calendarUrl += "?action=TEMPLATE";
      calendarUrl += "&dates=" + formatTime(startTime);
      calendarUrl += "/" + formatTime(endTime);
      calendarUrl += "&location=" + encodeURIComponent(location);
      
      calendarUrl += "&text=" + encodeURIComponent(title);
      calendarUrl += "&details=" + encodeURIComponent(description); 

      console.log('Calender Link', calendarUrl)
      window.open(calendarUrl, "_blank", "noreferrer");

    } 
}


function formatTime(date) {
  var formattedDate = _moment2.default.utc(date).format("YYYYMMDDTHHmmssZ");
  return formattedDate.replace("+00:00", "Z");
}

function getRandomKey() {
  var n = Math.floor(Math.random() * 999999999999).toString();
  return new Date().getTime().toString() + "_" + n;
}