import React from 'react';
import WebcastingState from './context/webcasting/webcastingState.js';
import MainRouter from './router/index.jsx';
// import ReactGA from 'react-ga';
// import { useLocation } from 'react-router-dom';

import usePageTracking from './usePageTracking';
import GA from './components/GA.jsx';
// let debugging = true;
let debugging =false;

if (!debugging) {
  window.console.log = () => null;
  window.console.warn = () => null;
  window.console.info = () => null;
  window.console.error = () => null;
}
const App = () => {
  // usePageTracking();
  // const location = useLocation();

  // useEffect(() => {
  //   console.log('>>>>>>>>>>> LOCATION', location);
  //   ReactGA.pageview(location.pathname + location.search);
  // }, [location]);

  return (
    <WebcastingState>
      <GA/>
      <MainRouter />
    </WebcastingState>
  );
};

export default App;
