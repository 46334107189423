import React from 'react';
import { CssBaseline, Container, Grid, Typography } from '@material-ui/core';
import './CookiePrivacyPolicyPage.css';

const CookiePolicyPage = () => {
  return (
    <Container maxWidth='md' className='policy-container'>
      <CssBaseline />
      <Grid container spacing={4}>
        <Grid item className='policy-title'>
          <Typography variant='h4'>Cookies</Typography>
        </Grid>
        <Grid item>
          <Typography variant='h6'>What are cookies?</Typography>
          <Typography paragraph={true}>
            Cookies are very small, completely safe and secure text files which
            are downloaded to your computer or mobile device when you visit a
            website or application. Your web browser (e.g. Safari, Internet
            Explorer, Edge, Google Chrome or Mozilla Firefox) then sends these
            files back to the website or application every time you return so
            that your user personal settings can be recognised. Cookies are
            useful, helping website developers and companies to improve your
            website browsing experience. Some cookies are strictly necessary to
            enable you to move around the site or to provide certain basic
            features. Others are used to monitor and improve our website’s
            functionality and performance.
          </Typography>
          <Typography variant='h6'>How we use cookies</Typography>
          <Typography paragraph={true}>
            BRR Media continually strives to provide you with the best customer
            experience possible when visiting our website. To help us do this,
            we use cookies to remember your settings during your and between
            visits, allow interaction between our website and social networks
            like Twitter, collect anonymous data about our users and the way
            they interact with our website in order for us to provide relevant
            and functional content and to continually improve our website to
            enhance the customer experience. br. We do not sell or share any
            information collected by cookies, nor do we disclose the information
            to third parties, except where required by law (for example to
            government bodies and law enforcement agencies).
          </Typography>
          <Typography variant='h6'>
            What cookies we use on our website
          </Typography>
          <Typography paragraph={true}>
            Anonymous Analytics Cookies: Analytics provide us with key
            information about anonymous user behaviour. Whenever a user visits
            our website, web analytics software provided by a third party
            generates an anonymous analytics cookie. These help us find out
            whether or not you have visited the BRR Media website before, how
            many individual unique users visit the site, what pages they look at
            most frequently etc. Social Website Cookies: The BRR Media website
            allows you to share content on social media networks like Twitter.
            This functionality is enabled via the use of third party cookies. In
            this case, privacy implications vary and depend on the privacy
            settings you have chosen when registering with the social media
            sites. Our site enables sharing through Facebook, Twitter and Share
            This.Session Cookies:Session cookies are used for users’ movement
            through the website. They allow you to proceed through many pages of
            the BRR Media site quickly and easily without having to authenticate
            or reprocess each new area you visit. Session cookies are not stored
            on your computer and they will expire when you close your web
            browser.
          </Typography>
          <Typography variant='h6'>How you can control cookies</Typography>
          <Typography paragraph={true}>
            We assume your consent to the use of cookies when you visit the BRR
            Media website and most browsers are automatically set to accept
            them. You can alter the settings of your browser to prevent
            automatic acceptance of cookies, or delete cookies that have already
            been set. This will only slightly affect your user experience as you
            may still utilize most of the sections on our website. If you wish
            to restrict or block web browser cookies on your device, you can do
            this in your browser settings.
          </Typography>
          <Typography variant='subtitle2'>
            Last changed on 7 October 2021
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default React.memo(CookiePolicyPage);
