import React, { useContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import VideoJsContainer from './VideoJsContainer';
import WowzaPlayerContainer from './WowzaPlayerContainer';
import AntPlayer from './AntPlayer';
import './index.css';

// HELPERS
// import { getCurrentIndex } from '../../../helpers';

import WebcastingContext from '../../../context/webcasting/webcastingContext';
import PresenterItem from '../../presenter/PresenterItem';
import PresenterItemSmall from '../../presenter/PresenterItemSmall';

const VideoPlayer = ({
  media,
  isLive,
  title,
  preview,
  isOnlyVideo,
  getHeight,
  ispresenter,
  qnaMode

}) => {
  const webcastingContext = useContext(WebcastingContext);
  const { event, seekToTime, currentPresenter, playerCurrentTime } =
    webcastingContext;

  const activePresenterFromAPI = event.items[0].webcast.live.active.presenter;

  let presenters;
  let presentersTime;
  // let currentPresenter2;
  // let checkTime = true;
  let liveCurrentPresenter = [];
  const [activePresenter, setActivePresenter] = useState(0);

  if (event.items[0]) {
    presenters = event.items[0].presenters;
    presentersTime = event.items[0].webcast.presenterTimes;
  }



  // if (event.items[0].webcast.live.active.presenter) {
  //   presenters.forEach((p) => {
  //     if (p._id === event.items[0].webcast.live.active.presenter) {
  //       // console.log(
  //       //   'ACTIVE PRESENTER FOUND: ',
  //       //   event.items[0].webcast.live.active.presenter
  //       // );
  //       currentPresenter2 = [];
  //       currentPresenter2[0] = p;
  //     }
  //   });
  // }

  // if (presentersTime.length === 0) {
  //   currentPresenter2 = [presenters[0]];
  // } else {
  //   presentersTime.forEach((pTime) => {
  //     if (checkTime && pTime.time > (seekToTime || playerCurrentTime)) {
  //       checkTime = false;
  //     }
  //     if (checkTime) {
  //       currentPresenter2 = presenters.filter(
  //         (p) => p._id === pTime.presenterId
  //       );
  //     }
  //   });
  // }
  function getCurrentPresenter(time, array, pres) {
    let index = 0;
    array.forEach((el, idx) => {
      if (el.time <= time) {
        index = idx;
      }
    });

    return pres.filter((e) => e._id === array[index].presenterId);
  }

  useEffect(() => {
    let t = playerCurrentTime || seekToTime;

    if (!isLive) {
      if (presentersTime.length === 0) {
        setActivePresenter([presenters[0]]);
      } else {
        setActivePresenter(getCurrentPresenter(t, presentersTime, presenters));
      }
    }

    // eslint-disable-next-line
  }, [playerCurrentTime, seekToTime]);

  // Next IF statements set current presenter for live event
  if (
    Object.keys(currentPresenter).length === 0 &&
    activePresenterFromAPI === null
  ) {
    liveCurrentPresenter.push(presenters[0]);
  } else if (
    activePresenterFromAPI !== null &&
    Object.keys(currentPresenter).length === 0
  ) {
    const cP = presenters.filter((p) => p._id === activePresenterFromAPI);
    liveCurrentPresenter.push(cP[0]);
  } else {
    liveCurrentPresenter.push(currentPresenter);
  }

  // Next lines of code was for PresenterItem in WowzaPlayer and AntPlayer
  // related to wpv-71 in Jira
  // active={
  // liveStatus === 'presenter'
  //   ? currentPresenter
  //   : currentPresenter2[0]
  // }

  return (
    <>
      <div className='video-player-container' style={{ height: '100%' }}>
        {isLive === true && media.wowzaCloudLiveid !== '' ? (
          <>
            <WowzaPlayerContainer
              media={media}
              title={title}
              isLive={true}
              preview={preview}
              event={event}
              isOnlyVideo={isOnlyVideo}
              getHeight={getHeight}
              qnaMode={qnaMode}

            />

          

            {
            ispresenter  === 'off' ?  <></> : (
              
              isMobile || isOnlyVideo ? (
              <PresenterItemSmall
                active={liveCurrentPresenter[0]}
                isOnlyVideo={isOnlyVideo}
              />
            ) : (
              <PresenterItem
                active={liveCurrentPresenter[0]}
                // active={
                //   liveStatus !== undefined
                //     ? liveCurrentPresenter[0]
                //     : liveCurrentPresenter[0]
                //   // : currentPresenter2[0]
                // }
                small={true}
              />
            ))
            
            
            
            
            
            }







          </>
        ) : isLive === true && media.antMediaSrc !== '' ? (
          <>
            <AntPlayer
              media={media}
              title={title}
              isLive={true}
              preview={preview}
              event={event}
              isOnlyVideo={isOnlyVideo}
            />
            {isMobile || isOnlyVideo ? (
              <PresenterItemSmall
                active={liveCurrentPresenter[0]}
                isOnlyVideo={isOnlyVideo}
              />
            ) : (
              <PresenterItem active={liveCurrentPresenter[0]} small={true} />
            )}
          </>
        ) : (
          <div id='videoPlayerBC' className='videoBC-presenter-container'>
            <VideoJsContainer
              media={media}
              isOnlyVideo={isOnlyVideo}
              preview={preview}
              getHeight={getHeight}
            />

{
            ispresenter  === 'off' ?  <></> : (
              <PresenterItemSmall
              active={activePresenter[0]}
              // active={currentPresenter2[0]}
              isOnlyVideo={isOnlyVideo}
            />
            )

            }
          
          </div>
        )}
      </div>
    </>
  );
};

export default React.memo(VideoPlayer);
