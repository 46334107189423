import React, { useReducer } from 'react';
import axios from 'axios';
// import {
//  API_BASE_URL,
//  API_TOKEN,
//  ASSETS_BASE_URL,
//  API_AUTHLIST_TOKEN,
//  GEOIP_API_BASE_URL,
//  STATS_API, STATS_API_TOKEN
// } from '../../config/config';
import WebcastingContext from './webcastingContext';
import WebcastingReducer from './webcastingReducer';
import publicIp from 'react-public-ip';
import {
  GET_EVENT,
  GET_AUTHORIZATION,
  GET_CHECK_AUTHLIST,
  AUTHLIST_RESPONSE,
  AUTHLIST_COMPLETED,
  // SET_AUTHLIST,
  SET_REGISTRANT,
  SET_QUESTIONSENT,
  SET_NOTESENT,
  RESET_NOTESENT_VALUE,
  GET_LOGO,
  SET_LOADING,
  EVENT_ERROR,
  SAVE_LOCAL_DATA,
  READ_LOCAL_DATA,
  CURRENT_THUMB,
  CURRENT_PRESENTER,
  SEEKTO_TIME,
  GET_PLAYER_CURRENT_TIME,
  GET_PROGRESS_BAR_CURRENT_TIME,
  WEBSOCKET_CLIENT,
  LIVE_STATUS,
  LIVE_LAYOUT,
  GET_MESSAGE_TO_ALL,
  CLEAR_ERROR,
  HASMETADATA,
  SET_AUDIO_PIP,
  USER_IP,
  GET_USER_IP_PERMISSION,
  UPDATE_LOGIN_TIME,
  SET_BRANDING,
  GET_USER_IP,
  SET_USER_START_TIME,
  USER_VERIFIED,
  SET_NOREG_ID,
  EVENT_PASSWORD_VERIFIED,
  QUERY_SENT,
  SET_FONT_STYLING,
  QNA_STATUS,
  FULL_SCREEN_MODE_ON_DEMAND,
  SET_GA
} from '../types';
import submitLogs from '../../config/AWSGlobalLog';

axios.defaults.timeout = 5000;

let deb1 = 1;
let deb2 = 1;
let deb3 = 1;
let deb4 = 1;
let deb5 = 1;
let deb6 = 1;
let deb7 = 1;
let deb8 = 1;
let deb9 = 1;
let deb10 = 1;

const WebcastingState = (props) => {
  const initialState = {
    event: [],
    authorization: [],
    authList: {},
    authListResponse: {},
    authListCompleted: false,
    logo: '',
    // BRANDING - BOOL 
    branding:false,
    registrant: {},
    questionSent: 'no',
    noteSent: 'no',
    loading: false,
    error: null,
    localData: {
      user: {
        events: [],
      },
    },
    resultSaveLocal: false,
    resultSaveUser: false,
    currentPresenter: {},
    currentThumb: 0,
    seekToTime: 0,
    playerCurrentTime: 0,
    progressBarCurrentTime: 0,
    ws: {},
    messageToAll: {},
    hasmetadata: false,
    audioPip: false,
    userIp: '',
    userIpPermission: {},
    updateRegistrantLoginTime: {},
    userStartTime:'',
    userEndTime:'',
    userStartTimeTracking:'',
    userVerified:false,
    noRegID:'',
    noRegEventPassword:false,
    querySent:'',
    QnaStatus:false,
    onDemandFullScreenMode:false,
    onDemandFullScreenModeTimings:[],
    onDemandFullScreenMode:false,
    ga_enabled:false
  };

  const [state, dispatch] = useReducer(WebcastingReducer, initialState);

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> GET EVENT
  const getEvent = async (eventId) => {
    if (deb1 === 1) {
      setLoading();
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/event/${eventId}/?token=${process.env.REACT_APP_API_TOKEN}`
        );

        // checking if fonts or logo or presenter card is present -> set branding tp true;
        let getEventResult = res.data.items[0].webcast.branding;
        if(res.data.success && (getEventResult.fonts || getEventResult.logo || getEventResult.presenterCard)){
          setBranding()
        }

        setFontStyling(getEventResult.fonts)
        

        dispatch({
          type: GET_EVENT,
          payload: res.data,
        });
      } catch (error) {
        submitLogs(
          JSON.stringify({
              error:error.message
          })
        )
        console.log(error);
        dispatch({
          type: EVENT_ERROR,
          // type: GET_EVENT,
          payload: {
            success: false,
            message: { message: 'event not found' },
            event: [],
          },
        });
      }
      deb1++;
    }
  };




  //Check branding
  const setFontStyling = async (fonts) => {
        // console.log('Fonts', fonts)
        // Check title fonts
        // Check description fonts
        // Default fonts 
        dispatch({
          type: SET_FONT_STYLING,
          payload: fonts,
        });

  };




  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> GET USER IP
  const getUserIP = async () => {
    if (deb8 === 1) {
      try {
        const res = (await publicIp.v4()) || '';
        dispatch({
          type: USER_IP,
          payload: res,
        });
      } catch (error) {
        dispatch({
          type: EVENT_ERROR,
          payload: error.message,
        });
      }
    }
  };

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> GET USER IP PERMISSION
  const getUserIpPermission = async (userIp, eventId) => {
    if (deb10 === 1) {
      setLoading();
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_GEOIP_API_BASE_URL}/?ipaddress=${userIp}&eventid=${eventId}`
        );
        dispatch({
          type: GET_USER_IP_PERMISSION,
          payload: res.data,
        });
      } catch (error) {
        dispatch({
          type: EVENT_ERROR,
          payload: error.response,
        });
      }
      deb10++;
    }
  };



    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> POST GEO DATA OF USER FROM IP
    const getGeoData = async (userIp, eventId, userID) => {
      console.log('with req', userID)
      if (deb10 === 1) {
        try {
          const res = await axios.get(
            `${process.env.REACT_APP_GEOIP_API_BASE_URL}/?ipaddress=${userIp}&eventid=${eventId}`
          );

          const request = await fetch("https://ipinfo.io/json?token=6c766d2ca76fee")
          const jsonResponse = await request.json()
          console.log('res_ip', jsonResponse)
      
            const resStats = await axios.get(`${process.env.REACT_APP_STATS_API_URL}/event/addGeoData/${eventId}/?token=${process.env.REACT_APP_STATS_API_TOKEN}&geo=${res.data.data.country}&userID=${userID}`)
            const resGeoLocation = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/registrant/registrantLocation?token=${process.env.REACT_APP_API_TOKEN}`,
              {
                userID:userID,
                location:jsonResponse
              }
            );
      

            // post ip and country code 
        } catch (error) {
          submitLogs(
            JSON.stringify({
                status:'ERROR',
                message:'Failed GEO location for userID ' + userID,
                eventID:eventId,
                error:error.message
            })
          )
          dispatch({
            type: EVENT_ERROR,
            payload: error.response,
          });
        }
        deb10++;
      }
    };



    // Geo data and device name for NoReq Event
    const getGeoDataAndDeviceInfo = async (uid,userIp ,eventId,domain) => {
      console.log(uid, userIp, eventId)
      if (deb10 === 1) {
        try {
          const res = await axios.get(
            `${process.env.REACT_APP_GEOIP_API_BASE_URL}/?ipaddress=${userIp}&eventid=${eventId}`
          );
      

          //post geo code and device information
          let data = {
            uid:uid,
            updated_at:new Date(),
            location:res.data.data.country,
            platform:window.navigator.platform
          }

          console.log(data)
          const res1 = await axios.post(`${process.env.REACT_APP_STATS_API_URL}/event/addGeoDataDeviceInfoNoReqUID/${eventId}/?token=${process.env.REACT_APP_STATS_API_TOKEN}`, data)

          await axios.get(
            `${process.env.REACT_APP_STATS_API_URL}/event/liveUsersNoReq/${eventId}/?uid=${uid}&token=${process.env.REACT_APP_STATS_API_TOKEN}`
          );




          const request = await fetch("https://ipinfo.io/json?token=ce2ed65c47d251")
          const jsonResponse = await request.json()
          console.log('res_ip', jsonResponse)
          const resGeoLocation = await axios.post(`${process.env.REACT_APP_STATS_API_URL}/event/userLocationByNoReg/${eventId}?token=${process.env.REACT_APP_STATS_API_TOKEN}`,
          {
            
            location:{...jsonResponse, noRegID:uid}
          }
        );
          await axios.post(`${process.env.REACT_APP_STATS_API_URL}/event/referrerDomainNoReg/${eventId}/?token=${process.env.REACT_APP_STATS_API_TOKEN}`,{
            id:uid,
            domain:domain
          })

        } catch (error) {
          submitLogs(
            JSON.stringify({
                status:'ERROR',
                message:'(NO REG) Failed GEO/Location/Device information for UID ' + uid,
                eventID:eventId,
                error:error.message
            })
          )

          dispatch({
            type: EVENT_ERROR,  
            payload: error.response,
          });
        }
        deb10++;
      }
    };


    const referrerDomain = async(id,domain)=>{
      try {
       
              await axios.put(`${process.env.REACT_APP_API_BASE_URL}/registrant/referrerDomain/?token=${process.env.REACT_APP_API_TOKEN}`,{
                id:id,
                domain:domain
              })
        // post ip and country code 
    } catch (error) {

      submitLogs(
        JSON.stringify({
            status:'ERROR',
            message:'Failed adding referrer domain for userID' + id,
            error:error.message
        })
      )

      dispatch({
        type: EVENT_ERROR,
        payload: error.response,
      });
    }
    }




        // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> POST DEVICE USED BY USERS
        const addDeviceName = async (userID,eventId, device) => {
          // console.log('add device ', userIp, eventId, device)
       
            try {
       
                const resDeviceInformation = await axios.get(`${process.env.REACT_APP_STATS_API_URL}/event/addDeviceInformation/${eventId}/?token=${process.env.REACT_APP_STATS_API_TOKEN}&userID=${userID}&device=${device}`)
                // post ip and country code 
            } catch (error) {
              submitLogs(
                JSON.stringify({
                    status:'ERROR',
                    message:'Failed adding device information for user ' + userID,
                    eventId:eventId,
                    error:error.message
                })
              )

              dispatch({
                type: EVENT_ERROR,
                payload: error.response,
              });
            }
            deb10++;
          
        };
  

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> GET AUTHORIZATION
  const getAuthorization = async (eventId, registrantId) => {
    if (deb2 === 1) {
      setLoading();
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/registrant/${eventId}/${registrantId}/?token=${process.env.REACT_APP_API_TOKEN}`
        );

        dispatch({
          type: GET_AUTHORIZATION,
          payload: res.data,
        });
      } catch (error) {
        // console.log(error);
        dispatch({
          type: EVENT_ERROR,
          payload: error.response,
        });
      }
      deb2++;
    }
  };

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> GET AUTHLIST
  const getAuthList = async (eventId) => {
    if (deb9 === 1) {
      // add reset loading to false
      setLoading();
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/authlist/check/${eventId}/?token=${process.env.REACT_APP_API_AUTHLIST_TOKEN}`
        );

        // add set loading func
        dispatch({
          type: GET_CHECK_AUTHLIST,
          payload: res.data,
        });
      } catch (error) {
        dispatch({
          type: EVENT_ERROR,
          payload: error.response.data.message,
        });
      }
      deb9++;
    }
  };


  //Set GA
  const setGA = (status) => {
    try {
      dispatch({
        type: SET_GA,
        payload: status,
      });
    } catch (error) {
      dispatch({
        type: EVENT_ERROR,
        payload: error.message,
      });
    }
  };

  //Set GA



  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> SET AUTHLIST STATUS
  const setAuthList = () =>
    dispatch({ type: AUTHLIST_COMPLETED, payload: true });

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> POST AUTHLIST
  const checkAuthListFormData = async (eventId, listType, formData) => {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    // Create Data object to send
    const data = new FormData();

    if (listType === '2param') {
      data.append('listType', listType);
      data.append('param1', Object.values(formData)[0]);
      data.append('param2', Object.values(formData)[1]);
    } else {
      data.append('listType', listType);
      data.append('param1', Object.values(formData)[0]);
    }

    // Console.log for FormData
    // for (var pair of data.entries()) {
    //   console.log(pair[0] + ', ' + pair[1]);
    // }

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/authlist/auth/${eventId}/?token=${process.env.REACT_APP_API_AUTHLIST_TOKEN}`,
        data,
        config
      );

      dispatch({
        type: AUTHLIST_RESPONSE,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: EVENT_ERROR,
        payload: error.response.data.message,
      });
    }
  };

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> SET REGISTRANT
  const setRegistrant = async (eventId, formData) => {
    if (deb3 === 1) {
      setLoading();
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/registrant/createnew/?token=${process.env.REACT_APP_API_TOKEN}`,
          formData
        );

        if (eventId && res.data.success === true) {
          if (res.data.registrant.findId.length > 0) {
            //// console.log('registrant success. findId:', res.data.registrant.findId);
            dispatch({
              type: SET_REGISTRANT,
              payload: res.data,
            });
          }
        } else {
          dispatch({
            type: EVENT_ERROR,
            payload: false,
          });
        }
      } catch (error) {
        submitLogs(
          JSON.stringify({
              status:'ERROR',
              message:'Failed creating new registrant',
              formData:formData,
              eventId:eventId,
              error:error.message
          })
        )

        // console.log(error);
        dispatch({
          type: EVENT_ERROR,
          payload: error.response,
        });
      }
      deb3++;
    }
  };


  const setNoRegID = (id) => {
    try {
      dispatch({
        type: SET_NOREG_ID,
        payload: id,
      });
    } catch (error) {
      dispatch({
        type: EVENT_ERROR,
        payload: error.message,
      });
    }
  };



  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> UPDATE LOGIN TIME
  const updateLoginTime = async (registrantId) => {
    // console.log('Updating time for ', registrantId)
    const body = {
      registrantid: registrantId,
    };

    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/registrant/updatetime?token=${process.env.REACT_APP_API_TOKEN}`,
        body
      );

      dispatch({
        type: UPDATE_LOGIN_TIME,
        payload: res.data,
      });
    } catch (error) {
      submitLogs(
        JSON.stringify({
            status:'ERROR',
            message:'Failed updating loggedIn time for user '  + registrantId,
            error:error.message
        })
      )

      dispatch({
        type: EVENT_ERROR,
        payload: error.response,
      });
    }
  };


  // Track user start timing

  const setUserStartTiming = async (startTime)=>{
    try {
      dispatch({
        type: SET_USER_START_TIME,
        payload: startTime,
      });
    } catch (error) {
      
      dispatch({
        type: EVENT_ERROR,
        payload: error.response,
      });
    }
  }




  //user start time and end time tracking -> start



  //@dec Event with req
  const sendUserStartAndEndTime = async (registrantId, start) => {
    // console.log('Start time',state)
    const body = {
      registrantid: registrantId,
      startTime:start,
      endTime:new Date().toUTCString()
    };

    try {
      const res = await axios.put(
         `${process.env.REACT_APP_API_BASE_URL}/registrant/loggedOutTime/?token=${process.env.REACT_APP_API_TOKEN}`,
        body
      );

      // console.log(res)

    } catch (error) {
      dispatch({
        type: EVENT_ERROR,
        payload: error.response,
      });
    }
  };


  //@dec Event with NoReq

  const sendUserStartAndEndTimeNoReq = async (uid, start, eventId) => {
    console.log('Start time',uid, start, eventId)
    const body = {
      uid: uid,
      startTime:start,
      endTime:new Date().toUTCString()
    };

    try {
      const res = await axios.post(
         `${process.env.REACT_APP_STATS_API_URL}/event/addLoggedOutTime/${eventId}/?token=${process.env.REACT_APP_STATS_API_TOKEN}`,
        body
      );

      // console.log(res)

    } catch (error) {
      dispatch({
        type: EVENT_ERROR,
        payload: error.response,
      });
    }
  };


  //user start time and end time tracking -> end











  

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> SET MESSAGE
  const setMessage = async (formData) => {
    console.log('FORM DATA', formData)
    try {
      dispatch({
        type: SET_QUESTIONSENT,
        payload: 'sending',
      });

      const res = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/message/?token=${process.env.REACT_APP_API_TOKEN}`,
        formData.fields
      );

      // console.log('RES.DATA IN STATE: ', res.data);
      if (res.data.success === true) {
        dispatch({
          type: SET_QUESTIONSENT,
          payload: 'ok',
        });
      } else {

        submitLogs(
          JSON.stringify({
              status:'ERROR',
              message:'Failed sending message from user ',
              formData:formData
          })
        )

        dispatch({
          type: SET_QUESTIONSENT,
          payload: 'error',
        });
      }
    } catch (error) {
      submitLogs(
        JSON.stringify({
            status:'ERROR',
            message:'Failed sending message from user ',
            formData:formData,
            error:error.message
        })
      )

      dispatch({
        type: SET_QUESTIONSENT,
        payload: 'error',
      });
    }
  };


  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> SET BRANDING
  // @Set branding to true
  const setBranding = ()=>{
    dispatch({
        type:SET_BRANDING,
        payload:true
    })
  }



  /////  Send help email

  const sendQueryEmail = async (formBody) => {
   
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/event/sendHelpEmail/?token=${process.env.REACT_APP_API_TOKEN}`, formBody
        );

          if(res.data.success) {
            dispatch({
              type: QUERY_SENT,
              payload: 'sent',
            });
          }
       
          console.log('Email sent', res.data)

      } catch (error) {

        dispatch({
          type: EVENT_ERROR,
          payload: error,
        });
      }

  };



  const resetSendQuery = async () => {
   

      dispatch({
        type: QUERY_SENT,
        payload: 'empty',
      });
    

};


  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> RESET QUESTIONSENT
  const resetQuestionSent = () => {
    try {
      dispatch({
        type: SET_QUESTIONSENT,
        payload: 'no',
      });
    } catch (error) {
      dispatch({
        type: SET_QUESTIONSENT,
        payload: 'error',
      });
    }
  };

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> SET NOTE
  const setNote = async (formData) => {
    try {
      dispatch({
        type: SET_NOTESENT,
        payload: 'sending',
      });

      const res = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/emailfreenote/?token=${process.env.REACT_APP_API_TOKEN}`,
        formData.fields
      );

      if (res.data.success === true) {
        dispatch({
          type: SET_NOTESENT,
          payload: 'ok',
        });
      } else {
        dispatch({
          type: SET_NOTESENT,
          payload: 'error',
        });
      }
    } catch (error) {
      dispatch({
        type: SET_NOTESENT,
        payload: 'error',
      });
    }
  };

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> RESET NOTE SENT
  const resetNoteSentValue = () => {
    dispatch({
      type: RESET_NOTESENT_VALUE,
      payload: 'no',
    });
  };

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> GET LOGO
  const getLogo = async (logoImage) => {
    if (deb4 === 1) {
      try {
        const res = await axios.get(
          `https://rocky-tundra-66309.herokuapp.com/${process.env.REACT_APP_ASSETS_BASE_URL}/${logoImage}`
        );
        dispatch({
          type: GET_LOGO,
          payload: res.config.url,
        });
      } catch (error) {
        // console.log(error);
        dispatch({
          type: EVENT_ERROR,
          payload: error.response,
        });
      }
      deb4++;
    }
  };

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> SET LOADING
  const setLoading = () => dispatch({ type: SET_LOADING });

  // create func reset loading to false

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> SAVE LOCAL DATA
  const saveLocalData = (localData) => {
    if (deb5 === 1) {
      try {
        localStorage.setItem('localData', JSON.stringify(localData));
        dispatch({
          type: SAVE_LOCAL_DATA,
          payload: localData,
        });
      } catch (error) {
        // console.log(error);
        submitLogs(
          JSON.stringify({
              status:'ERROR',
              message:'Failed saving data to localstorage ',
              localData:localData,
              error:error.message
          })
        )
        dispatch({
          type: EVENT_ERROR,
          payload: false,
        });
      }
      deb5++;
    }
  };

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> FIRST LOCAL DATA
  const firstLocalData = () => {
    if (deb6 === 1) {
      localStorage.setItem('localData', JSON.stringify(initialState.localData));
      // console.log(
      //   'initialState.localData',
      //   JSON.stringify(initialState.localData)
      // );
      deb6++;
    }
  };

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> READ LOCAL DATA
  const readLocalData = (e,b) => {
    if (deb7 === 1) {
      let localDataRead = {};
      try {
        localDataRead = localStorage.getItem('localData');
   

        if (localDataRead) {
          localDataRead = JSON.parse(localDataRead);
        } else {
          firstLocalData();
          localDataRead = localStorage.getItem('localData');
          localDataRead = JSON.parse(localDataRead);
        }


        dispatch({
          type: READ_LOCAL_DATA,
          payload: localDataRead,
        });
      } catch (error) {
        // console.log('localDataRead error: ', error.message);
        // console.log('error localDataRead: ', localDataRead);

        dispatch({
          type: EVENT_ERROR,
          payload: error.message,
        });
      }
      deb7++;
    }
  };

  // Check local data is present or not and then redirect to unique link

  const checkLocalDataAndRedirect = async(id)=>{
    let localDataRead = localStorage.getItem('localData');
    localDataRead = JSON.parse(localDataRead);
    if(localDataRead  && localDataRead.user.events.length > 0) {
      
      let allEventsInLocalData = localDataRead.user.events.find((each)=> each.eventId=== id);
      if(allEventsInLocalData) {
        // redirect
        window.location.href = `/broadcast/${allEventsInLocalData.eventId}/${allEventsInLocalData.findId}`;
      } 


    }

  } 


  // Set password verified as true for event with reg

  const verifyEventPassword = async()=>{

    dispatch({
      type: EVENT_PASSWORD_VERIFIED
    });

  } 

  const validateUserID = async(eventId,id)=>{
    
    let localDataRead = localStorage.getItem('localData');
    localDataRead = JSON.parse(localDataRead);
  
    //Check localstorage, if null or no events for that user
      //Redirect to Registration page
    if(localDataRead  && localDataRead.user.events.length > 0) {
  
      //Localstorage FindID (registrantFindID)
      let registrantFindID = localDataRead.user.events.find((each)=> each.eventId=== eventId);
      console.log('isverified reg', registrantFindID)
  
  
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/registrant/${eventId}/${id}/?token=${process.env.REACT_APP_API_TOKEN}`
      );
  
      //DB FIndID (filteredRegistrant)
      let filteredRegistrant = res.data.success ? res.data?.registrants[0] : res.data.success


      //IMPORTANT CHECK 1 (if ID not present in DB or localstorage event object not found then REDIRECT)

      if(!filteredRegistrant || !registrantFindID ) {
        localStorage.removeItem('localData')
        window.location.href = `/broadcast/${eventId}`;
      } 

      //IMPORTANT CHECK 1 (if localstorage event object is found but not matched with findID then REDIRECT)

      if(registrantFindID?.findId !== id ) {
        console.log('Line 1009', registrantFindID)
        localStorage.removeItem('localData')
        window.location.href = `/broadcast/${eventId}`;
      }
  
      if(filteredRegistrant) {
            try {
              const res = await axios.get(
                `${process.env.REACT_APP_API_BASE_URL}/event/${eventId}/?token=${process.env.REACT_APP_API_TOKEN}`
              );
  
              if(res.data.items[0].regEmailvalidCheck) {
                  if(filteredRegistrant.isVerified) {
                    dispatch({
                      type:USER_VERIFIED
                    })
                    window.location.href = `/broadcast/${filteredRegistrant.eventid}/${filteredRegistrant.findId}?isverified=true`;
                   
                  } else {
                    window.location.href = `/notVerified/${filteredRegistrant.eventid}/${filteredRegistrant.findId}`;
  
                  }
              }
  
            } catch(error) {
              submitLogs(
                JSON.stringify({
                    status:'ERROR',
                    message:'Failed validating user',
                    eventId:eventId,
                    userID:id,
                    error:error.message
                })
              )
  
              
              dispatch({
                type: EVENT_ERROR,
                // type: GET_EVENT,
                payload: {
                  success: false,
                  message: { message: 'event not found' },
                  event: [],
                },
              });
            }
            
      }
  
    }
  
    else {
      localStorage.removeItem('localData')
      window.location.href = `/broadcast/${eventId}`;
    }
  
  } 





  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> GET CURRENT THUMBNAIL
  const getCurrentThumb = (index) => {
    // console.log('current thumb: ', index);
    //// console.log('state current thumb: ', state.currentThumb);
    try {
      dispatch({
        type: CURRENT_THUMB,
        payload: index,
      });
    } catch (error) {
      dispatch({
        type: EVENT_ERROR,
        payload: error.message,
      });
    }
  };

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> SET CURRENT PRESENTER
  const setCurrentPresenter = (presObj) => {
    // console.log('current presenter: ', presObj);
    try {
      dispatch({
        type: CURRENT_PRESENTER,
        payload: presObj,
      });
    } catch (error) {
      dispatch({
        type: EVENT_ERROR,
        payload: error.message,
      });
    }
  };

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> GET SEEKING TIME
  const getSeekingTime = (time) => {
    // console.log('State => getSeekingTime: ', time);
    // console.log('seekToTime: ', state.seekToTime);

    try {
      dispatch({
        type: SEEKTO_TIME,
        payload: time,
      });
    } catch (error) {
      dispatch({
        type: EVENT_ERROR,
        payload: error.message,
      });
    }
  };

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> GET PLAYER CURRENT TIME
  const getPlayerCurrentTime = (time) => {
    // console.log('getPlayerCurrentTime in context: ', time);
    try {
      dispatch({
        type: GET_PLAYER_CURRENT_TIME,
        payload: time,
      });
    } catch (error) {
      dispatch({
        type: EVENT_ERROR,
        payload: error.message,
      });
    }
  };

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> GET PROGRESS BAR CURRENT TIME
  const getProgressBarCurrentTime = (time) => {
    // // console.log('State => getProgressBarCurrentTime: ', time);
    // // console.log(
    //   'State => progressBarCurrentTime: ',
    //   state.progressBarCurrentTime
    // );

    try {
      dispatch({
        type: GET_PROGRESS_BAR_CURRENT_TIME,
        payload: time,
      });
    } catch (error) {
      dispatch({
        type: EVENT_ERROR,
        payload: error.message,
      });
    }
  };

  const websocketClient = (req, res) => {
    let ws = null;
    let sMsg = null;

    try {
      const subscribe = {
        event: 'subscribe',
        channel: 1,
      };
      ws = new WebSocket('ws://localhost:3030');

      ws.onopen = () => {
        ws.send(JSON.stringify(subscribe));
        // console.log('ws channel subscribed');
      };

      ws.onmessage = (event) => {
        const response = JSON.parse(event.data);
        console.log('ws onmessage', response);
      };

      ws.onclose = () => {
        ws.close();
        // console.log('ws close');
        clearInterval(interval);
      };

      const wsClosed = () => {
        clearInterval(interval);
        ws.close();
        // console.log('server closed connection');
      };

      const checkServerAlive = () => {
        // console.log('ping ws server');
        if (!ws || ws.readyState === WebSocket.CLOSED) wsClosed();
      };

      if (req.cmd === 'send') {
        ws.send(req.msg);
        // console.log('ws message sent', req.msg);
      }

      let interval = setInterval(function ping() {
        checkServerAlive();
      }, 30000);

      dispatch({
        type: WEBSOCKET_CLIENT,
        payload: { ws, req, sMsg },
      });

      return () => {
        ws.close();
      };
    } catch (error) {
      dispatch({
        type: EVENT_ERROR,
        payload: error.message,
      });
    }
  };

  const setLiveStatus = (status) => {
    try {
      dispatch({
        type: LIVE_STATUS,
        payload: status,
      });
    } catch (error) {

      submitLogs(
        JSON.stringify({
            status:'ERROR',
            message:'WEBSOCKET - EVENT STATUS',
            error:error.message
        })
      )

      dispatch({
        type: EVENT_ERROR,
        payload: error.message,
      });
    }
  };


  const setQnaStatus = (status) => {
    try {
      dispatch({
        type: QNA_STATUS,
        payload: status,
      });
    } catch (error) {
      dispatch({
        type: EVENT_ERROR,
        payload: error.message,
      });
    }
  };

  //Full screen mode for On Demand events
  const setOnDemandFullScreenMode = (status) => {
    try {
      dispatch({
        type: FULL_SCREEN_MODE_ON_DEMAND,
        payload: status,
      });
    } catch (error) {
      submitLogs(
        JSON.stringify({
            status:'ERROR',
            message:'WEBSOCKET - Full screen error',
            error:error.message
        })
      )

      dispatch({
        type: EVENT_ERROR,
        payload: error.message,
      });
    }
  };


  const setLiveLayout = (status) => {
    try {
      dispatch({
        type: LIVE_LAYOUT,
        payload: status,
      });
    } catch (error) {
      submitLogs(
        JSON.stringify({
            status:'ERROR',
            message:'WEBSOCKET - LIVE LAYOUT failed to load ' + status,
            error:error.message
        })
      )

      dispatch({
        type: EVENT_ERROR,
        payload: error.message,
      });
    }
  };

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> GET MESSAGES TO ALL
  const getMessageToAll = (message) => {
    // console.log('WEBCASTINGSTATE: ', message);
    // let count = 0;
    // if (message) count++;
    // console.log('STATE: ', count);

    try {
      dispatch({
        type: GET_MESSAGE_TO_ALL,
        payload: message,
      });
    } catch (error) {
      submitLogs(
        JSON.stringify({
            status:'ERROR',
            message:'WEBSOCKET - Failed receiving broadcasting message',
            error:error.message
        })
      )

      dispatch({
        type: EVENT_ERROR,
        payload: error.message,
      });
    }
  };

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> RESET ERROR
  const clearError = () => {
    try {
      dispatch({
        type: CLEAR_ERROR,
        payload: null,
      });
    } catch (error) {
      dispatch({
        type: EVENT_ERROR,
        payload: error.message,
      });
    }
  };

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> CHECK PLAYER LOADEDMETADATA
  const hasMetaData = () => {
    try {
      dispatch({
        type: HASMETADATA,
        payload: true,
      });
    } catch (error) {
      dispatch({
        type: EVENT_ERROR,
        payload: error.message,
      });
    }
  };

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> SET AUDIO PICTURE IN PICTURE
  const setAudioPip = (active) => {
    try {
      dispatch({
        type: SET_AUDIO_PIP,
        payload: active,
      });
    } catch (error) {
      dispatch({
        type: EVENT_ERROR,
        payload: error.message,
      });
    }
  };

  return (
    <WebcastingContext.Provider
      value={{
        event: state.event,
        authorization: state.authorization,
        authList: state.authList,
        authListResponse: state.authListResponse,
        authListCompleted: state.authListCompleted,
        logo: state.logo,
        // BRANDING - BOOL 
        branding:state.branding,
        loading: state.loading,
        error: state.error,
        localData: state.localData,
        registrant: state.registrant,
        questionSent: state.questionSent,
        noteSent: state.noteSent,
        currentThumb: state.currentThumb,
        currentPresenter: state.currentPresenter,
        seekToTime: state.seekToTime,
        playerCurrentTime: state.playerCurrentTime,
        progressBarCurrentTime: state.progressBarCurrentTime,
        ws: state.ws,
        liveStatus: state.liveStatus,
        liveLayout: state.liveLayout,
        messageToAll: state.messageToAll,
        hasmetadata: state.hasmetadata,
        audioPip: state.audioPip,
        userIp: state.userIp,
        userIpPermission: state.userIpPermission,
        updateRegistrantLoginTime: state.updateRegistrantLoginTime,
        userStartTime:state.userStartTime,
        userStartTimeTracking:state.userStartTimeTracking,
        userVerified:state.userVerified,
        noRegID:state.noRegID,
        noRegEventPassword: state.noRegEventPassword,
        querySent:state.querySent,
        QnaStatus:state.QnaStatus,
        onDemandFullScreenModeTimings:state.onDemandFullScreenModeTimings,
        onDemandFullScreenMode:state.onDemandFullScreenMode,
        ga_enabled:state.ga_enabled,
        getEvent,
        getAuthorization,
        getAuthList,
        setGA,
        checkAuthListFormData,
        setRegistrant,
        setMessage,
        resetQuestionSent,
        setNote,
        resetNoteSentValue,
        getLogo,
        // @To set branding - bool value 
        setBranding,
        saveLocalData,
        readLocalData,
        checkLocalDataAndRedirect,
        getCurrentThumb,
        setCurrentPresenter,
        getSeekingTime,
        getPlayerCurrentTime,
        getProgressBarCurrentTime,
        websocketClient,
        setLiveStatus,
        setLiveLayout,
        getMessageToAll,
        clearError,
        setAuthList,
        hasMetaData,
        setAudioPip,
        getUserIP,
        getUserIpPermission,
        updateLoginTime,
        sendUserStartAndEndTime,
        validateUserID,
        getGeoData,
        addDeviceName,
        setUserStartTiming,
        sendQueryEmail,
        resetSendQuery,
        
        //noreq
        getGeoDataAndDeviceInfo,
        referrerDomain,
        sendUserStartAndEndTimeNoReq,
        setNoRegID,
        verifyEventPassword,
        // Qna status
        setQnaStatus,
        setOnDemandFullScreenMode
        
      }}
    >
      {props.children}
    </WebcastingContext.Provider>
  );
};

export default WebcastingState;
