import React, { createContext, useEffect, useRef, useState } from "react";
import submitLogs from "../../config/AWSGlobalLog";


const SocketContainerNoReg = createContext(null);

export { SocketContainerNoReg };

export default ({ children, socket_wc,noRegID, eventID }) => {



    const [socketioMethods, setSocketioMethods] = useState({});

    useEffect(() => {

        console.log('Whats in socket NOREG NOREG')
        if (socket_wc) {
            socket_wc.on('connection', (data) => {
                console.log('connected', data)
            });

            socket_wc.on('connect_error', (error) => {
                console.error('Socket.io connection error:', error);

                submitLogs(
                    JSON.stringify({
                        status: 'ERROR - connect_error',
                        message: 'SOCKET.IO connect_error',
                        error: error
                    }) )

            });

            socket_wc.on("disconnect", (reason) => {
                submitLogs(
                    JSON.stringify({
                        status: 'ERROR - disconnect',
                        message: 'SOCKET.IO disconnect',
                        error: reason
                    }) )
                console.log('Socket.io connection error Reason ---->>>>>>>:', reason);

            });

        }
        return () => {
            // socket.emit('leaveRoom', eventID, userId);
            try {
                // socket.disconnect();
                // socket_wc.emit('leaveEvent_noreg',eventID, noRegID); 

                console.log("Disconneting from socket.io");

            } catch (error) {
                submitLogs(
                    JSON.stringify({
                        status: 'ERROR',
                        message: 'SOCKET.IO disconnecting',
                        error: error
                    }) )
                console.error('Error disconnecting socket:', error);
            }
        }

    }, []);


    useEffect(() => {
        // if (socket_wc.connected) {

                if(noRegID) {

                
                    try {

                        let userData = {
                            noRegID:noRegID,
                            eventID:eventID
                        }
                        console.log('Connected', userData)
        
                        socket_wc.emit('join_noreg',userData);
        
        
                        socket_wc.on('liveParticipantsUpdateByEvent', (data) => {
                            console.log('From liveUsers', data)
                        })
        
                        socket_wc.on('liveParticipantsByEvent', (data) => {
                            console.log('Live user updates', data)
                        })
        
        
                    } catch (error) {
                        submitLogs(
                            JSON.stringify({
                                status: 'ERROR',
                                message: 'SOCKET.IO disconnecting',
                                error: error
                            }) )
                        console.error('Socket.io connection error:', error);
        
                    }
                }


        // }

        return () => {
            // socket_wc.off('message')
            // socket.disconnect()

        }
    }, [socket_wc,noRegID])




    return (
        <SocketContainerNoReg.Provider value={socketioMethods}>
            {children}
        </SocketContainerNoReg.Provider>
    );
};
