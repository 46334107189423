import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App.js';
import * as serviceWorker from './serviceWorker.js';
// import { createBrowserHistory } from 'history';

// import ReactGA from 'react-ga';
// import { REACT_APP_GOOGLE_ANALYTICS_ID } from './config/config';

// ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID, {
//   debug: true,
//   titleCase: false,
//   redactEmail: false,
//   gaOptions: { siteSpeedSampleRate: 100 },
// });

// export const history = createBrowserHistory({
//   basename: process.env.PUBLIC_URL,
// });

ReactDOM.render(
  <BrowserRouter basename={process.env.PUBLIC_URL} forceRefresh={false}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById('root')
);

serviceWorker.unregister();
