import React, { useState, useContext, useEffect, useRef } from 'react';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import WebcastingContext from '../../context/webcasting/webcastingContext';
// import { WEBCASTING_PLATFORM_URL } from '../../config/config';

// MUI
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  DialogContent,
  Container,
  Grid,
  Box,
  List,
  ListItem,
  Typography,
  Snackbar,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

// HELPERS
import { gaEvent } from '../../helpers/reactGA';

import './index.css';

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const useStyles = makeStyles((theme) => ({
  dContent: {
    [theme.breakpoints.down('md')]: {
      padding: '8px 0px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '10px',
    },
  },
  listItem: {
    [theme.breakpoints.down('sm')]: {
      padding: '0px',
    },
  },
  sendBtn: {
    marginTop: '0.7rem',
    color: '#333',
    backgroundColor: '#fff',
    borderColor: '#333',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#333',
      borderColor: '#333',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  noteTextArea: {
    '& div': {
      '& textarea:first-child': {
        minHeight: '90px',
      },
    },
  },
  input: {
    width: '75%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  closeBtn: {
    left: 'calc(100% - 48px)',
    marginTop: '32px',
  },
}));

const NotesDrawer = (props) => {
  let {socket_wc} = props
  const webcastingContext = useContext(WebcastingContext);
  const { setNote, noteSent, localData, resetNoteSentValue, authorization, noRegID } =
    webcastingContext;

  const [formData, setFormData] = useState({});
  const [submitReady, setSubmitReady] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const noteRef = useRef(null);
  const emailRef = useRef(null);
  const nameRef = useRef(null);

  let note = {};
  note.eventid = props.event._id;
  note.findId = '';
  note.name = '';
  note.email = '';
  note.webcast_title = props.event.title;
  note.webcast_link = `${process.env.REACT_APP_WEBCASTING_PLATFORM_URL}/broadcast/${props.event._id}`;
  note.user_notes = '';

  let isUserRegistered = false;
  let fieldObj = note;

  const userData = () => {
    if (localData.user) {
      if (localData.user.events.length > 0) {
        localData.user.events.forEach((element) => {
          if (element.eventId === props.event._id) {
            if (element.registrant.fields) {
              const parsedEl = JSON.parse(element.registrant.fields);
              fieldObj.name = parsedEl.name || 'there';
              fieldObj.email = parsedEl.email;
              fieldObj.findId = element.findId;
              isUserRegistered = true;
            } else {
              fieldObj.name = 'there';
              fieldObj.email = element.registrant.email;
              fieldObj.findId = element.findId;
              isUserRegistered = true;
            }
          }
        });
      }
    }
  };
  userData();

  const [noteData, setNoteData] = useState(fieldObj);

  const handleChangeData = (event) => {


    const noteDataKey = event.target.name;
    const noteDataValue = event.target.value.replace(/(<([^>]+)>)/ig,"");
    setNoteData({ ...noteData, [`${noteDataKey}`]: noteDataValue });
    setSubmitReady(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormData({
      eventid: props.event._id,
      fields: noteData,
    });
    setOpenSnack(true);
    setSubmitReady(true);
  };

  useEffect(() => {
    const noteRefCurrent = noteRef.current;
    const nameRefCurrent = nameRef.current;
    const emailRefCurrent = emailRef.current;

    let isMounted = true;

    if (isMounted) {
      const sessionStorageNoteData =
        JSON.parse(sessionStorage.getItem('wpUserNotesData')) || {};

      // console.log(sessionStorageNoteData)


      if (Object.keys(sessionStorageNoteData).length > 0) {
        if (sessionStorageNoteData.user_notes !== '') {
          setNoteData((prevState) => ({
            ...prevState,
            user_notes: sessionStorageNoteData.user_notes,
          }));
        }
        if (sessionStorageNoteData.name !== '' && !isUserRegistered) {
          setNoteData((prevState) => ({
            ...prevState,
            name: sessionStorageNoteData.name,
          }));
        }
        if (sessionStorageNoteData.email !== '' && !isUserRegistered) {
          setNoteData((prevState) => ({
            ...prevState,
            email: sessionStorageNoteData.email,
          }));
        }
      }
    }

    return () => {
      sessionStorage.setItem(
        'wpUserNotesData',
        JSON.stringify({
          user_notes: noteRefCurrent.state.value,
          email: !isUserRegistered ? emailRefCurrent.state.value : '',
          name: !isUserRegistered ? nameRefCurrent.state.value : '',
        })
      );

      isMounted = false;
    };
    // eslint-disable-next-line
  }, []);

  // >>>>>>>>>>>>>>>> Set note message to context setNote function
  useEffect(() => {
    if (submitReady) setNote(formData);
    // eslint-disable-next-line
  }, [submitReady]);

  // >>>>>>>>>>>>>>> Set timeout for close the drawer/panel
  useEffect(() => {
    if (noteSent === 'ok') {

      if(props.event.registrationRequired) {
        let action = {
          userID:Object.entries(authorization).length > 0 && authorization.registrants[0].findId,
          action: Object.entries(authorization).length > 0 &&  JSON.parse(authorization.registrants[0].fields).email + ' used note feature',
          eventID:props.event._id,
          // action:'Used A used note feature',
          type:'NOTE',
          time:new Date(),
          details:{   
    
          }
    }
    socket_wc.emit('userAction',action);  
        
      } else {
        let action = {
          userID:noRegID.length > 0 && noRegID,
          action: noRegID.length > 0 &&  noRegID + ' used note feature',
          eventID:props.event._id,
          // action:'Used A used note feature',
          type:'NOTE',
          time:new Date(),
          details:{   
    
          }
    }
    socket_wc.emit('userAction',action); 
      }

      const timer = setTimeout(() => {
        props.onClose(`left`, false);
        gaEvent('bottom_toolbar', 'freenote', 'submit');

        // Clear user_notes value in sessionstorage obj
        const sessionStorageData =
          JSON.parse(sessionStorage.getItem('wpUserNotesData')) || {};
        delete sessionStorageData.user_notes;
        sessionStorage.setItem(
          'wpUserNotesData',
          JSON.stringify(sessionStorageData)
        );
      }, 2000);

      return () => {
        resetNoteSentValue();
        clearTimeout(timer);
      };
    }

    // eslint-disable-next-line
  }, [noteSent]);

  // >>>>>>>>>>>>>>>> Material UI handler
  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setNoteData({ ...noteData, [`user_notes`]: '' });
    setSubmitReady(false);
    setOpenSnack(false);
  };

  const classes = useStyles();

  return (
    <div role='presentation'>
      <Container maxWidth='xl'>
        <Grid item mt={4} xs={12} sm={12} md={12} lg={12} xl={12}>
          <IconButton
            aria-label='close'
            disableRipple={true}
            onClick={props.onClose}
            className={classes.closeBtn}
          >
            <CloseIcon />
          </IconButton>
          <ValidatorForm
            onSubmit={handleSubmit}
            onError={(errors) => console.log(errors)}
          >
            <Box mt={3} className={classes.dContent}>
              <Typography
                variant='h4'
                id='form-dialog-title'
                className='note-title title'
              >
                Take Notes
              </Typography>
              <Typography variant='subtitle1' className='note-subtitle title'>
                And send them to your email
              </Typography>
            </Box>
            <DialogContent className={classes.dContent}>
              <Box mt={2}>
                <TextValidator
                  ref={noteRef}
                  autoFocus
                  margin='dense'
                  id='user_notes'
                  fullWidth
                  label='Your Notes'
                  onChange={handleChangeData}
                  name='user_notes'
                  multiline
                  value={noteData[`user_notes`]}
                  validators={['required']}
                  errorMessages={['this field is required']}
                  disabled={false}
                  variant='outlined'
                  className={classes.noteTextArea}
                />
              </Box>
              {isUserRegistered ? (
                <>
                  <Grid item xs={12} style={{ textAlign: 'right' }}>
                    <Button
                      className={classes.sendBtn}
                      variant='outlined'
                      type='submit'
                      disabled={noteSent === 'sending'}
                    >
                      Send
                    </Button>
                  </Grid>
                </>
              ) : (
                <>
                  <Box mt={2}>
                    <Typography variant='caption' display='block' gutterBottom>
                      The notes you take will be submitted to the following
                      email
                    </Typography>
                    <List dense>
                      <ListItem className={classes.listItem}>
                        <div className={classes.input}>
                          <TextValidator
                            ref={nameRef}
                            margin='dense'
                            id='name'
                            fullWidth
                            label='Name'
                            onChange={handleChangeData}
                            name='name'
                            value={noteData[`name`]}
                            validators={['required']}
                            errorMessages={['this field is required']}
                            disabled={false}
                            variant='outlined'
                          />
                        </div>
                      </ListItem>
                      <ListItem className={classes.listItem}>
                        <div className={classes.input}>
                          <TextValidator
                            ref={emailRef}
                            margin='dense'
                            id='email'
                            fullWidth
                            label='Email'
                            onChange={handleChangeData}
                            name='email'
                            value={noteData[`email`]}
                            validators={['required', 'isEmail']}
                            errorMessages={[
                              'this field is required',
                              'please type a valid email',
                            ]}
                            disabled={false}
                            variant='outlined'
                          />
                        </div>
                      </ListItem>
                    </List>
                  </Box>
                  <Box>
                    <Grid item xs={12} style={{ textAlign: 'right' }}>
                      <Button
                        className={classes.sendBtn}
                        variant='outlined'
                        type='submit'
                        disabled={noteSent === 'sending'}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Box>
                </>
              )}
            </DialogContent>
          </ValidatorForm>
        </Grid>
        {noteSent === 'ok' ? (
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            open={openSnack}
            autoHideDuration={2000}
            onClose={handleCloseSnack}
          >
            <Alert severity='success' onClose={handleCloseSnack}>
              Note Sent
            </Alert>
          </Snackbar>
        ) : noteSent === 'error' ? (
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            open={openSnack}
            autoHideDuration={2500}
            onClose={handleCloseSnack}
          >
            <Alert severity='error' onClose={handleCloseSnack}>
              An error occurred, please try again
            </Alert>
          </Snackbar>
        ) : noteSent === 'sending' ? (
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            open={openSnack}
            autoHideDuration={1500}
          >
            <Alert severity='warning'>Sending...</Alert>
          </Snackbar>
        ) : (
          <></>
        )}
      </Container>
    </div>
  );
};

export default React.memo(NotesDrawer);
