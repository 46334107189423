import React from 'react';
import LiveLayout from './Live';

const MemoizedPreviewLayout = ({
  match,
  loading,
  event,
  saveAuth,
  authorization,
  localData,
  preview,
}) => {
  return (
    <LiveLayout
      match={match}
      loading={loading}
      event={event}
      saveAuth={saveAuth}
      authorization={authorization}
      localData={localData}
      preview={preview}
    />
  );
};

const areEqual = (prevProps, nextProps) => {
  // console.log('areEqual: ', prevProps.match === nextProps.match);
  return prevProps.match === nextProps.match;
};

export default React.memo(MemoizedPreviewLayout, areEqual);
