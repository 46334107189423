import React from 'react';
import VideoJsSimpleFunc from './VideoJsSimpleFunc';

import './index.css';

import SnackBar from '../../UI/snackbar/SnackBar';

let streamingSrc = false;

const WowzaVideoJsPlayer = (props) => {
  switch (props.playerId) {
    case '69ql0nl6':
      streamingSrc =
        'https://cdn3.wowza.com/1/c1M4SWRRVVdqTWtI/QUtVSHZX/hls/live/playlist.m3u8';
      break; //Wirecast low latency 1
    case 'ypjbkqsl':
      streamingSrc =
        'https://cdn3.wowza.com/1/RHBHSzA4c3d0RHo4/TkdKaGps/hls/live/playlist.m3u8';
      break; //Wirecast low latency 2
    case '9fv9fhwp':
      streamingSrc =
        'https://cdn3.wowza.com/1/akZxNG5UZndDSzNU/VVhhU1hi/hls/live/playlist.m3u8';
      break; //Wirecast low latency 3
    case 'y7t6mbkl':
      streamingSrc =
        'https://cdn3.wowza.com/1/ZFd4VVJHOHRoWWxQ/RGJtMHlx/hls/live/playlist.m3u8';
      break; //Wirecast low latency 4
    case 'jvwlhmzw':
      streamingSrc =
        'https://cdn3.wowza.com/1/NUJ6WjhHaG1pQUNT/anNkVHNj/hls/live/playlist.m3u8';
      break; //Wirecast low latency 5
    case 'fyszjhb2':
      streamingSrc =
        'https://cdn3.wowza.com/1/bnd5a3phNUJ3Z2ht/WitTdG9n/hls/live/playlist.m3u8';
      break; //Wirecast low latency 6

      case 'qrgfsmmc':
        streamingSrc =
          'https://cdn3.wowza.com/1/dzAzeXB4WTlEd05j/UnlaQTJx/hls/live/playlist.m3u8';
        break; //Wirecast low latency 7

        case 'xfkw5jvb':
          streamingSrc =
            'https://cdn3.wowza.com/1/UFE3VkNoK2tWWjcz/cWRxZUFN/hls/live/playlist.m3u8';
          break; //Wirecast low latency 8

          case 'th3pb6vz':
            streamingSrc =
              'https://cdn3.wowza.com/1/a1NqZkpNd0dUQlJm/Z0pKSVJL/hls/live/playlist.m3u8';
            break; //Wirecast low latency 9
          
            case 'tvnf0ctb':
              streamingSrc =
                'https://cdn3.wowza.com/1/cWEyYkx2MTlYTGhy/c21aaG8y/hls/live/playlist.m3u8';
              break; //Wirecast low latency 10

              case 'qt0yjdyq':
                streamingSrc =
                  'https://cdn3.wowza.com/1/UmpOb2grajVTRGVV/c0VWaU1G/hls/live/playlist.m3u8';
                break; //Wirecast low latency 11  

                case 'vblbfm0p':
                  streamingSrc =
                    'https://cdn3.wowza.com/1/STdzZlo4cnBuR0Nn/UzZYckEw/hls/live/playlist.m3u8';
                  break; //Wirecast low latency 12
  

    case 'gmrhvtcm':
      streamingSrc =
        'https://cdn3.wowza.com/1/c1I5eUFuejFqTkJy/aGcvdjE3/hls/live/playlist.m3u8';
      break; //BRR IT DEV
    case 'gf5nnxhs':
      streamingSrc =
        'https://cdn3.wowza.com/1/WTRVQXN1bGo4ajlk/QlVGcnV2/hls/live/playlist.m3u8';
      break; //Gocoder low latency 1
    case 'c19tcrpt':
      streamingSrc =
        'https://cdn3.wowza.com/1/ZmlRZTBFUjNWVjV3/SFB2aitO/hls/live/playlist.m3u8';
      break; //Gocoder low latency 2
    case '8znfvlj8':
      streamingSrc =
        'https://cdn3.wowza.com/1/M2pINXpWS0RCSnlF/L2R3aUMx/hls/live/playlist.m3u8';
      break; //Gocoder low latency 3
    default:
      streamingSrc = false;
      break;
  }

  // const videoJsOptions = {
  //   autoplay: true,
  //   muted: true,
  //   controls: true,
  //   sources: [
  //     {
  //       src: streamingSrc,
  //       type: 'application/x-mpegURL',
  //     },
  //   ],
  // };

  return (
    <div className='videoWrapper'>
      <SnackBar
        open={true}
        liveStarted={props.liveStarted}
        element={'video'}
        position={{ vertical: 'top', horizontal: 'left' }}
        preview={props.preview}
      />
      <div
        id='playerElement'
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
        }}
      >
        {streamingSrc && <VideoJsSimpleFunc src={streamingSrc} qnaMode={props.qnaMode}/>}
      </div>
    </div>
  );
};

export default React.memo(WowzaVideoJsPlayer);
