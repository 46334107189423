import React, { useState, useEffect, useContext } from 'react';
// import { PRIMUS_JS_FILE_URL } from '../../config/config';

import WebcastingContext from '../../context/webcasting/webcastingContext';

import MemoizedPrimusConnect from './MemoizedPrimusConnect';

let channel = null;
let countMessageSent = 1;

// Hook
// Create script and append to the DOM
function useScript(src) {
  const [status, setStatus] = useState(src ? 'loading' : 'idle');

  useEffect(() => {
    if (!src) {
      setStatus('idle');
      return;
    }
    let script = document.querySelector(`script[src="${src}"]`);

    if (!script) {
      // Create script
      script = document.createElement('script');
      script.src = src;
      script.async = true;
      script.setAttribute('data-status', 'loading');
      document.body.appendChild(script);
      const setAttributeFromEvent = (event) => {
        script.setAttribute(
          'data-status',
          event.type === 'load' ? 'ready' : 'error'
        );
      };

      script.addEventListener('load', setAttributeFromEvent);
      script.addEventListener('error', setAttributeFromEvent);
    } else {
      setStatus(script.getAttribute('data-status'));
    }
    const setStateFromEvent = (event) => {
      setStatus(event.type === 'load' ? 'ready' : 'error');
    };
    script.addEventListener('load', setStateFromEvent);
    script.addEventListener('error', setStateFromEvent);
    return () => {
      if (script) {
        script.removeEventListener('load', setStateFromEvent);
        script.removeEventListener('error', setStateFromEvent);
      }
    };
  }, [src]);
  return status;
}

const PrimusContainer = (props) => {
  const webcastingContext = useContext(WebcastingContext);
  const {
    getCurrentThumb,
    setLiveStatus,
    setLiveLayout,
    questionSent,
    setCurrentPresenter,
    getMessageToAll,
    setQnaStatus
  } = webcastingContext;

  // NOTES
  // isLive is used for:
  // #1 to select the activeSlide from the API, without this check the application will always take that slide as a first one
  // #2 if isLive is FALSE Primus is not called, so we do not insert the scirpt to the DOM

  const [isLive, setIsLive] = useState(false);
  const status = useScript(isLive && process.env.REACT_APP_PRIMUS_JS_FILE_URL);

  //// Not sure if the next function and useEffect are necessary ////
  const getSlide = (slideId) => {
    let selected = 0;
    props.event.slides.forEach((slideEl, index) => {
      if (slideEl._id === slideId) {
        selected = index;
      }
    });
    // console.log('>>>>>>>>>>>>> getSlide:', slideId, selected);
    return selected;
  };
  useEffect(() => {
    if (props.event.activeSlide !== '' && isLive) {
      getCurrentThumb(getSlide(props.event.activeSlide));
    }
    // eslint-disable-next-line
  }, [props.event.activeSlide]);

  // Check if event isLive
  useEffect(() => {
    setIsLive(props.event.isLive);
    // eslint-disable-next-line
  }, []);

  // Get Primus Channel from MemoizedPrimusConnect
  const getChannel = (primusChannel) => {
    channel = primusChannel;
  };

  const listenQuestionSent = () => {
    if (countMessageSent === 1) {
      // console.log('questionSent', questionSent);
      // console.log('questionSent channel: ', channel);
      channel.send('MessageSent', { status: 'new', event_id: props.event._id });
      countMessageSent++;
    }
  };

  const doNothing = () => {
    // console.log('questionSent', questionSent);
    countMessageSent = 1;
  };

  return (
    <>
      {status === 'ready' && isLive && (
        <>
          <MemoizedPrimusConnect
            event={props.event}
            preview={props.preview}
            setLiveStatus={setLiveStatus}
            setLiveLayout={setLiveLayout}
            setCurrentPresenter={setCurrentPresenter}
            getMessageToAll={getMessageToAll}
            getCurrentThumb={getCurrentThumb}
            getChannel={getChannel}
            setQnaStatus={setQnaStatus}
          />
          {questionSent === 'ok' && (props.event.isLive || props.preview) ? (
            <>{listenQuestionSent()}</>
          ) : (
            <>{doNothing()}</>
          )}
        </>
      )}
    </>
  );
};

export default React.memo(PrimusContainer);
