import React, { useState, useEffect, lazy, Suspense, useContext } from 'react';
import { isMobileOnly } from 'react-device-detect';

// MUI
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import CommentIcon from '@material-ui/icons/CommentRounded';
import QuestionIcon from '@material-ui/icons/QuestionAnswer';
import InfoIcon from '@material-ui/icons/Info';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import AddIcon from '@material-ui/icons/Add';
import GetAppIcon from '@material-ui/icons/GetApp';
import PollIcon from '@material-ui/icons/Poll';

import './index.css';

// Hooks
import useWindowDimension from '../../hooks/useViewportDimension';
import useDynamicColor from '../../hooks/useDynamicColor';
import { gaEvent } from '../../helpers/reactGA';

// Components
import InfoDrawer from '../info';
import NotesDrawer from '../notes';
import QuestionsDrawer from '../questions';
import PollDrawer from '../poll';
import AttachmentsModal from '../modals/AttachmentsModal';
import SnackBar from '../UI/snackbar/SnackBar';
import { Help, Phone } from '@material-ui/icons';
import HelpModal from '../help';
import WebcastingContext from '../../context/webcasting/webcastingContext';
const ShareButtons = lazy(() => import('../UI/ShareButtons'));

const useStyles = makeStyles((theme) => ({
  selected: {
    color: '#f26123',
  },
  bottomnav: {
    flexWrap: 'wrap',
    width: '100%',
    height: 'auto',
    alignItems: 'center',
    paddingTop: '6px',
    // paddingBottom: '8px',
    backgroundColor: 'transparent',
    left: 0,
    '& .share-buttons-container': {
      right: '0px',
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: '0px',
    },
  },
  stickToBottom: {
    position: 'fixed',
    width: '100%',
    minHeight: '25px',
    bottom: 0,
    left: 0,
  },
  stickToBottomMobile: {
    position: 'fixed',
    width: '100%',
    bottom: '-8px',
    left: 0,
  },
  containerColors: {
    backgroundColor: (bgColor) => bgColor.backgroundColor,
  },
  expandBtn: {
    width: '65px',
    height: '65px',
    position: 'absolute',
    top: '-28px',
    left: '50%',
    padding: '0px',
    transform: 'translateX(-50%)',
    transition: 'top 300ms ease-in-out',
    '&:hover': {
      backgroundColor: (bgColor) => bgColor.backgroundColor,
    },
    '& svg': {
      width: '60%',
      height: '60%',
    },
  },
  expandBtnMobile: {
    width: '56px',
    height: '56px',
    position: 'absolute',
    top: '-65px',
    left: '50%',
    padding: '0px',
    transform: 'translateX(-50%)',
    transition: 'top 300ms ease-in-out',
    boxShadow:
      '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)',
    '&:hover': {
      backgroundColor: (bgColor) => bgColor.backgroundColor,
    },
  },
  button: {
    maxWidth: '80px',
    minWidth: '80px',
    margin: theme.spacing(1),
    backgroundColor: (bgColor) => bgColor.backgroundColor,
    border: '2px solid',
    borderRadius: '5px',
    transition: 'all 0.2s',
    '&:hover': {
      backgroundColor: '#333',
      '& span': {
        '& svg': {
          fill: '#fff',
          filter: 'opacity(85%)',
        },
        '& span': {
          color: '#fff',
        },
      },
    },
    [theme.breakpoints.down('xs')]: {
      border: 'none',
      margin: '4px',
      padding: '2px',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: '40%',
    maxWidth: '620px',
    [theme.breakpoints.up('xl')]: {
      width: '30%',
    },
    [theme.breakpoints.between('xs', 'md')]: {
      width: '60%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  // drawerPoll: {
  //   width: '40%',
  //   maxWidth: '620px',
  //   [theme.breakpoints.up('xl')]: {
  //     width: '30%',
  //   },
  //   [theme.breakpoints.between('xs', 'md')]: {
  //     width: '60%',
  //   },
  //   [theme.breakpoints.down('xs')]: {
  //     width: '100%',
  //   },
  // },
}));

const Toolbar = (props) => {
  let {socket_wc} = props
  const webcastingContext = useContext(WebcastingContext);

  const {resetSendQuery} = webcastingContext
  const [shareBtns, setShareBtns] = useState(true);
  const [feedbackEnabled, setFeedbackEnabled] = useState(null);
  const [attachments, setAttachments] = useState([]);
  const [bgColor, setBgColor] = useState({ backgroundColor: '#454343' });
  const [eventID, setEventID] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [borderColor, setBorderColor] = useState({ borderColor: '#333333' });
  const [checked, setChecked] = useState(false);
  const [value, setFloat] = useState(0);
  const [color, setColor] = useState();
  const [disabled, setDisabled] = useState(true);
  const [openAttachmentModal, setOpenAttachmentModal] = useState(false);
  const [jotFormObj, setJotFormObj] = useState({
    enable: false,
    url: '',
  });
  const [drawerInfoState, setDrawerInfoState] = useState({
    left: false,
  });
  const [drawerQuestionsState, setDrawerQuestionsState] = useState({
    left: false,
  });
  const [drawerNotesState, setDrawerNotesState] = useState({
    left: false,
  });
  const [drawerPollState, setDrawerPollState] = useState({
    left: false,
  });

  const getCorrectColor = useDynamicColor(props.event);
  const [width] = useWindowDimension();

  
  console.log('toolbar socketio', socket_wc)
  useEffect(() => {
    setShareBtns(props.event.webcast.shareButtons);
    setFeedbackEnabled(props.event.webcast.feedbackEnabled);
    setAttachments(props.event.attachments);
    setEventID(props.event._id);
    setTitle(props.event.title);
    setDescription(props.event.description);
    setCompanyName(props.event.companies[0].name);

    if (props.event.webcast.colour) {
      if (props.event.webcast.colour.border[0] !== '#') {
        setBgColor({
          ...bgColor,
          backgroundColor: '#' + props.event.webcast.colour.border,
        });
      } else {
        setBgColor({
          ...bgColor,
          backgroundColor: props.event.webcast.colour.border,
        });
      }
      setBorderColor({
        ...borderColor,
        borderColor: props.event.webcast.colour.border,
      });
    }

    if (typeof props.event.webcast.jotform !== 'undefined') {
      setJotFormObj({
        ...jotFormObj,
        ...props.event.webcast.jotform,
      });
    }

    

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if (width > 960) setDisabled(false);
      if (checked !== false) {
        setTimeout(() => {
          setDisabled(false);
        }, 800);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [checked, width]);

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>
  //        ANALYTICS START
  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>
  useEffect(() => {
    if (props.preview === false) {
      drawerInfoState.left !== false &&
        gaEvent('bottom_toolbar', 'additional_info', 'show');
    }
    // eslint-disable-next-line
  }, [drawerInfoState]);

  useEffect(() => {
    if (props.preview === false) {
      drawerQuestionsState.left !== false &&
        gaEvent('submit_question', 'open', 'open question form');
    }
    // eslint-disable-next-line
  }, [drawerQuestionsState]);

  useEffect(() => {
    if (props.preview === false) {
      drawerNotesState.left !== false &&
        gaEvent('bottom_toolbar', 'freenote', 'open');
    }
    // eslint-disable-next-line
  }, [drawerNotesState]);
  // <<<<<<<<<<<<<<<<<<<<<<<<<<<<
  //        ANALYTICS END
  // <<<<<<<<<<<<<<<<<<<<<<<<<<<<

  const toggleDrawerInfo = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setDrawerInfoState({ ...drawerInfoState, [anchor]: open });
    setChecked(false);
  };

  const toggleDrawerNotes = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setDrawerNotesState({ ...drawerNotesState, [anchor]: open });
    setChecked(false);
  };

  const toggleDrawerQuestions = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setDrawerQuestionsState({ ...drawerQuestionsState, [anchor]: open });
    setChecked(false);
  };

  const toggleDrawerPoll = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setDrawerPollState({ ...drawerPollState, [anchor]: open });
    setChecked(false);
  };

  const toggleOpen = () => {
    setChecked((prev) => !prev);
  };

  const handleClickAway = () => {
    setChecked(false);
    setDisabled(true);
  };

  const handleOpenAttachmentsModal = () => {
    setOpenAttachmentModal(true);
    setChecked(false);
  };

  const handleCloseModal = (modal) => {
    if (modal === 'attachments') setOpenAttachmentModal(false);
  };


  const openInNewTabCallUs = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  const classes = useStyles(bgColor);
  const classNoSocialLink = checked && 'open';
  const classWithSocialLink = shareBtns && 'with-social-btn';





  ///Help modal

  const [openHelpModal, setOpenHelpModal] = React.useState(false);

  const handleOpenHelpModal = () => {
    setOpenHelpModal(true);
  };

  const handleCloseHelpModal = () => {
    setOpenHelpModal(false);
    resetSendQuery()
  };


  return (
    <>
    <HelpModal openHelpModal={openHelpModal} handleCloseHelpModal={handleCloseHelpModal}/>
      <SnackBar
        open={feedbackEnabled}
        liveStarted={props.event.liveStarted}
        element={'toolbar'}
        position={{ vertical: 'bottom', horizontal: 'center' }}
        preview={props.preview}
      />
      <Suspense fallback={<></>}>
        <ClickAwayListener onClickAway={handleClickAway}>
          <div
            className={`${
              isMobileOnly ? classes.stickToBottomMobile : classes.stickToBottom
            } ${classes.containerColors}`}
            style={{ zIndex: '1301' }}
          >
            <Grid
              container
              justify='center'
              spacing={2}
              className={`toolbarContainer ${classNoSocialLink} ${classWithSocialLink}`}
            >

              
              {isMobileOnly ? (
                <IconButton
                  className={`${classes.expandBtnMobile} ${
                    classes.containerColors
                  } ${
                    checked
                      ? 'expandMoreIconMobile slideOut'
                      : 'expandMoreIconMobile'
                  }`}
                  onClick={toggleOpen}
                >
                  <AddIcon
                    fontSize='large'
                    style={{ color: getCorrectColor }}
                  />
                </IconButton>
              ) : (
                <IconButton
                  className={`${classes.expandBtn} ${classes.containerColors} ${
                    checked ? 'expandMoreIcon up' : 'expandMoreIcon'
                  }`}
                  onClick={toggleOpen}
                >
                  <ArrowForwardIosRoundedIcon
                    style={{ color: getCorrectColor }}
                  />
                </IconButton>
              )}
              <BottomNavigation
                value={{ value, color }}
                onChange={(event, newValue) => {
                  setFloat(newValue);
                  setColor(classes.selected.color);
                }}
                showLabels
                className={`${classes.bottomnav} bottom-nav-mobile-padding`}
              >
                <BottomNavigationAction
                  variant='contained'
                  icon={<InfoIcon />}
                  label='Info'
                  style={{
                    borderColor: getCorrectColor,
                    color: getCorrectColor,
                  }}
                  className={`${classes.button} btnToolbar description ${
                    disabled ? 'disabled-btn' : undefined
                  }`}
                  onClick={toggleDrawerInfo(`left`, true)}
                  disabled={disabled}
                />
                <BottomNavigationAction
                  variant='contained'
                  icon={<CommentIcon />}
                  label='Notes'
                  style={{
                    borderColor: getCorrectColor,
                    color: getCorrectColor,
                  }}
                  className={`${classes.button} btnToolbar description ${
                    disabled ? 'disabled-btn' : undefined
                  }`}
                  onClick={toggleDrawerNotes(`left`, true)}
                  disabled={disabled}
                />



                {feedbackEnabled && (
                  <BottomNavigationAction
                    variant='contained'
                    icon={<QuestionIcon />}
                    label='Questions'
                    style={{
                      borderColor: getCorrectColor,
                      color: getCorrectColor,
                    }}
                    className={`${classes.button} btnToolbar description ${
                      disabled ? 'disabled-btn' : undefined
                    }`}
                    onClick={toggleDrawerQuestions(`left`, true)}
                    disabled={disabled}
                  />
                )}
                {attachments.length > 0 && (
                  <BottomNavigationAction
                    variant='contained'
                    icon={<GetAppIcon />}
                    label='Download'
                    style={{
                      borderColor: getCorrectColor,
                      color: getCorrectColor,
                    }}
                    className={`${classes.button} btnToolbar description ${
                      disabled ? 'disabled-btn' : undefined
                    }`}
                    onClick={handleOpenAttachmentsModal}
                    disabled={disabled}
                  />
                )}
                {jotFormObj.enable && (
                  <BottomNavigationAction
                    variant='contained'
                    icon={<PollIcon />}
                    label='Poll'
                    style={{
                      borderColor: getCorrectColor,
                      color: getCorrectColor,
                    }}
                    className={`${classes.button} btnToolbar description${
                      disabled ? 'disabled-btn' : undefined
                    }`}
                    onClick={toggleDrawerPoll(`left`, true)}
                    disabled={disabled}
                  />
                )}
                {shareBtns && (
                  <ShareButtons
                    eventID={eventID}
                    title={title}
                    description={description}
                    companyName={companyName}
                    borderColor={borderColor}
                    disabledBtn={disabled}
                  />
                )}


<BottomNavigationAction
                  variant='contained'
                  icon={<Help />}
                  label='Help'
                  style={{
                    borderColor: getCorrectColor,
                    color: getCorrectColor,
                  }}
                  className={`${classes.button} btnToolbar description ${
                    disabled ? 'disabled-btn' : undefined
                  }`}
                  onClick={handleOpenHelpModal}
                  disabled={disabled}
                />

                {/* Added Call us button for 64478b3672587f8b7a6c97e4 First Quantum */}
{/* {
props.event._id === '64478b3672587f8b7a6c97e4' &&
<BottomNavigationAction
                  variant='contained'
                  icon={<Phone />}
                  label='Call us'
                  style={{
                    borderColor: getCorrectColor,
                    color: getCorrectColor,
                  }}
                  className={`${classes.button} btnToolbar ${
                    disabled ? 'disabled-btn' : undefined
                  }`}
                  onClick={() => openInNewTabCallUs("https://hd.choruscall.com/?$Y2FsbG1lPXRydWUmcGFzc2NvZGU9NzM5MzA2Jmg9dHJ1ZSZpbmZvPWNvbXBhbnkmcj10cnVlJmI9Mw==")}
                  disabled={disabled}
                  />
                } */}


              </BottomNavigation>
            </Grid>
          </div>
        </ClickAwayListener>
      </Suspense>

      <Drawer
        anchor={`left`}
        open={drawerNotesState[`left`]}
        onClose={toggleDrawerNotes(`left`, false)}
        classes={{ paper: classes.drawerPaper }}
        style={{ zIndex: '1302' }}
      >
        <NotesDrawer
          drawerNotesState={{ left: false }}
          event={props.event}
          onClose={toggleDrawerNotes(`left`, false)}
          preview={props.preview}
          socket_wc={socket_wc}
        />
      </Drawer>
      {props.event.webcast.feedbackEnabled && (
        <Drawer
          anchor={`left`}
          open={drawerQuestionsState[`left`]}
          onClose={toggleDrawerQuestions(`left`, false)}
          classes={{ paper: classes.drawerPaper }}
          style={{ zIndex: '1302' }}
        >
          <QuestionsDrawer
            socket_wc={socket_wc}
            className={classes.toolbar}
            drawerQuestionsState={{ left: false }}
            event={props.event}
            onClose={toggleDrawerQuestions(`left`, false)}
          />
        </Drawer>
      )}
      <Drawer
        anchor={`left`}
        open={drawerInfoState[`left`]}
        onClose={toggleDrawerInfo(`left`, false)}
        classes={{ paper: classes.drawerPaper }}
        style={{ zIndex: '1302' }}
      >
        <InfoDrawer
          className={classes.toolbar}
          drawerInfoState={{ left: false }}
          event={props.event}
          onClose={toggleDrawerInfo(`left`, false)}
          socket_wc={socket_wc}
        />
      </Drawer>
      {jotFormObj.enable && (
        <Drawer
          anchor={`left`}
          open={drawerPollState[`left`]}
          onClose={toggleDrawerPoll(`left`, false)}
          classes={{ paper: classes.drawerPaper }}
          style={{ zIndex: '1302' }}
        >
          <PollDrawer
            className={classes.toolbar}
            drawerPollState={{ left: false }}
            onClose={toggleDrawerPoll(`left`, false)}
            formUrl={jotFormObj.url}
          />
        </Drawer>
      )}
      <AttachmentsModal
        open={openAttachmentModal}
        handleClose={handleCloseModal}
        attachmentsArray={attachments}
      />
    </>
  );
};

const areEqual = (prevProps, nextProps) => {
  return prevProps.event === nextProps.event;
};

export default React.memo(Toolbar, areEqual);
