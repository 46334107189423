import React from 'react';
import { Route, Switch } from 'react-router-dom';

// HOOKS
// import usePageTracking from '../usePageTracking';

// COMPONENTS
import BroadcastRouter from './broadcast';
import PreviewRouter from './preview';
import ZoomRouter from './zoom';
import NotFound from '../components/UI/NotFound';
import CookiePolicyPage from '../components/UI/CookiePolicyPage';
import PrivacyPolicyPage from '../components/UI/PrivacyPolicyPage';
import TermsAndConditionsPage from '../components/UI/TermsAndConditionsPage';
import CheckYourEmail from '../components/UI/CheckYourEmail';

function MainRouter() {
  // usePageTracking();

  return (
    <Switch>
      <Route path='/broadcast/preview' component={PreviewRouter} />
      <Route path='/broadcast' component={BroadcastRouter} />
      <Route path='/preview' component={PreviewRouter} />
      <Route path='/cookies' component={CookiePolicyPage} />
      <Route path='/privacy' component={PrivacyPolicyPage} />
      <Route path='/notVerified/:eventID/:findID' component={CheckYourEmail} />
      <Route path='/conference' component={ZoomRouter} />
      <Route path='/terms_and_conditions' component={TermsAndConditionsPage} />
      <Route component={NotFound} />
    </Switch>
  );
}

export default MainRouter;
